import React, { useState } from 'react'
import axios from 'axios'
import { Modal, Spin, message} from 'antd'
import MyIcon from '../../../components/general/icon'

const DownloadFacebodyReport = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [isLoading, setIsLoading] = useState(false)

  // const handleGenerate = () => {
  //   setIsLoading(true)
  //   axios.post('/temp/facebody/reports/generate', { 
  //     merchant_oid: merchantOid,
  //     facebody_category_oid: props.filterValue.category,
  //     status: props.filterValue.status,
  //     college: props.filterValue.college,
  //     major: props.filterValue.major
  //   })
  //     .then(function (response) {
  //       if (response.errcode === 0) {
  //         message.success('人脸核身报告批量生成成功！')
  //         setIsLoading(false)
  //       }
  //     })
  //     .catch(function (error) {
  //       console.error('请求失败，错误信息为：', error)
  //     })
  // }

  const handleDownloadReport = () => {
    setIsLoading(true)
    axios.post('/p/facebody/student/report/download', { 
      merchant_oid: merchantOid,
      facebody_category_oid: props.filterValue.category,
      status: props.filterValue.status,
      college: props.filterValue.college,
      major: props.filterValue.major,
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('人脸核身报告批量下载成功！')
          setIsLoading(false)
          const link = document.createElement('a')
          link.href = response.data.download_address
          link.setAttribute('download', '')
          link.click()
          document.body.removeChild(link)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleDownloadPhoto = () => {
    setIsLoading(true)
    axios.post('/p/facebody/student/collected_photos/download', { 
      merchant_oid: merchantOid,
      facebody_category_oid: props.filterValue.category,
      status: props.filterValue.status,
      college: props.filterValue.college,
      major: props.filterValue.major,
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('人脸采集照片批量下载成功！')
          setIsLoading(false)
          const link = document.createElement('a')
          link.href = response.data.download_address
          link.setAttribute('download', '')
          link.click()
          document.body.removeChild(link)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <Modal
      width={500}
      title="批量下载核验报告"
      open={props.isOpen}
      onCancel={props.isCancel}
      maskClosable={false}
      footer=''>

      <Spin spinning={isLoading}>
        <div className='public-template-container'>
          <div className='public-template-icon'>
            <MyIcon name='daochu' />
          </div>

          <div className='public-template-title'>人脸核身报告</div>
          <div className='public-template-despcription'>批量下载已核验的学生报告，包含核验通过和核验未通过的</div>

          {/* <div className='public-template-button2' onClick={ handleGenerate }>
            <p>批量生成核验报告</p>
          </div> */}
          <div className='public-template-button2' onClick={ handleDownloadReport }>
            <p>批量下载核验报告</p>
          </div>
          <div className='public-template-button2' onClick={ handleDownloadPhoto }>
            <p>批量下载采集照片</p>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default DownloadFacebodyReport
