import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MySwitch from '../../../components/forms/switch'

const EditAdmissionStudent = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/admission_notice/student/detail/get', {
      merchant_oid: merchantOid,
      student_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({nameEmpty: '校区名称不能为空'})
      return false
    }
    if (!formValue.number) {
      setErrorMsg({numberEmpty: '考生号不能为空'})
      return false
    }

    axios.post('/p/admission_notice/student/edit', { 
      merchant_oid: merchantOid, 
      student_oid: formValue.oid, 
      name: formValue.name,
      number: formValue.number,
      college: formValue.college,
      major: formValue.major,
      duration: formValue.duration,
      category: formValue.category,
      is_print: formValue.is_print
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getStudentData()
          message.success('录取学生修改成功！')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title="编辑录取学生"
      destroyOnClose={true}
      width='700px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyInput
        id="name"
        required
        autoFocus
        maxLength={64}
        label='名称'
        placeholder='请输入考生姓名'
        value={formValue.name}
        onChange={setFormValue}
        errorMsg={errorMsg.nameEmpty}
      />

      <MyInput
        id="number"
        required
        maxLength={64}
        label='考生号'
        placeholder='请输入考生号'
        value={formValue.number}
        onChange={setFormValue}
        errorMsg={errorMsg.numberEmpty}
      />

      <MyInput
        id="college"
        maxLength={64}
        label='学院'
        placeholder='请输入学院名称'
        value={formValue.college}
        onChange={setFormValue}
      />

      <MyInput
        id="major"
        maxLength={64}
        label='专业'
        placeholder='请输入专业名称'
        value={formValue.major}
        onChange={setFormValue}
      />

      <MyInput
        id="duration"
        maxLength={64}
        label='学制'
        placeholder='请输入学制'
        value={formValue.duration}
        onChange={setFormValue}
      />

      <MyInput
        id="category"
        maxLength={64}
        label='招生类型'
        placeholder='请输入招生类型'
        value={formValue.category}
        onChange={setFormValue}
      />

      <MySwitch
        id='is_print'
        label='是否已打印'
        checked={formValue.is_print}
        onChange={setFormValue}
      />
    </Modal>
  )
}
export default EditAdmissionStudent
