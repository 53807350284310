import React from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'

const EmptyAdmissionStudent = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')

  const handleOk = () => {
    axios.post('/p/admission_notice/student/empty', { 
      merchant_oid: merchantOid, 
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('录取通知书数据清空成功！')
          props.isCancel()
          props.getStudentData()
        } else {
          message.error(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <Modal 
      title="清空录取通知书数据"
      open={props.isOpen}
      onOk={handleOk}
      onCancel={props.isCancel}
      cancelText="取消"
      okType='danger'
      okText='清空'>

      <p>正在将当前院校下的所有录取通知书数据清空，此操作不可逆，是否确认清空？</p>
    </Modal>
  )
}

export default EmptyAdmissionStudent
