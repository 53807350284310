import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MyIcon from '../../../components/general/icon'
import AddCollege from './add'
import EditCollege from './edit'
import DeleteCollege from './delete'

export default function College() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [collegeList, setCollegeList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isAddCollegeVisible, setIsAddCollegeVisible] = useState(false)
  const [isEditCollegeVisible, setIsEditCollegeVisible] = useState(false)
  const [isDeleteCollegeVisible, setIsDeleteCollegeVisible] = useState(false)

  const getCollegeList = () => {
    axios.post('/p/info/college/list/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setCollegeList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getCollegeList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const editCollege = (oid) => {
    setIsEditCollegeVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteCollege = (oid, name) => {
    setIsDeleteCollegeVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">院系部门</div>
        </div>

        <div className='public-main-style'>
          <div className='public-main-style1'>
            <div className='public-main-style1-add' onClick={() => { setIsAddCollegeVisible(true) }}>
              <div className='public-main-style1-add-icon'>
                <MyIcon name='jiahao' />
              </div>
              <div className='public-main-style1-add-title'>创建新的院系</div>
            </div>

            {collegeList.map(college => (
              <div className='public-main-style1-content' key={college.oid} onClick={() => { editCollege(college.oid) }}>
                <div>
                  <div className='public-main-style1-content-title'>
                    <div className='public-main-style1-content-icon'>
                      {college.icon ? (  
                        <img style={{width: '28px', height: '28px'}} src={college.icon} alt="" />
                      ) : (  
                        <span style={{fontSize: '28px'}}><MyIcon name='xueyuan' /></span>
                      )} 
                    </div>
                    <div className='public-main-style1-content-name'>{college.name}</div>
                    <div className='public-main-style1-content-delete' 
                      onClick={(event) => {event.stopPropagation(); deleteCollege(college.oid, college.name)}}>
                      <MyIcon name='huishouzhan' />
                    </div>
                  </div>
                  <div className='public-main-style1-content-description'>
                    {college.introduction ? college.introduction : '暂无院系介绍'}
                  </div>
                </div>
                <div className='public-main-style1-content-other'>下设 {college.major_number} 个专业</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isAddCollegeVisible && <AddCollege
        getCollegeList={getCollegeList} 
        isOpen={isAddCollegeVisible} 
        isCancel={() => { setIsAddCollegeVisible(false) }} 
      />}

      {isEditCollegeVisible && <EditCollege
        data={propsData}
        getCollegeList={getCollegeList}
        isOpen={isEditCollegeVisible}
        isCancel={() => { setIsEditCollegeVisible(false) }}
      />}

      {isDeleteCollegeVisible && <DeleteCollege
        data={propsData}
        getCollegeList={getCollegeList}
        isOpen={isDeleteCollegeVisible}
        isCancel={() => { setIsDeleteCollegeVisible(false) }}
      />}
    </div>
  )
}
