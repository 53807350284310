import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Input, Select } from 'antd'
import MyIcon from '../../../components/general/icon'

const ChatbotKnowledgeRibbon = ({
  filterValue,
  setFilterValue,
  isImportVisible,
  isEmptyVisible
}) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [keywordsValue, setKeywordsValue] = useState('')
  const [categoryList, setCategoryList] = useState([])
  const [searchBoxVisible, setSearchBoxVisible] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFilterValue({ ...filterValue, keywords: keywordsValue })
    }, 300)
    return () => clearTimeout(timeoutId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordsValue])

  useEffect(() => {
    axios.post('/p/chatbot/category/list/get', { 
      merchant_oid: merchantOid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setCategoryList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid])

  let items = [
    {  
      value: '', 
      label: '全部'
    },
    ...categoryList.map((item) => (
      {
        value: item.oid,
        label: item.name
      }
    ))
  ]

  const clickCategoryFilter = (value) => {
    setFilterValue({ ...filterValue, category_oid: value })
  }

  const handleBlur = () => {
    if (!keywordsValue) {
      setSearchBoxVisible(false)
      setFilterValue({ ...filterValue, keywords: '' })
    }
  }

  return (
    <div className='ribbon-page'>
      <div className='ribbon-container'>
        <div className='filter-addButton' onClick={() => { isImportVisible() }}>导入/导出</div>
        <div className='filter-addButton2' onClick={() => { isEmptyVisible() }}>清空数据</div>
        <Select
          placeholder="所属类目"
          options={items}
          style={{height: '32px', width: '200px'}}
          onChange={(value) => clickCategoryFilter(value)}
        />
        {!searchBoxVisible ? 
          <div className='ribbon-condition' onClick={() => { setSearchBoxVisible(true) }}>
            <span><MyIcon name='sousuo'/></span>
            <p>搜索</p>
          </div>
        : <Input
            style={{width: '260px'}}
            prefix={<MyIcon name='sousuo'/>}
            maxLength={64}
            autoFocus
            placeholder='输入问题标题'
            value={keywordsValue}
            onChange={(e) => setKeywordsValue(e.target.value)}
            onBlur={handleBlur}
            allowClear
          />
        }
      </div>
    </div>
  )
}

export default ChatbotKnowledgeRibbon
