import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message, Button } from 'antd'
import MyInput from '../../../components/forms/input'

const AddAdmissionStudent = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  const handleSubmit = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({nameEmpty: '考生姓名不能为空'})
      return false
    }
    if (!formValue.number) {
      setErrorMsg({numberEmpty: '考生号不能为空'})
      return false
    }

    axios.post('/p/admission_notice/student/add', {
      merchant_oid: merchantOid,
      name: formValue.name,
      number: formValue.number,
      college: formValue.college,
      major: formValue.major,
      duration: formValue.duration,
      category: formValue.category
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('录取学生添加成功！')
          props.getStudentData()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleOk = () => {
    const isOk = handleSubmit()
    if (isOk !== false) {handleCancel()}
  }

  const handleNext = () => {
    handleSubmit()
    setFormValue({})
  }

  const handleCancel = () => {
    setErrorMsg({})
    setFormValue({})
    props.isCancel()
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="添加录取学生"
        width='600px'
        open={props.isOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key='cancel' onClick={handleCancel}>取消</Button>,
          <Button key='submit' onClick={handleNext}>提交并添加下一条</Button>,
          <Button key='isOk' type="primary" onClick={handleOk}>添加</Button>
        ]}>

        <MyInput
          id="name"
          required
          autoFocus
          maxLength={64}
          label='名称'
          placeholder='请输入考生姓名'
          value={formValue.name}
          onChange={setFormValue}
          errorMsg={errorMsg.nameEmpty}
        />

        <MyInput
          id="number"
          required
          maxLength={64}
          label='考生号'
          placeholder='请输入考生号'
          value={formValue.number}
          onChange={setFormValue}
          errorMsg={errorMsg.numberEmpty}
        />

        <MyInput
          id="college"
          maxLength={64}
          label='学院'
          placeholder='请输入学院名称'
          value={formValue.college}
          onChange={setFormValue}
        />

        <MyInput
          id="major"
          maxLength={64}
          label='专业'
          placeholder='请输入专业名称'
          value={formValue.major}
          onChange={setFormValue}
        />

        <MyInput
          id="duration"
          maxLength={64}
          label='学制'
          placeholder='请输入学制'
          value={formValue.duration}
          onChange={setFormValue}
        />

        <MyInput
          id="category"
          maxLength={64}
          label='招生类型'
          placeholder='请输入招生类型'
          value={formValue.category}
          onChange={setFormValue}
        />
      </Modal>
    </>
  )
}

export default AddAdmissionStudent
