import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyImageUpload from '../../../components/forms/imageUpload'
import MyRadio from '../../../components/forms/radio'
import MyTextArea from '../../../components/forms/textArea'

const EditWebsiteBanner = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/deco/banner/detail/get', {
      merchant_oid: merchantOid,
      banner_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          const { mode, path, ...rest } = response.data;
          if (mode === 4) {
            setFormValue({ ...rest, mode, path, finderUserName: path });
          } else if (mode === 5 && path) {
            const [finderUserName, feedId] = path.split('&');
            setFormValue({ ...rest, mode, path, finderUserName, feedId });
          } else {
            setFormValue(response.data);
          }
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.thumbnail) {
      setErrorMsg({thumbnailEmpty: '图片不能为空'})
      return false
    }

    let path
    if (formValue.mode === 4) {
      path = formValue.finderUserName
    } else if (formValue.mode === 5) {
      path = formValue.finderUserName + '&' + formValue.feedId
    } else {
      path = formValue.path
    }

    axios.post('/p/deco/banner/edit', { 
      merchant_oid: merchantOid,
      banner_oid: formValue.oid,
      title: formValue.title,
      thumbnail: formValue.thumbnail,
      mode: formValue.mode,
      path: path,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getBannerList()
          message.success('横幅广告修改成功！')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title='编辑横幅广告'
      destroyOnClose={true}
      width='760px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyImageUpload 
        id="thumbnail"
        required
        label='图片'
        value={formValue.thumbnail}
        setValue={setFormValue}
        errorMsg={errorMsg.thumbnailEmpty}
      />

      <MyInput
        id="title"
        autoFocus
        maxLength={64}
        label='标题'
        placeholder='请输入横幅广告的标题'
        value={formValue.title}
        onChange={setFormValue}
      />

      <MyRadio
        id='mode'
        label='跳转页面'
        options={[
          {
            label: '内部路径',
            value: 1,
          },
          {
            label: '外部网页',
            value: 2,
          },
          {
            label: '不跳转',
            value: 0,
          }
        ]}
        value={formValue.mode}
        onChange={setFormValue}
      />

      {[1, 2].includes(formValue.mode) && <MyTextArea 
        id="path"
        minRows={2}
        placeholder='请输入地址'
        value={formValue.path}
        onChange={setFormValue}
      />}

      {formValue.mode === 5 && <MyInput
        id="feedId"
        maxLength={128}
        label='视频id'
        placeholder='请输入视频 feedId'
        value={formValue.feedId}
        onChange={setFormValue}
      />}

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />
    </Modal>
  )
}

export default EditWebsiteBanner
