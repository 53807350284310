import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message, Spin } from 'antd'
import MyRadio from '../../../components/forms/radio'
import MyTextArea from '../../../components/forms/textArea'

const AuditFreshmenDeferral = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({
    auditStatus: props.data.auditStatus,
    failureReason: props.data.failureReason
  })
  const [errorMsg, setErrorMsg] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const handleOk = () => {
    setErrorMsg({})
    if (formValue.auditStatus === 3 && !formValue.failureReason) {
      setErrorMsg({failureReasonEmpty: '失败原因不能为空'})
      return false
    }

    if (formValue.auditStatus === 2) {
      setIsLoading(true)
    }
    axios.post('/p/retention_admission/application/audit', { 
      merchant_oid: merchantOid, 
      retention_admission_oid: props.data.oid,
      audit_status: formValue.auditStatus,
      failure_reason: formValue.failureReason
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('审核完成！')
          props.isCancel()
          props.getFreshmenRetentionDetail()
          props.getApplicationData()
          setIsLoading(false)
        } else {
          message.error(response.msg)
          setIsLoading(false)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <Modal 
      title="审核"
      open={props.isOpen}
      onOk={handleOk}
      onCancel={props.isCancel}
      cancelText="取消"
      okText="保存">

      <Spin spinning={isLoading} tip='保留入学资格证明文件生成中，请稍等'>
        <MyRadio
          id='auditStatus'
          label='请选择'
          options={[
            {
              label: '待审',
              value: 1,
            },
            {
              label: '通过',
              value: 2,
            },
            {
              label: '未通过',
              value: 3,
            }
          ]}
          value={formValue.auditStatus}
          onChange={setFormValue}
        />

        {formValue.auditStatus === 3 && <MyTextArea 
          id="failureReason"
          label='失败原因'
          minRows={3}
          placeholder='请输入失败原因'
          value={formValue.failureReason}
          onChange={setFormValue}
          errorMsg={errorMsg.failureReasonEmpty}
        />}
      </Spin>
    </Modal>
  )
}

export default AuditFreshmenDeferral
