import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, Image, message, Spin } from 'antd'
import AuditFreshmenDeferral from './audit'

const FreshmenDeferralDetail = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [applicationDetail, setApplicationDetailDetail] = useState({})
  const [propsData, setPropsData] = useState({})
  const [isAuditVisible, setIsAuditVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getFreshmenRetentionDetail = () => {
    axios.post('/p/retention_admission/application/detail/get', {
      merchant_oid: merchantOid,
      retention_admission_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setApplicationDetailDetail(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getFreshmenRetentionDetail()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, props.data.oid])

  const handleCancel = () => {
    props.isCancel()
  }

  const getColor = () => {
    if (applicationDetail.audit_status.key === 2) return 'green'
    if (applicationDetail.audit_status.key === 3) return 'red'
    return 'black'
  }

  const openAudit = () => {
    setIsAuditVisible(true)
    setPropsData({ 
      oid: applicationDetail.oid,
      auditStatus: applicationDetail.audit_status.key,
      failureReason: applicationDetail.failure_reason
    })
  }

  const printPdf = (url) => {
    window.open(url, '_blank')
  }

  const handleDownload = () => {
    setIsLoading(true)
    axios.post('/p/retention_admission/materials/download', { 
      merchant_oid: merchantOid,
      retention_admission_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('申请资料下载成功！')
          setIsLoading(false)
          const link = document.createElement('a')
          link.href = response.data.download_address
          link.setAttribute('download', '')
          link.click()
          document.body.removeChild(link)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <Modal 
      title="保留入学资格申请详情"
      destroyOnClose={true}
      width='700px'
      open={props.isOpen}
      onCancel={handleCancel}
      footer={false}>
      
      <Spin spinning={isLoading}>
      <div className='public-datail-container'>
        <div className='public-datail-item'>
          <div className='public-datail-label'>申请时间</div>
          <div className='public-datail-content'>{applicationDetail.create_time}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>姓名</div>
          <div className='public-datail-content'>{applicationDetail.name}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>身份证号</div>
          <div className='public-datail-content'>{applicationDetail.id_number}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>手机号</div>
          <div className='public-datail-content'>{applicationDetail.phone}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>学院</div>
          <div className='public-datail-content'>{applicationDetail.college}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>专业</div>
          <div className='public-datail-content'>{applicationDetail.major}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>录取时间</div>
          <div className='public-datail-content'>{applicationDetail.admission_date}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>身份证照片</div>
          <div className='public-datail-photo'>
            <Image src={applicationDetail.id_card_front_photo} width={80}/>
            <Image src={applicationDetail.id_card_back_photo} width={80}/>
          </div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>录取通知书</div>
          <div className='public-datail-photo'>
            <Image src={applicationDetail.admission_notice_photo} width={80}/>
          </div>
        </div>
        {applicationDetail.application_reason_type && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>申请原因</div>
            <div className='public-datail-content'>{applicationDetail.application_reason_type.value}</div>
          </div>
        }
        <div className='public-datail-item'>
          <div className='public-datail-label'>申请原因证明文件</div>
          <div className='public-datail-photo'>
            <Image src={applicationDetail.application_reason_proof_photo} width={80}/>
          </div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>申请表</div>
          <div className='public-datail-photo'>
            <Image src={applicationDetail.application_form_photo} width={80}/>
          </div>
        </div>

        {applicationDetail.audit_status && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>审核状态</div>
            <div style={{ color: getColor() }} className='public-datail-status'>{applicationDetail.audit_status.value}</div>
            <div className='public-datail-button' onClick={openAudit}>审核</div>
          </div>
        }
        {(applicationDetail.audit_status && applicationDetail.audit_status.key === 2) && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>保留入学资格证明文件</div>
            <div className='public-datail-pdf'>
              <iframe 
                title='证明'
                style={{border: 'none', width: '99px', height: '140px'}} 
                src={applicationDetail.success_proof_pdf}>
              </iframe>
              <div className='public-datail-button2' onClick={() => {printPdf(applicationDetail.success_proof_pdf)}}>预览</div>
            </div>
          </div>
        }
        {(applicationDetail.audit_status && applicationDetail.audit_status.key === 3) && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>审核失败原因</div>
            <div className='public-datail-content'>{applicationDetail.failure_reason}</div>
          </div>
        }
        
        <div className='public-download-button' onClick={ handleDownload }>打包下载</div>
      </div>
      </Spin>

      {isAuditVisible && <AuditFreshmenDeferral
        data={propsData}
        getFreshmenRetentionDetail={getFreshmenRetentionDetail}
        getApplicationData={() => { props.getApplicationData() }}
        isOpen={isAuditVisible}
        isCancel={() => { setIsAuditVisible(false) }}
      />}
    </Modal>
  )
}
export default FreshmenDeferralDetail
