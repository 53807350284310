import React, { useState, useEffect, useRef } from 'react'
import { useLocation} from 'react-router-dom'
import axios from 'axios'
import MyIcon from '../../../components/general/icon'
import AddPrimaryColumn from './addPrimary'
import AddSecondaryColumn from './addSecondary'
import EditColumn from './edit'
import DeleteColumn from './delete'

export default function Column() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [columnList, setColumnList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isAddPrimaryColumnVisible, setIsAddPrimaryColumnVisible] = useState(false)
  const [isAddSecondaryColumnVisible, setIsAddSecondaryColumnVisible] = useState(false)
  const [isEditColumnVisible, setIsEditColumnVisible] = useState(false)
  const [isDeleteColumnVisible, setIsDeleteColumnVisible] = useState(false)

  const columnInfo = useRef({})
  const location = useLocation()
  const pathSegments = location.pathname.split('/').filter(Boolean)
  const pathType = pathSegments[pathSegments.length - 1]

  const getColumnList = () => {
    axios.post('/p/content/column/list/get', {
      merchant_oid: merchantOid,
      category: columnInfo.current.category
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setColumnList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    if (pathType === 'article_column') {
      columnInfo.current = { category: 1, name: '文章' }
    } else if (pathType === 'picture_column') {
      columnInfo.current = { category: 2, name: '图片' }
    } else if (pathType === 'video_column') {
      columnInfo.current = { category: 3, name: '视频' }
    } else if (pathType === 'character_column') {
      columnInfo.current = { category: 4, name: '人物' }
    } else if (pathType === 'faq_column') {
      columnInfo.current = { category: 5, name: '常见问题' }
    }
    getColumnList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, pathType])

  const addPrimaryColumn = () => {
    setIsAddPrimaryColumnVisible(true)
    setPropsData({ category: columnInfo.current.category, name: columnInfo.current.name})
  }

  const addSecondaryColumn = (superior_column_oid, superior_column_name) => {
    setIsAddSecondaryColumnVisible(true)
    setPropsData({ 
      category: columnInfo.current.category, 
      name: columnInfo.current.name, 
      superior_column_oid: superior_column_oid,
      superior_column_name: superior_column_name
    })
  }

  const editColumn = (oid) => {
    setIsEditColumnVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteColumn = (oid, name) => {
    setIsDeleteColumnVisible(true)
    setPropsData({ oid: oid, name: name })
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">{columnInfo.current.name}栏目</div>
          <div className='filter-addButton' onClick={addPrimaryColumn}>添加栏目</div>
        </div>

        {columnList.length > 0 ? (
          <div className='public-main-style3-wrapper'>
            <div className='public-main-style3'>
              {columnList.map(primaryColumn => (
                <div className='public-main-style3-box' key={primaryColumn.oid}>
                  <div className='public-main-style3-primary' onClick={() => { editColumn(primaryColumn.oid) }}>
                    <div className='public-main-style3-title'>
                      {primaryColumn.icon && <img src={primaryColumn.icon} alt="" style={{width: '20px', height: '20px', backgroundColor: '#fff'}}/>}
                      <p>{primaryColumn.name}</p>
                    </div>
                    <div className='public-main-style3-primary-delete' 
                      onClick={(event) => {event.stopPropagation(); deleteColumn(primaryColumn.oid, primaryColumn.name)}}>
                      <MyIcon name='huishouzhan' />
                    </div>
                  </div>
                  {primaryColumn.children.map(secondaryColumn => (
                    <div className='public-main-style3-secondary' key={secondaryColumn.oid} onClick={() => { editColumn(secondaryColumn.oid) }}>
                      <div className='public-main-style3-title'>
                        {secondaryColumn.icon && <img src={secondaryColumn.icon} alt="" />}
                        <p>{secondaryColumn.name}</p>
                      </div>
                      <div className='public-main-style3-secondary-delete' 
                        onClick={(event) => {event.stopPropagation(); deleteColumn(secondaryColumn.oid, secondaryColumn.name)}}>
                        <MyIcon name='huishouzhan' />
                      </div>
                    </div>
                  ))}
                  <div className='public-main-style3-add' onClick={() => {addSecondaryColumn(primaryColumn.oid, primaryColumn.name)}}>
                    <MyIcon name='jiahao' />
                    <p>添加子栏目</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="public-main-style3-empty">
            <img src="/images/nodata.png" alt="空数据" />
            <span>暂无数据</span>
          </div>
        )}
      </div>

      {isAddPrimaryColumnVisible && <AddPrimaryColumn
        data={propsData}
        getCollegeList={getColumnList} 
        isOpen={isAddPrimaryColumnVisible} 
        isCancel={() => { setIsAddPrimaryColumnVisible(false) }} 
      />}

      {isAddSecondaryColumnVisible && <AddSecondaryColumn
        data={propsData}
        getCollegeList={getColumnList} 
        isOpen={isAddSecondaryColumnVisible} 
        isCancel={() => { setIsAddSecondaryColumnVisible(false) }} 
      />}

      {isEditColumnVisible && <EditColumn
        data={propsData}
        getColumnList={getColumnList} 
        isOpen={isEditColumnVisible} 
        isCancel={() => { setIsEditColumnVisible(false) }} 
      />}

      {isDeleteColumnVisible && <DeleteColumn
        data={propsData}
        getColumnList={getColumnList} 
        isOpen={isDeleteColumnVisible} 
        isCancel={() => { setIsDeleteColumnVisible(false) }} 
      />}
    </div>
  )
}
