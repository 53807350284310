import React from 'react'
import { Outlet, NavLink } from 'react-router-dom'
import MyIcon from '../../components/general/icon'

export default function AuxiliaryNav() {
  const computedClassName = ({ isActive }) => {
    return isActive ?  "secondaryNav-menu secondaryNav-menuActive" : "secondaryNav-menu"
  }

  return (
    <div className="secondaryNav-page">
      <div className="secondaryNav-container">
        <div className="secondaryNav-headline">招录辅助</div>
          {/* <div className="secondaryNav-box">
            <div className="secondaryNav-title">招生表单</div>
            <NavLink to="collect_form" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='shoujibiaodan' /></div>
              <div className="secondaryNav-name">收集表单</div>
            </NavLink>
            <NavLink to="collect_data" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='shoujishuju' /></div>
              <div className="secondaryNav-name">收集数据</div>
            </NavLink>
          </div> */}

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">录取辅助</div>
            <NavLink to="admission_notice" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='luqutongzhishu' /></div>
              <div className="secondaryNav-name">录取通知书</div>
            </NavLink>
          </div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">人脸核身</div>
            <NavLink to="facebody_category" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='renxiangfenlei' /></div>
              <div className="secondaryNav-name">分类管理</div>
            </NavLink>
            <NavLink to="facebody_student" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='renxiangxuesheng' /></div>
              <div className="secondaryNav-name">学生管理</div>
            </NavLink>
          </div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">问答机器人</div>
            <NavLink to="chatbot_knowledge" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='zhishiku' /></div>
              <div className="secondaryNav-name">知识库</div>
            </NavLink>
            <NavLink to="chatbot_faq" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='changjianwenti' /></div>
              <div className="secondaryNav-name">常见问题</div>
            </NavLink>
            <NavLink to="chatbot_record" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='huihua' /></div>
              <div className="secondaryNav-name">会话记录</div>
            </NavLink>
            <NavLink to="chatbot_config" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='peizhixinxi' /></div>
              <div className="secondaryNav-name">配置信息</div>
            </NavLink>
          </div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">新生入学资格</div>
            <NavLink to="freshmen_deferral" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='yanqi' /></div>
              <div className="secondaryNav-name">保留申请</div>
            </NavLink>
            <NavLink to="freshmen_readmission" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='fuxue' /></div>
              <div className="secondaryNav-name">复学申请</div>
            </NavLink>
            <NavLink to="freshmen_config" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='peizhixinxi' /></div>
              <div className="secondaryNav-name">配置信息</div>
            </NavLink>
          </div>
      </div>
      <Outlet />
    </div>
  )
}
