import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import MyInput from '../../../components/forms/input'
import MyButton from '../../../components/forms/button'

export default function Login() { 
  const navigate = useNavigate()
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    const metaTag = document.createElement('meta')
    metaTag.title = 'viewport'
  }, [])

  const handleSubmit = () => {
    setErrorMsg({})
    if (!formValue.username) {
      setErrorMsg({usernameEmpty: '用户名不能为空'})
      return
    }
    if (!formValue.password) {
      setErrorMsg({passwordEmpty: '密码不能为空'})
      return
    }

    axios.post('/auth/login/producer', {
      username: formValue.username,
      password: formValue.password
    })
      .then(function (response) {
        if (response.errcode === 0) {
          localStorage.setItem('token', response.data.access_token)
          localStorage.setItem('merchant_oid', response.data.merchant_oid)
          navigate('/index')
        } else if (response.errcode === 1) {
          setErrorMsg({usernameError: response.msg})
        } else if (response.errcode === 2 || response.errcode === 3) {
          setErrorMsg({pwdError: response.msg})
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const domain = window.location.hostname;

  let companyName;
  if (domain === 'school.yunzhaosoft.com') {
    companyName = '云招科技';
  } else if (domain === 's.bonnieschool.com') {
    companyName = '邦尼招生'
  } else {
    companyName = '本地环境'
  }

  return (
    <div className="auth-page">
      <Link className="auth-logo"  to='/'>
        <p>{companyName}</p>
      </Link>
      <div className="auth-container">
        <div className="auth-header">
          <div className="auth-title">登录</div>
          <div className="auth-description">登录表示同意{companyName}的服务协议、隐私政策</div>
        </div>

        <form className="auth-loginForm">
          <MyInput
            id="username"
            autoFocus
            autoComplete='on'
            label="用户名"
            placeholder='请输入用户名'
            value={formValue.username}
            onChange={setFormValue}
            errorMsg={errorMsg.emailEmpty || errorMsg.usernameError}
          />
          <MyInput
            id="password"
            type="password"
            visibilityToggle={false}
            label="密码"
            onPressEnter={handleSubmit}
            placeholder='请输入密码'
            value={formValue.password}
            onChange={setFormValue}
            errorMsg={errorMsg.passwordEmpty || errorMsg.pwdError}
          />
          <div className="auth-forgot">
            <Link className="auth-forgotLink" to={''}>
              忘记密码？
            </Link>
          </div>
          <MyButton 
            onClick={handleSubmit} 
            buttonText='登录'
            style={{ width: '200px', marginTop: '30px' }}
          />

          <div className="auth-toRegister">
            首次使用？
            <Link className="auth-toRegisterLink" to={''}>
              创建账号
            </Link>
          </div>

          <div className="auth-otherLogin">
            <span className="auth-otherLine"></span>
            <span className="auth-otherLine"></span>
          </div>
          <div className='signature'>
            {companyName === '邦尼招生' ?
              <>
                <span>© 2023-2024 bonnieschool.com, all rights reserved</span>
                <span>安徽邦尼信息科技有限公司</span>
                <span>皖ICP备2023006966号-1</span>
              </> :
              <>
                <span>© 2024 yunzhaosoft.com, all rights reserved</span>
                <span>广西云招教育科技有限公司</span>
                <span>桂ICP备2024030510号</span>
              </>
            }
          </div>
        </form>
      </div>
    </div>
  )
}
