import axios from 'axios'
import { message } from 'antd'

const apiBaseURL = process.env.REACT_APP_API_BASE_URL
const baseURL = process.env.REACT_APP_BASE_URL

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.baseURL = apiBaseURL

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = token
    }
		return config
	},
  function (error) {
		return Promise.reject(error)
	}
)

axios.interceptors.response.use(
	function (response) {
		if (response.status === 200) {
			return response.data
		}
	}, 
  function (error) {
    console.log(error);
    if (error.response.status === 503) {
			window.location.href = '/maintenance'
		} else if (error.response.status === 401) {
			localStorage.removeItem('token')
			window.location.href = '/login'
		} else if (error.response.status === 403) {
			window.location.href = '/403'
    } else if (error.response.status === 404) {
      return error.response.data
    } else if (error.response.status === 413) {
			message.error('提交的文件太大了!')
		} else if (error.response.status === 422) {
			message.error('提交的数据太长了!')
    } else if (error.response.status === 500) {
			window.location.href = '/500'
		} else {
      message.error('网络请求异常!')
    }
	}
)

export { apiBaseURL, baseURL }
