import React, { useMemo, useState, useRef } from 'react'
import axios from 'axios'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Spin } from 'antd'

const MyRichText = ({ 
  id,
  label = '',
  value,
  setValue,
  styles
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const quillRef = useRef()

  // Quill.register(Quill.import("attributors/style/direction"), true)
  // Quill.register(Quill.import("attributors/style/align"), true)

  // const Size = Quill.import("attributors/style/size");
  // Size.whitelist = ["0.75em", "1em", "1.5em", "2.5em"];
  // Quill.register(Size, true);
  // const Parchment = Quill.import("parchment");
  // class IndentAttributor extends Parchment.Attributor.Style {
  //     add(node, value) {
  //       if (value === 0) {
  //         this.remove(node);
  //         return true;
  //       } else {
  //         return super.add(node, `${value * 2}em`);
  //       }
  //     }
  // }
  // let IndentStyle = new IndentAttributor("indent", "text-indent", {
  //   scope: Parchment.Scope.BLOCK,
  //   whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
  // });
  // Quill.register(IndentStyle, true);


  const handleVideo = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'video/*'
    input.onchange = handleFileSelect
    input.click()
  }

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const videoUrl = await uploadVideo(file)
        const quill = quillRef.current.getEditor()
        const range = quill.getSelection()
        quill.insertEmbed(range.index, 'video', videoUrl)
      } catch (error) {
        console.error('Error uploading video:', error)
      }
    }
  }

  const uploadVideo = (file) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      axios.post('/common/oss_signature/generate', { suffix: file.type.split('/')[1] })
        .then(function (response) {
          if (response.errcode === 0) {
            const formData = new FormData();
            formData.append('name',response.data.filename)
            formData.append('policy', response.data.policy)
            formData.append('OSSAccessKeyId', response.data.accessid)
            formData.append('success_action_status', '200')
            formData.append('signature', response.data.signature)
            formData.append('key', response.data.dir + response.data.filename)
            formData.append('file', file)

            fetch(response.data.host, { method: 'POST', body: formData })
              .then(() => {
                resolve(response.data.full_url)
                setIsLoading(false)
              }
            )
          } else {
            reject(new Error('API request failed'))
          }
        })
        .catch(function (error) {
          console.error('API request failed, error:', error)
          reject(error)
        })
    })
  }

  const modules = useMemo(() => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
          ['image'],
          ['video'],
        ],
        handlers: {
          'video': handleVideo,
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [])

  return (
    <div className="form-richText">
      <div className='form-richText-label'>{label}</div>
      <Spin spinning={isLoading}>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={(value) => setValue(prevValue => ({ ...prevValue, [id]: value }))}
          modules={modules}
          style={{ width: '100%', ...styles }}
        />
      </Spin>
    </div>
  )
}

export default MyRichText
