import React, { useState, useEffect } from 'react'
import { Input } from 'antd'
import MyIcon from '../../../components/general/icon'

const NoticeRibbon = ({
  isAddVisible,
  isImportVisible,
  isTemplateVisible,
  isBatchOperationVisible,
  filterValue,
  setFilterValue
}) => {
  const [keywordsValue, setKeywordsValue] = useState('')
  const [searchBoxVisible, setSearchBoxVisible] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFilterValue({ ...filterValue, keywords: keywordsValue })
    }, 300)
    return () => clearTimeout(timeoutId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordsValue])

  const handleBlur = () => {
    if (!keywordsValue) {
      setSearchBoxVisible(false)
      setFilterValue({ ...filterValue, keywords: '' })
    }
  }

  return (
    <div className='ribbon-page'>
      <div className='ribbon-container'>
        <div className='ribbon-button-primary' onClick={() => { isAddVisible() }}>新增学生</div>
        <div className='ribbon-button-secondary' onClick={() => { isImportVisible() }}>导入/导出</div>
        <div className='ribbon-button-secondary' onClick={() => { isBatchOperationVisible() }}>批量打印</div>
        <div className='ribbon-button-secondary' onClick={() => { isTemplateVisible() }}>通知书模版</div>

        {!searchBoxVisible ? 
          <div className='ribbon-condition' onClick={() => { setSearchBoxVisible(true) }}>
            <span><MyIcon name='sousuo'/></span>
            <p>搜索</p>
          </div>
        : <Input
            style={{width: '260px'}}
            prefix={<MyIcon name='sousuo'/>}
            maxLength={64}
            autoFocus
            placeholder='输入姓名或考生号'
            value={keywordsValue}
            onChange={(e) => setKeywordsValue(e.target.value)}
            onBlur={handleBlur}
            allowClear
          />
        }
      </div>
    </div>
  )
}

export default NoticeRibbon
