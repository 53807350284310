import React from 'react'
import { Radio } from 'antd'

const MyRadio = ({
  id,
  required = false,
  label = '',
  value,
  onChange,
  options,
  defaultValue
}) => {

  return (
    <div className="form-box">
      <label htmlFor={id}>
        {label} 
        {required && <span style={{ color: '#ae2a19' }}> *</span>}
      </label>

      <Radio.Group
        id={id}
        options={options}
        defaultValue={defaultValue}
        onChange={(e) => onChange(prevValues => ({ ...prevValues, [id]: e.target.value }))}
        value={value}
        optionType="button"
        buttonStyle="solid"
      />
    </div>
  )
}

export default MyRadio
