import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message, Button } from 'antd'
import MyInput from '../../../components/forms/input'
import MyRichText from '../../../components/forms/richText'
import MyColumnSelect from '../../../components/select/columnSelect'
import MyImageUpload from '../../../components/forms/imageUpload'

const AddCharacter = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  const handleSubmit = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({nameEmpty: '人物名称不能为空'})
      return false
    }
    if (!formValue.column_oid) {
      setErrorMsg({columnEmpty: '所属栏目不能为空'})
      return false
    }

    axios.post('/p/content/character/add', {
      merchant_oid: merchantOid,
      name: formValue.name,
      honor: formValue.honor,
      column_oid: formValue.column_oid,
      thumbnail: formValue.thumbnail,
      introduction: formValue.introduction,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('新人物创建成功！')
          props.getCharacterList()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleOk = () => {
    const isOk = handleSubmit()
    if (isOk !== false) {handleCancel()}
  }

  const handleNext = () => {
    handleSubmit()
    setFormValue({column_oid: formValue.column_oid})
  }

  const handleCancel = () => {
    setErrorMsg({})
    setFormValue({})
    props.isCancel()
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="创建人物"
        width='800px'
        open={props.isOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key='cancel' onClick={handleCancel}>取消</Button>,
          <Button key='submit' onClick={handleNext}>提交并新建下一条</Button>,
          <Button key='isOk' type="primary" onClick={handleOk}>创建</Button>
        ]}>

        <MyImageUpload 
          id="thumbnail"
          label='人物照片'
          value={formValue.thumbnail}
          setValue={setFormValue}
        />

        <MyColumnSelect
          id='column_oid'
          required
          label='选择栏目'
          category={4}
          value={formValue.column_oid}
          onChange={setFormValue}
          errorMsg={errorMsg.columnEmpty}
        />

        <MyInput
          id="name"
          required
          autoFocus
          maxLength={64}
          label='姓名'
          placeholder='请输入人物姓名'
          value={formValue.name}
          onChange={setFormValue}
          errorMsg={errorMsg.nameEmpty}
        />

        <MyInput
          id="honor"
          maxLength={64}
          label='荣誉'
          placeholder='请输入人物荣誉'
          value={formValue.honor}
          onChange={setFormValue}
        />

        <MyRichText
          id='introduction'
          required
          label='人物介绍'
          value={formValue.introduction}
          setValue={setFormValue}
        />

        <MyInput
          type='number'
          id="sort"
          label='排序'
          value={formValue.sort}
          onChange={setFormValue}
          styles={{ width: '60px' }}
          min={0}
          max={1000}
        />
      </Modal>
    </>
  )
}

export default AddCharacter
