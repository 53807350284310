import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Image } from 'antd'
import BasicFilter from '../../../components/filter/basicFilter'
import MyIcon from '../../../components/general/icon'
import AddNavigation from './add'
import EditNavigation from './edit'
import DeleteNavigation from './delete'

export default function MiniappNavigation() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [propsData, setPropsData] = useState({})
  const [navigationList, setNavigationList] = useState([])
  const [isAddNavigationVisible, setIsAddNavigationVisible] = useState(false)
  const [isEditNavigationVisible, setIsEditNavigationVisible] = useState(false)
  const [isDeleteNavigationVisible, setIsDeleteNavigationVisible] = useState(false)

  const initialConditions = {
    keywords: '',
    sort_filter: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getNavigationList = () => {
    axios.post('/p/deco/navigation/list/get', { 
      merchant_oid: merchantOid,
      source: 1
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setNavigationList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getNavigationList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue])

  const editNavigation = (oid) => {
    setIsEditNavigationVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteNavigation = (oid, name) => {
    setIsDeleteNavigationVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">首页金刚位</div>
          <BasicFilter
            addName='金刚位'
            filterValue={filterValue} 
            setFilterValue={setFilterValue} 
            isAddVisible={() => { setIsAddNavigationVisible(true) }}  
          />
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th style={{width: '300px'}}>
                    <div className='public-main-style2-title'>金刚位</div>
                  </th>
                  <th>打开模式</th>
                  <th>排序</th>
                  <th style={{width: '50px'}}></th>
                </tr>
              </thead>

              <tbody>
                {navigationList && (navigationList.length > 0 ? (navigationList.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editNavigation(item.oid) }}>
                    <td style={{width: '300px'}}>
                      <div className='public-main-style2-tuwen'>
                        <div style={{margin: '10px 15px 10px 0'}} onClick={(event) => {event.stopPropagation()}}>
                          <Image src={item.icon} width={30} height={30} />
                        </div>
                        <span>{item.name}</span>
                      </div>
                    </td>
                    <td>{item.mode}</td>
                    <td>{item.sort}</td>
                    <td style={{width: '50px'}}>
                      <div className='public-main-style2-operation-box'>
                        <div className='public-main-style2-operation' 
                          onClick={(event) => {event.stopPropagation(); deleteNavigation(item.oid, item.name)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan='4'><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isAddNavigationVisible && <AddNavigation
        getNavigationList={getNavigationList} 
        isOpen={isAddNavigationVisible} 
        isCancel={() => { setIsAddNavigationVisible(false) }} 
      />}

      {isEditNavigationVisible && <EditNavigation
        data={propsData}
        getNavigationList={getNavigationList} 
        isOpen={isEditNavigationVisible} 
        isCancel={() => { setIsEditNavigationVisible(false) }} 
      />}

      {isDeleteNavigationVisible && <DeleteNavigation
        data={propsData}
        getNavigationList={getNavigationList} 
        isOpen={isDeleteNavigationVisible} 
        isCancel={() => { setIsDeleteNavigationVisible(false) }} 
      />}
    </div>
  )
}
