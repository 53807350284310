import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal } from 'antd'

const ChatBotKnowledgeDetail = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [chatBotKnowledgeDetail, setChatBotKnowledgeDetail] = useState({})

  useEffect(() => {
    axios.post('/p/chatbot/knowledge/detail/get', {
      merchant_oid: merchantOid,
      knowledge_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setChatBotKnowledgeDetail(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleCancel = () => {
    props.isCancel()
  }

  return (
    <Modal 
      title="知识详情"
      destroyOnClose={true}
      width='700px'
      open={props.isOpen}
      onCancel={handleCancel}
      footer={false}>

      <div className='public-datail-container'>
        <div className='public-datail-item'>
          <div className='public-datail-label' style={{width: '100px'}}>问题</div>
          <div className='public-datail-content'>{chatBotKnowledgeDetail.question}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label' style={{width: '100px'}}>关键字</div>
          <div className='public-datail-content'>{chatBotKnowledgeDetail.keywords}</div>
        </div>
        
        <div className='public-datail-item2'>
          <div className='public-datail-label'>相似提问</div>
          {chatBotKnowledgeDetail.similar_questions && (chatBotKnowledgeDetail.similar_questions.length > 0 ? (chatBotKnowledgeDetail.similar_questions.map((item) => (
            <div className='public-datail-content' key={item}>{item}</div>
          ))) : (
            <div className='public-datail-content'>空</div>
          ))}
        </div>

        <div className='public-datail-item'>
          <div className='public-datail-label' style={{width: '100px'}}>匹配答案</div>
          <div className='public-datail-content'>{chatBotKnowledgeDetail.answer}</div>
        </div>
      </div>
    </Modal>
  )
}

export default ChatBotKnowledgeDetail
