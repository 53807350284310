import React from 'react'
import MyIcon from './icon'

const MyAvatar = ({ 
  userObject, 
  avatarSize = 30,
  fontSize = '14px',
  slice
}) => {
  if (userObject === null) {
    return (
      <div style={{fontSize: avatarSize, color: '#ced2d9'}}>
        <MyIcon name='touxiang' />
      </div>
    )
  } else {
    if (userObject.avatar) {
      const avatarStyle = {
        width: avatarSize,
        height: avatarSize,
      }
      return <img className="general-avatar" src={userObject.avatar} alt="" style={avatarStyle} />
  
    } else {
      const abbreviationText = slice ? userObject.abbreviation.slice(slice) : userObject.abbreviation;
      const avatarStyle = {
        fontSize: fontSize,
        backgroundColor: userObject.background_color,
        width: avatarSize,
        height: avatarSize,
      }

      return (
        <div className="general-avatar" style={avatarStyle}>
          <span className="general-abbreviation" style={{lineHeight: `${avatarSize}px`}}>
            {abbreviationText}
          </span>
        </div>
      )
    }
  }
}

export default MyAvatar
