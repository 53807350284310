import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message, Button } from 'antd'
import MyInput from '../../../components/forms/input'
import MyTextArea from '../../../components/forms/textArea'
import MyRadio from '../../../components/forms/radio'
import MyImageUpload from '../../../components/forms/imageUpload'

const AddContact = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  const handleSubmit = () => {
    setErrorMsg({})
    if (!formValue.title) {
      setErrorMsg({titleEmpty: '标题不能为空'})
      return false
    }
    if (!formValue.description) {
      setErrorMsg({descriptionEmpty: '描述不能为空'})
      return false
    }

    axios.post('/p/info/contact/add', {
      merchant_oid: merchantOid,
      title: formValue.title,
      description: formValue.description,
      category: Number(formValue.category),
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('创建成功！')
          props.getContactList()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleOk = () => {
    const isOk = handleSubmit()
    if (isOk !== false) {handleCancel()}
  }

  const handleNext = () => {
    handleSubmit()
    setFormValue({college_oid: formValue.college_oid})
  }

  const handleCancel = () => {
    setErrorMsg({})
    setFormValue({})
    props.isCancel()
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="创建联系信息"
        width='760px'
        open={props.isOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key='cancel' onClick={handleCancel}>取消</Button>,
          <Button key='submit' onClick={handleNext}>提交并新建下一条</Button>,
          <Button key='isOk' type="primary" onClick={handleOk}>创建</Button>
        ]}>

        <MyInput
          id="title"
          required
          autoFocus
          maxLength={64}
          label='标题'
          placeholder='请输入标题'
          value={formValue.title}
          onChange={setFormValue}
          errorMsg={errorMsg.titleEmpty}
        />

        <MyRadio
          id='category'
          label='类型'
          options={[
            {
              label: '普通文本',
              value: 1,
            },
            {
              label: '电话号码',
              value: 2,
            },
            {
              label: '图片',
              value: 3,
            }
          ]}
          defaultValue={1}
          value={formValue.category}
          onChange={setFormValue}
        />

        {formValue.category !== 3 && <MyTextArea 
          id="description"
          required
          label='描述'
          minRows={3}
          placeholder='请输入描述'
          value={formValue.description}
          onChange={setFormValue}
          errorMsg={errorMsg.descriptionEmpty}
        />}

        {formValue.category === 3 && <MyImageUpload 
          id="description"
          required
          label='上传图片'
          value={formValue.description}
          setValue={setFormValue}
          errorMsg={errorMsg.descriptionEmpty}
        />}

        <MyInput
          type='number'
          id="sort"
          label='排序'
          value={formValue.sort}
          onChange={setFormValue}
          styles={{ width: '60px' }}
          min={0}
          max={1000}
        />
      </Modal>
    </>
  )
}

export default AddContact
