import React, { createContext, useState } from 'react'

const MyContext = createContext({})

const MyContextProvider = ({children}) => {
  const [merchantName, setMerchantName] = useState('')

  const contextValue = {
    merchantName,
    setMerchantName,
  }

  return (
    <MyContext.Provider value={contextValue}>
      {children}
    </MyContext.Provider>
  )
}

export { MyContext, MyContextProvider }
