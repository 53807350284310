import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Pagination, Tooltip } from 'antd'
import MyIcon from '../../../components/general/icon'
import FreshmenReadmissionRibbon from './ribbon'
import DeleteFreshmenReadmission from './delete'
import FreshmenReadmissionDetail from './detail'
import ExportFreshmenReadmission from './export'

export default function FreshmenReadmission() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [currentPage, setCurrentPage] = useState(1)
  const [propsData, setPropsData] = useState({})
  const [applicationData, setApplicationData] = useState({})
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [isDetailVisible, setIsDetailVisible] = useState(false)
  const [isExportVisible, setIsExportVisible] = useState(false)

  const initialConditions = {
    keywords: ''
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getApplicationData = () => {
    axios.post('/p/retention_admission/application/list/get', { 
      merchant_oid: merchantOid,
      application_type: 2,
      keywords: filterValue.keywords,
      page: currentPage, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setApplicationData({
            'total_page': response.data.total_page,
            'current_page': response.data.current_page,
            'total': response.data.total,
            'applications': response.data.applications
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getApplicationData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, currentPage, filterValue])

  const getColor = (key) => {
    if (key === 2) return 'green'
    if (key === 3) return 'red'
    return 'black'
  }

  const previewDetail = (oid) => {
    setIsDetailVisible(true)
    setPropsData({ oid: oid})
  }

  const deleteApplication = (oid, name) => {
    setIsDeleteVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">复学申请</div>
          <FreshmenReadmissionRibbon 
            filterValue={filterValue} 
            setFilterValue={setFilterValue}
            isExportVisible={() => { setIsExportVisible(true) }} 
          />
        </div>

        <div className='public-main-style2'>
          <table className='public-main-style2-item'>
            <thead>
              <tr className='public-main-style2-thead'>
                <th style={{textAlign: 'left', paddingLeft: '20px'}}>姓名</th>
                <th>身份证号</th>
                <th>学院</th>
                <th>专业</th>
                <th>申请原因</th>
                <th>审核状态</th>
                <th>申请时间</th>
                <th style={{width: '50px'}}></th>
              </tr>
            </thead>

            <tbody>
              {applicationData.applications && (applicationData.applications.length > 0 ? (applicationData.applications.map((item) => (
                <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { previewDetail(item.oid) }}>
                  <td style={{textAlign: 'left', paddingLeft: '20px'}}>{item.name}</td>
                  <td>{item.id_number}</td>
                  <td>{item.college}</td>
                  <td>{item.major}</td>
                  <td>{item.application_reason_type.value}</td>
                  <td style={{ color: getColor(item.audit_status.key) }}>{item.audit_status.value}</td>
                  <td>{item.create_time}</td>
                  <td style={{width: '100px'}}>
                    <div className='public-main-style2-operation-box'>
                      <Tooltip placement="bottom" color='#1A1B1C' title="删除">
                        <div className='public-main-style2-operation' onClick={(event) => {event.stopPropagation(); deleteApplication(item.oid, item.name)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))) : (
                <tr className='public-main-style2-tbodyEmpty'>
                  <td colSpan="8"><span>暂无数据</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='pagination'>
          <Pagination 
            current={currentPage}
            total={applicationData.total}
            pageSize={20}
            onChange={(page) => setCurrentPage(page)}
            showTotal={(total) => `共${total}条`}
            showSizeChanger={false}
            hideOnSinglePage={true}
            showQuickJumper={true}
          />
        </div>
      </div>

      {isDeleteVisible && <DeleteFreshmenReadmission
        data={propsData}
        getApplicationData={getApplicationData}
        isOpen={isDeleteVisible}
        isCancel={() => { setIsDeleteVisible(false) }}
      />}

      {isDetailVisible && <FreshmenReadmissionDetail
        data={propsData}
        isOpen={isDetailVisible}
        getApplicationData={getApplicationData}
        isCancel={() => { setIsDetailVisible(false) }}
      />}

      {isExportVisible && <ExportFreshmenReadmission
        isOpen={isExportVisible}
        isCancel={() => { setIsExportVisible(false) }}
      />}
    </div>
  )
}
