import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Tooltip } from 'antd'
import { MyContext } from '../../context'
import AcccountSelect from '../people'
import Shop from './setting'
import PrimaryNav from './primaryNav'
import Notice from './notice'

export default function Main() {
  const { merchantName } = useContext(MyContext)

  return (
    <div className="main-page">
      <div className="main-header">
        <div className="main-merchantName">{merchantName}</div>
        <div className="main-box">
          <div className="main-func">
            <Shop />
          </div>

          <Tooltip placement="bottom" color='#1A1B1C' title="通知">
            <div className="main-func">
              <Notice />
            </div>
          </Tooltip>

          <Tooltip placement="bottom" color='#1A1B1C' title="我的">
            <div className="main-account">
              <AcccountSelect />
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="main-nav">
        <PrimaryNav />
      </div>

      <div className="main-outlet">
        <Outlet />
      </div>
    </div>
  )
}
