import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message, Button, Spin } from 'antd'
import MyInput from '../../../components/forms/input'
import MyColumnSelect from '../../../components/select/columnSelect'
import MyImageUpload from '../../../components/forms/imageUpload'
import MyRichText from '../../../components/forms/richText'
import MyVideoUpload from '../../../components/forms/videoUpload'

const AddVideo = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    setErrorMsg({})
    if (!formValue.thumbnail) {
      setErrorMsg({thumbnailEmpty: '缩略图不能为空'})
      return false
    }
    if (!formValue.filename) {
      setErrorMsg({filenameEmpty: '视频不能为空'})
      return false
    }
    if (!formValue.column_oid) {
      setErrorMsg({columnEmpty: '所属栏目不能为空'})
      return false
    }

    axios.post('/p/content/video/add', {
      merchant_oid: merchantOid,
      title: formValue.title,
      description: formValue.description,
      column_oid: formValue.column_oid,
      thumbnail: formValue.thumbnail,
      filename: formValue.filename,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('新视频添加成功！')
          props.getVideoList()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleOk = () => {
    const isOk = handleSubmit()
    if (isOk !== false) {handleCancel()}
  }

  const handleNext = () => {
    handleSubmit()
    setFormValue({column_oid: formValue.column_oid})
  }

  const handleCancel = () => {
    setErrorMsg({})
    setFormValue({})
    props.isCancel()
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="添加视频"
        width='600px'
        open={props.isOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key='cancel' onClick={handleCancel}>取消</Button>,
          <Button key='submit' onClick={handleNext}>提交并新建下一条</Button>,
          <Button key='isOk' type="primary" onClick={handleOk}>创建</Button>
        ]}>

        <Spin spinning={isLoading}>
          <MyImageUpload 
            id="thumbnail"
            required
            label='缩略图'
            size={80}
            value={formValue.thumbnail}
            setValue={setFormValue}
            errorMsg={errorMsg.thumbnailEmpty}
          />

          <MyVideoUpload 
            id="filename"
            required
            label='视频'
            value={formValue.filename}
            setValue={setFormValue}
            setIsLoading={setIsLoading}
          />

          <MyColumnSelect
            id='column_oid'
            required
            label='选择栏目'
            category={3}
            value={formValue.column_oid}
            onChange={setFormValue}
            errorMsg={errorMsg.columnEmpty}
          />

          <MyInput
            id="title"
            maxLength={64}
            label='标题'
            placeholder='请输入视频标题'
            value={formValue.title}
            onChange={setFormValue}
          />

          <MyRichText
            id='description'
            required
            label='视频描述'
            value={formValue.description}
            setValue={setFormValue}
          />

          <MyInput
            type='number'
            id="sort"
            label='排序'
            value={formValue.sort}
            onChange={setFormValue}
            styles={{ width: '60px' }}
            min={0}
            max={1000}
          />
        </Spin>
      </Modal>
    </>
  )
}

export default AddVideo
