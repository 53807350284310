import React from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'

const DeleteFaq = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const { oid, question } = props.data

  const handleOk = () => {
    axios.post('/p/content/faq/delete', { 
      merchant_oid: merchantOid, 
      faq_oid: oid 
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('常见问题删除成功！')
          props.isCancel()
          props.getFaqList()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <Modal 
      title="删除常见问题"
      open={props.isOpen}
      onOk={handleOk}
      onCancel={props.isCancel}
      cancelText="取消"
      okType='danger'
      okText='删除'>

      <p>正在将常见问题
        <span style={{color: '#005dff'}}>《{question}》</span>
        从列表中移除，此操作不可逆，是否确认删除？
      </p>
    </Modal>
  )
}

export default DeleteFaq
