import React from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'

const DeleteFacebodyCategory = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const { oid, name } = props.data

  const handleOk = () => {
    axios.post('/p/facebody/category/delete', { 
      merchant_oid: merchantOid, 
      facebody_category_oid: oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('删除人脸核身分类成功！')
          props.isCancel()
          props.getFacebodyCategoryList()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <Modal 
      title="删除人脸核身分类"
      open={props.isOpen}
      onOk={handleOk}
      onCancel={props.isCancel}
      cancelText="取消"
      okType='danger'
      okText='删除'>

      <p>正在将校区
        <span style={{color: '#005dff'}}>《{name}》</span>
        从列表中移除，此操作不可逆，是否确认删除？
      </p>
    </Modal>
  )
}

export default DeleteFacebodyCategory
