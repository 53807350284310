import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Pagination } from 'antd'
import ImportChatbotKnowledge from './import'
import ChatbotKnowledgeRibbon from './ribbon'
import EmptyChatbotKnowledge from './empty'
import ChatBotKnowledgeDetail from './detail'

export default function ChatbotKnowledge() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [currentPage, setCurrentPage] = useState(1)
  const [knowledgeData, setKnowledgeData] = useState({})
  const [isImportVisible, setIsImportVisible] = useState(false)
  const [isEmptyVisible, setIsEmptyVisible] = useState(false)
  const [propsData, setPropsData] = useState({})
  const [isDetailVisible, setIsDetailVisible] = useState(false)

  const initialConditions = {
    category_oid: '',
    keywords: ''
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getKnowledgeData = () => {
    axios.post('/p/chatbot/knowledge/list/get', { 
      merchant_oid: merchantOid,
      category_oid: filterValue.category_oid,
      keywords: filterValue.keywords,
      page: currentPage, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setKnowledgeData({
            'total_page': response.data.total_page,
            'current_page': response.data.current_page,
            'total': response.data.total,
            'knowledges': response.data.knowledges
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getKnowledgeData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, currentPage, filterValue])

  const previewDetail = (oid) => {
    setIsDetailVisible(true)
    setPropsData({ oid: oid})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">知识库</div>
          <ChatbotKnowledgeRibbon 
            filterValue={filterValue} 
            setFilterValue={setFilterValue}
            isImportVisible={() => { setIsImportVisible(true) }}
            isEmptyVisible={() => { setIsEmptyVisible(true) }}
          />
        </div>

        <div className='public-main-style2'>
          <table className='public-main-style2-item'>
            <thead>
              <tr className='public-main-style2-thead'>
                <th style={{textAlign: 'left', paddingLeft: '20px'}}>问题</th>
                <th>类目</th>
                <th>相似提问数量</th>
                <th>关键字</th>
              </tr>
            </thead>

            <tbody>
              {knowledgeData.knowledges && (knowledgeData.knowledges.length > 0 ? (knowledgeData.knowledges.map((item) => (
                <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { previewDetail(item.oid) }}>
                  <td style={{textAlign: 'left', paddingLeft: '20px'}}>{item.question}</td>
                  <td>{item.category}</td>
                  <td>{item.similar_question_number}</td>
                  <td>{item.keywords}</td>
                </tr>
              ))) : (
                <tr className='public-main-style2-tbodyEmpty'>
                  <td colSpan="4"><span>暂无数据</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='pagination'>
          <Pagination 
            current={currentPage}
            total={knowledgeData.total}
            pageSize={20}
            onChange={(page) => setCurrentPage(page)}
            showTotal={(total) => `共${total}条`}
            showSizeChanger={false}
            hideOnSinglePage={true}
            showQuickJumper={true}
          />
        </div>
      </div>

      {isImportVisible && <ImportChatbotKnowledge
        getKnowledgeData={getKnowledgeData}
        isOpen={isImportVisible}
        isCancel={() => { setIsImportVisible(false) }}
      />}

      {isEmptyVisible && <EmptyChatbotKnowledge
        getKnowledgeData={getKnowledgeData}
        isOpen={isEmptyVisible}
        isCancel={() => { setIsEmptyVisible(false) }}
      />}

      {isDetailVisible && <ChatBotKnowledgeDetail
        data={propsData}
        isOpen={isDetailVisible}
        isCancel={() => { setIsDetailVisible(false) }}
      />}
    </div>
  )
}
