import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Pagination } from 'antd'
import ChatbotRecordRibbon from './ribbon'
import ChatBotRecordDetail from './detail'

export default function ChatbotRecord() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [recordData, setRecordData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [propsData, setPropsData] = useState({})
  const [isDetailVisible, setIsDetailVisible] = useState(false)

  const initialConditions = {
    is_hit: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  useEffect(() => {
    axios.post('/p/chatbot/record/list/get', { 
      merchant_oid: merchantOid,
      is_hit: filterValue.is_hit,
      page: currentPage, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setRecordData({
            'total_page': response.data.total_page,
            'current_page': response.data.current_page,
            'total': response.data.total,
            'records': response.data.records
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, currentPage, filterValue])

  const getColor = (isHit) => {
    if (isHit === '未命中') {
      return 'red'
    } else if (isHit === '反问') {
      return 'orange'
    } else {
      return 'green'
    }
  }

  const previewDetail = (oid) => {
    setIsDetailVisible(true)
    setPropsData({ oid: oid})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">会话记录</div>
          <ChatbotRecordRibbon 
            filterValue={filterValue} 
            setFilterValue={setFilterValue}
          />
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th>
                    <div className='public-main-style2-title'>咨询问题</div>
                  </th>
                  <th>状态</th>
                  <th>咨询时间</th>
                </tr>
              </thead>

              <tbody>
                {recordData.records && (recordData.records.length > 0 ? (recordData.records.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { previewDetail(item.oid) }}>
                    <td>
                      <div className='public-main-style2-title2'>
                        <p>Q：{item.question}</p>
                        <p>A：{item.answer || item.reverse_questions}</p>
                      </div>
                    </td>
                    <td style={{ color: getColor(item.is_hit) }}>{item.is_hit}</td>
                    <td>{item.create_time}</td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan='3'><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='pagination'>
            <Pagination 
              current={currentPage}
              total={recordData.total}
              pageSize={20}
              onChange={(page) => setCurrentPage(page)}
              showTotal={(total) => `共${total}条`}
              showSizeChanger={false}
              hideOnSinglePage={true}
              showQuickJumper={true}
            />
          </div>
        </div>
      </div>

      {isDetailVisible && <ChatBotRecordDetail
        data={propsData}
        isOpen={isDetailVisible}
        isCancel={() => { setIsDetailVisible(false) }}
      />}
    </div>
  )
}
