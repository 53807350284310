import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyTextArea from '../../../components/forms/textArea'

const EditNoAnswerResponse = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({
    no_answer_response: props.data.info.no_answer_response
  })
  const [errorMsg, setErrorMsg] = useState({})

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.no_answer_response) {
      setErrorMsg({noAnswerResponseEmpty: '无答案默认回应不能为空'})
      return false
    }

    axios.post('/p/chatbot/config/edit', { 
      merchant_oid: merchantOid, 
      no_answer_response: formValue.no_answer_response
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getChatbotConfig()
          message.success('无答案默认回应修改成功！')
        } else {
          message.error(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title={'无答案默认回应'}
      destroyOnClose={true}
      width='700px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyTextArea
        id="no_answer_response"
        minRows={5}
        placeholder='请输入无答案默认回应话术'
        value={formValue.no_answer_response}
        onChange={setFormValue}
        errorMsg={errorMsg.noAnswerResponseEmpty}
      />
    </Modal>
  )
}

export default EditNoAnswerResponse
