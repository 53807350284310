import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyRichText from '../../../components/forms/richText'
import MyImageUpload from '../../../components/forms/imageUpload'

const EditUnit = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/jobs/unit/detail/get', {
      merchant_oid: merchantOid,
      unit_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({nameEmpty: '单位名称不能为空'})
      return false
    }

    axios.post('/p/jobs/unit/edit', { 
      merchant_oid: merchantOid, 
      unit_oid: formValue.oid, 
      name: formValue.name,  
      sort: Number(formValue.sort),
      logo: formValue.logo,
      serial_number: formValue.serial_number,
      introduction: formValue.introduction
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getUnitList()
          message.success('修改成功！')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title='编辑招聘单位'
      destroyOnClose={true}
      width='780px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyImageUpload 
        id="logo"
        label='单位Logo'
        value={formValue.logo}
        setValue={setFormValue}
      />

      <MyInput
        id="name"
        required
        autoFocus
        maxLength={64}
        label='名称'
        placeholder='请输入单位的名称'
        value={formValue.name}
        onChange={setFormValue}
        errorMsg={errorMsg.nameEmpty}
      />

      <MyInput
        id="serial_number"
        maxLength={64}
        label='单位序号'
        placeholder='请输入单位序号'
        value={formValue.serial_number}
        onChange={setFormValue}
      />

      <MyRichText
        id='introduction'
        label='介绍'
        value={formValue.introduction}
        setValue={setFormValue}
      />

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />
    </Modal>
  )
}

export default EditUnit
