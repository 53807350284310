import React, { useState, useEffect } from 'react'
import { Input } from 'antd'
import MyIcon from '../../../components/general/icon'

const FacebodyRibbon = ({
  isImportVisible,
  isExportVisible,
  isDownloadVisible,
  filterValue,
  setFilterValue
}) => {
  const [keywordsValue, setKeywordsValue] = useState('')
  const [searchBoxVisible, setSearchBoxVisible] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFilterValue({ ...filterValue, keywords: keywordsValue })
    }, 300)
    return () => clearTimeout(timeoutId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordsValue])

  const handleBlur = () => {
    if (!keywordsValue) {
      setSearchBoxVisible(false)
      setFilterValue({ ...filterValue, keywords: '' })
    }
  }

  return (
    <div className='ribbon-page'>
      <div className='ribbon-container'>
        <div className='ribbon-button-primary' onClick={() => { isImportVisible() }}>导入数据</div>
        <div className='ribbon-button-secondary' onClick={() => { isExportVisible() }}>导出数据</div>
        <div className='ribbon-button-secondary' onClick={() => { isDownloadVisible() }}>批量下载核验报告</div>

        {!searchBoxVisible ? 
          <div className='ribbon-condition' onClick={() => { setSearchBoxVisible(true) }}>
            <span><MyIcon name='sousuo'/></span>
            <p>搜索</p>
          </div>
        : <Input
            style={{width: '260px'}}
            prefix={<MyIcon name='sousuo'/>}
            maxLength={64}
            autoFocus
            placeholder='输入姓名、身份证号或考生号'
            value={keywordsValue}
            onChange={(e) => setKeywordsValue(e.target.value)}
            onBlur={handleBlur}
            allowClear
          />
        }
      </div>
    </div>
  )
}

export default FacebodyRibbon
