import React from 'react'
import { Select } from 'antd'

const ChatbotRecordRibbon = ({
  filterValue,
  setFilterValue
}) => {

  let items = [
    {
      value: 0,
      label: '全部'
    },
    {
      value: 1,
      label: '未命中'
    },
    {
      value: 2,
      label: '命中'
    }
  ]

  const clickIsHITFilter = (value) => {
    setFilterValue({ ...filterValue, is_hit: value })
  }

  return (
    <div className='ribbon-page'>
      <div className='ribbon-container'>
        <Select
          placeholder="是否命中"
          options={items}
          style={{height: '32px', width: '200px'}}
          onChange={(value) => clickIsHITFilter(value)}
        />
      </div>
    </div>
  )
}

export default ChatbotRecordRibbon
