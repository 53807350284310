import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Select } from 'antd'

const JobApplicationRibbon = ({
  isExportVisible,
  filterValue,
  setFilterValue
}) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [unitList, setUnitList] = useState([])
  const [jobList, setJobList] = useState([])

  useEffect(() => {
    axios.post('/p/jobs/unit/list/get', { 
      merchant_oid: merchantOid 
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setUnitList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })

    axios.post('/p/jobs/post/list/get', { 
      merchant_oid: merchantOid,
      unit_oid: filterValue.unit_oid,
      per_page: 100
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setJobList(response.data.job_posts)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, filterValue])

  let unitItems = [
    {  
      value: '', 
      label: '全部单位'
    },
    ...unitList.map((item) => (
      {
        value: item.oid,
        label: item.name
      }
    ))
  ]

  let jobItems = [
    {  
      value: '', 
      label: '全部职位'
    },
    ...jobList.map((item) => (
      {
        value: item.oid,
        label: item.name
      }
    ))
  ]

  const clickUnitFilter = (value) => {
    setFilterValue({ ...filterValue, unit_oid: value, job_oid: '' })
  }

  const clickJobFilter = (value) => {
    setFilterValue({ ...filterValue, job_oid: value })
  }

  return (
    <div className='ribbon-page'>
      <div className='ribbon-container'>
        <div className='ribbon-button-secondary' onClick={() => { isExportVisible() }}>导出数据</div>
        <Select
          placeholder="所属单位"
          options={unitItems}
          style={{height: '32px', width: '200px'}}
          onChange={(value) => clickUnitFilter(value)}
        />
        <Select
          placeholder="所属职位"
          options={jobItems}
          style={{height: '32px', width: '200px'}}
          onChange={(value) => clickJobFilter(value)}
        />
      </div>
    </div>
  )
}

export default JobApplicationRibbon
