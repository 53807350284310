import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import { MyContext } from '../../context'
import MyIcon from '../../components/general/icon'
import MyInput from '../../components/forms/input'
import MyTextArea from '../../components/forms/textArea'
import MyImageUpload from '../../components/forms/imageUpload'

const Shop = () => {
  const { setMerchantName } = useContext(MyContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/merchant/setting/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
          setMerchantName(response.data.name)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({ nameEmpty: '学校名称不能为空' })
      return
    }

    axios.post('/p/merchant/setting/edit',
      {
        merchant_oid: merchantOid,
        name: formValue.name,
        slogan: formValue.slogan,
        avatar: formValue.avatar,
        wx_appid: formValue.wx_appid,
        wx_secret: formValue.wx_secret,
        dy_appid: formValue.dy_appid,
        dy_secret: formValue.dy_secret
      })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          setMerchantName(formValue.name)
          message.success('学校资料修改成功！')
        } else if (response.errcode === 1) {
          setErrorMsg({nameRepeatError: response.msg})
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    setIsModalOpen(false)
  }

  return (
    <>
      <span style={{ color: "#fff", fontSize: '18px', }} onClick={() => {setIsModalOpen(true)}}>
        <MyIcon name='dianpu' />
      </span>

      <Modal
        destroyOnClose={true}
        title="修改商户资料"
        width='500px'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="取消"
        okText="保存">

        <MyImageUpload 
          id="avatar"
          value={formValue.avatar}
          setValue={setFormValue}
        />

        <MyInput
          id="name"
          label="学校名称"
          placeholder='请输入学校名称'
          value={formValue.name}
          onChange={setFormValue}
          errorMsg={errorMsg.nameEmpty || errorMsg.nameRepeatError}
        />

        <MyTextArea 
          id="slogan"
          label='学校标语'
          minRows={2}
          placeholder='请输入学校标语'
          value={formValue.slogan}
          onChange={setFormValue}
        />

        <MyInput
          id="wx_appid"
          label="微信小程序appid"
          placeholder='请输入微信小程序appid'
          value={formValue.wx_appid}
          onChange={setFormValue}
        />

        <MyInput
          id="wx_secret"
          label="微信小程序secret"
          placeholder='请输入微信小程序secret'
          value={formValue.wx_secret}
          onChange={setFormValue}
        />

        <MyInput
          id="dy_appid"
          label="抖音小程序appid"
          placeholder='请输入抖音小程序appid'
          value={formValue.dy_appid}
          onChange={setFormValue}
        />

        <MyInput
          id="dy_secret"
          label="抖音小程序secret"
          placeholder='请输入抖音小程序secret'
          value={formValue.dy_secret}
          onChange={setFormValue}
        />
      </Modal>
    </>
  )
}

export default Shop
