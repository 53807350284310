import React, { useContext } from 'react'
import { MyContext } from '../../context'

export default function Index() {
  const { merchantName } = useContext(MyContext)

  return (
    <div className='dashboard-page'>
      <div className='dashboard-container'>
        <div className='dashboard-container-left'>
          <div className='dashboard-container-left-greeting'>
            您好，欢迎回到{merchantName}招就管理后台
          </div>
          <div className='dashboard-container-left-overview'>
            <div className='dashboard-container-left-title'>访客数据</div>
            <div className='dashboard-container-left-content'>
              <img src="images/sprint.png" alt="" />
              <span>访客数据即将推出</span>
            </div>
          </div>
        </div>
        <div className='dashboard-container-right'>
          <div className='dashboard-container-right-dynamic'>
            <div className='dashboard-container-right-title'>产品动态</div>
            <div className='dashboard-container-right-content'>
              <img src="images/nodata.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div className='dashboard-container-right-news'>
            <div className='dashboard-container-right-title'>云招新闻</div>
            <div className='dashboard-container-right-content'>
              <img src="images/nodata.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
