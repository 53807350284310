import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MyIcon from '../../../components/general/icon'
import AddUnit from './add'
import EditUnit from './edit'
import DeleteUnit from './delete'

export default function JobUnit() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [unitList, setUnitList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isAddUnitVisible, setIsAddUnitVisible] = useState(false)
  const [isEditUnitVisible, setIsEditUnitVisible] = useState(false)
  const [isDeleteUnitVisible, setIsDeleteUnitVisible] = useState(false)

  const getUnitList = () => {
    axios.post('/p/jobs/unit/list/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setUnitList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getUnitList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const editUnit = (oid) => {
    setIsEditUnitVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteUnit = (oid, name) => {
    setIsDeleteUnitVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">单位管理</div>
        </div>

        <div className='public-main-style'>
          <div className='public-main-style1'>
            <div className='public-main-style1-add' onClick={() => { setIsAddUnitVisible(true) }}>
              <div className='public-main-style1-add-icon'>
                <MyIcon name='jiahao' />
              </div>
              <div className='public-main-style1-add-title'>创建新的招聘单位</div>
            </div>

            {unitList.map(unit => (
              <div className='public-main-style1-content' key={unit.oid} onClick={() => { editUnit(unit.oid) }}>
                <div>
                  <div className='public-main-style1-content-title'>
                    <div className='public-main-style1-content-icon'>
                      {unit.logo ? (  
                        <img style={{width: '28px', height: '28px'}} src={unit.logo} alt="" />
                      ) : (  
                        <span style={{fontSize: '28px'}}><MyIcon name='gongsi' /></span>
                      )} 
                    </div>
                    <div className='public-main-style1-content-name'>{unit.name}</div>
                    <div className='public-main-style1-content-delete' 
                      onClick={(event) => {event.stopPropagation(); deleteUnit(unit.oid, unit.name)}}>
                      <MyIcon name='huishouzhan' />
                    </div>
                  </div>
                  <div className='public-main-style1-content-description'>
                    {unit.introduction ? unit.introduction : '暂无招聘单位文字介绍'}
                  </div>
                </div>
                <div className='public-main-style1-content-other'>共发布了 {unit.job_number} 个招聘职位</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isAddUnitVisible && <AddUnit
        getUnitList={getUnitList} 
        isOpen={isAddUnitVisible} 
        isCancel={() => { setIsAddUnitVisible(false) }} 
      />}

      {isEditUnitVisible && <EditUnit
        data={propsData}
        getUnitList={getUnitList}
        isOpen={isEditUnitVisible}
        isCancel={() => { setIsEditUnitVisible(false) }}
      />}

      {isDeleteUnitVisible && <DeleteUnit
        data={propsData}
        getUnitList={getUnitList}
        isOpen={isDeleteUnitVisible}
        isCancel={() => { setIsDeleteUnitVisible(false) }}
      />}
    </div>
  )
}
