import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Select } from 'antd'
import MyIcon from '../general/icon'

const MyUnitSelect = ({ 
  id,
  required = false,
  label = '',
  value = '',
  onChange,
  errorMsg,
  disabled = false,
  notFoundContent = '暂无数据',
  styles
}) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [unitList, setUnitList] = useState([])

  useEffect(() => {
    axios.post('/p/jobs/unit/list/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setUnitList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid])

  let items = [
    ...unitList.map((item) => (
      {
        value: item.oid,
        label: item.name
      }
    ))
  ]

  if (unitList.length === 0) {
    value = ''
    items = []
  }

  const noData = (
    <div className='select-noData'>
      <span style={{ fontSize: '50px', color: '#aaa' }}><MyIcon name='kong' /></span>
      <p>{notFoundContent}</p>
    </div>
  )

  return (
    <div className='select-container'>
      <label>
        {label}
        {required && <span style={{ color: '#ae2a19' }}> *</span>}
      </label>

      <Select
        disabled={disabled}
        placeholder="选择一个所属招聘单位"
        value={value}
        onChange={(value) => onChange(prevValues => ({ ...prevValues, [id]: value }))}
        notFoundContent={noData}
        options={items}
        style={{ width: '360px', height: '40px', marginBottom: '5px', ...styles }}
      />

      {errorMsg && (
        <div className="form-errormsg">
          <span><MyIcon name='jinggao' /></span> 
          <p>{errorMsg}</p>
        </div>
      )}
    </div>  
  )
}

export default MyUnitSelect
