import React from 'react'
import { useNavigate } from 'react-router-dom'
import MyButton from '../../components/forms/button'

export default function Forbidden() {
  const navigate = useNavigate()

  return (
    <div className="error-page">
      <div className="error-container">
        <img className="error-figure" src='/images/403.png' alt="403" />
        <p className="error-title">没有当前项目的访问权限</p>
        <MyButton 
          onClick={() => { navigate('/index') }} 
          buttonText='返回首页'
          style={{ width: '120px', marginTop: '30px' }}
        />
      </div>
    </div>
  )
}
