import React from 'react'
import { useNavigate } from 'react-router-dom'
import MyButton from '../../components/forms/button'

export default function InternalServerError() {
  const navigate = useNavigate()

  return (
    <div className="error-page">
      <div className="error-container">
        <img className="error-figure" src='/images/500.png' alt="500" />
        <p className="error-title">非常抱歉，服务器发生了异常错误</p>
        <MyButton 
          onClick={() => { navigate('/index') }} 
          buttonText='返回首页'
          style={{ width: '120px', marginTop: '30px' }}
        />
      </div>
    </div>
  )
}
