import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Space } from 'antd'
import MyIcon from '../../components/general/icon'

const Notice = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const callAllRead = () => { }
  const callNoticeList = () => { }

  return (
    <>
      <Dropdown 
        open={dropdownVisible} 
        onOpenChange={(open) => setDropdownVisible(open)} 
        trigger={['click']} 
        overlayStyle={{ width: '460px'}} 
        dropdownRender={() => (
          <div className='main-notice-box'>
            <div className='main-notice-head'>
              <div className='main-notice-title'>通知</div>
              <div className='main-notice-mark' onClick={callAllRead}>全部已读</div>
            </div>

            <div className='main-notice-main'>
              <div className='main-notice-empty'>
                <img src="/images/wuxiaoxi.png" alt="空数据" />
                <span>近30天内没有任何通知</span>
              </div>
            </div>
          </div>
      )}>
        <Link onClick={(e) => e.preventDefault()}>
          <Space onClick={callNoticeList}>
            <div className='main-notice-icon'>
              <MyIcon name='tongzhi' />
            </div>
          </Space>
        </Link>
      </Dropdown>
    </>
  )
}

export default Notice
