import React, { useState } from 'react'
import { Button } from 'antd'

const MyButton = ({
  type="primary",
  onClick,
  buttonText,
  style,
  ...rest
}) => {

  const [loadings, setLoadings] = useState([])
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings]
      newLoadings[index] = true
      return newLoadings
    })
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings]
        newLoadings[index] = false
        return newLoadings
      })
    }, 600)
  }

  return (
    <div>
      <Button 
        type={type}
        loading={loadings[0]} 
        onClick={() => {
          enterLoading(0)
          onClick()
        }}
        style={{ height: '40px', fontWeight: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', ...style }}
        {...rest}
      >
        {buttonText}
      </Button>
    </div>
  )
}

export default MyButton
