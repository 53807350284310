import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Pagination } from 'antd'
import JobResumeRibbon from './ribbon'
import ExportJobResume from './export'

export default function JobResume() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [currentPage, setCurrentPage] = useState(1)
  const [resumeData, setResumeData] = useState({})
  const [isExportVisible, setIsExportVisible] = useState(false)

  const initialConditions = {
    keywords: '',
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getResumeData = () => {
    axios.post('/p/jobs/resume/list/get', { 
      merchant_oid: merchantOid,
      keywords: filterValue.keywords,
      page: currentPage, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setResumeData({
            'total_page': response.data.total_page,
            'current_page': response.data.current_page,
            'total': response.data.total,
            'resumes': response.data.resumes
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getResumeData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, currentPage, filterValue])

  const PreviewOnlineResume = (oid) => {
    window.open(`/resume/preview/${oid}`, '_blank');
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">简历管理</div>
          <JobResumeRibbon 
            isExportVisible={() => { setIsExportVisible(true) }}
            filterValue={filterValue} 
            setFilterValue={setFilterValue}
          />
        </div>

        <div className='public-main-style2'>
          <table className='public-main-style2-item'>
            <thead>
              <tr className='public-main-style2-thead'>
                <th>
                  <div className='public-main-style2-name'>姓名</div>
                </th>
                <th>性别</th>
                <th>毕业院校</th>
                <th>所学专业</th>
                <th>手机号</th>
                <th>在线简历</th>
                <th>附件简历</th>
              </tr>
            </thead>

            <tbody>
              {resumeData.resumes && (resumeData.resumes.length > 0 ? (resumeData.resumes.map((item) => (
                <tr className='public-main-style2-tbody' key={item.oid} style={{cursor: 'default'}}>
                  <td>
                    <div className='public-main-style2-name'>{item.name}</div>
                  </td>
                  <td>{item.gender || '未填写'}</td>
                  <td>{item.graduating_institution || '未填写'}</td>
                  <td>{item.study_major || '未填写'}</td>
                  <td>{item.phone || '未填写'}</td>
                  <td>
                    <div className='public-main-style2-viewBox'>
                      {item.phone ? <div className='public-main-style2-view' onClick={() => { PreviewOnlineResume(item.oid) }}>查看</div> : '未填写'}
                    </div>
                  </td>
                  <td>
                    <div className='public-main-style2-viewBox'>
                      {item.annex_resume ? 
                        <div className='public-main-style2-view' onClick={(event) => {event.stopPropagation(); window.open(item.annex_resume, '_blank')}}>查看</div> : '未上传'}
                    </div>
                  </td>
                </tr>
              ))) : (
                <tr className='public-main-style2-tbodyEmpty'>
                  <td colSpan="5"><span>暂无数据</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='pagination'>
          <Pagination 
            current={currentPage}
            total={resumeData.total}
            pageSize={20}
            onChange={(page) => setCurrentPage(page)}
            showTotal={(total) => `共${total}条`}
            showSizeChanger={false}
            hideOnSinglePage={true}
            showQuickJumper={true}
          />
        </div>
      </div>

      {isExportVisible && <ExportJobResume
        filterValue={filterValue}
        isOpen={isExportVisible}
        isCancel={() => { setIsExportVisible(false) }}
      />}
    </div>
  )
}
