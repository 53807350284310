import React, { useState, useEffect } from 'react'
import { Input } from 'antd'
import MyIcon from '../general/icon'

const BasicFilter = ({
  addName,
  isAddVisible,
  filterValue,
  setFilterValue
}) => {
  const [keywordsValue, setKeywordsValue] = useState('')
  const [searchBoxVisible, setSearchBoxVisible] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFilterValue({ ...filterValue, keywords: keywordsValue })
    }, 300)
    return () => clearTimeout(timeoutId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordsValue])
  
  const clickSortFilter = () => {
    if (filterValue.sort_filter === 1) {
      setFilterValue({ ...filterValue, sort_filter: 0 })
    } else {
      setFilterValue({ ...filterValue, sort_filter: 1 })
    }
  }

  const handleBlur = () => {
    if (!keywordsValue) {
      setSearchBoxVisible(false)
      setFilterValue({ ...filterValue, keywords: '' })
    }
  }

  return (
    <div className='filter-container'>
      <div className='filter-filterContainer'>
        <div className='filter-addButton' onClick={() => { isAddVisible() }}>添加{addName}</div>

        {!searchBoxVisible 
        ? <div className='filter-condition' onClick={() => {setSearchBoxVisible(true)}}>
            <span><MyIcon name='sousuo'/></span>
            <p>搜索</p>
          </div>
        : <Input
            style={{width: '260px'}}
            prefix={<MyIcon name='sousuo'/>}
            maxLength={64}
            autoFocus
            placeholder='输入关键字'
            value={keywordsValue}
            onChange={(e) => setKeywordsValue(e.target.value)}
            onBlur={handleBlur}
            allowClear
          />
        }

        <div className='filter-condition' onClick={() => {clickSortFilter()}}>
          <span>{filterValue.sort_filter === 1 ? <MyIcon name='zhengxu'/> : <MyIcon name='daoxu'/>}</span>
          <p>排序</p>
        </div>
      </div>
    </div>
  )
}

export default BasicFilter
