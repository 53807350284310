import React from 'react'
import { Input } from 'antd'
import MyIcon from '../general/icon'

const MyTextArea = ({
  id,
  required = false,
  label = '',
  placeholder = '',
  value,
  maxLength,
  minRows = 2,
  maxRows = 6,
  onChange,
  errorMsg,
  styles,
  autoComplete='off',
  ...rest
}) => {
  const { TextArea } = Input

  return (
    <div className="form-box">
      <label htmlFor={id}>
        {label} 
        {required && <span style={{ color: '#ae2a19' }}> *</span>}
      </label>

      <TextArea
        id={id}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        onChange={(e) => onChange(prevValues => ({ ...prevValues, [id]: e.target.value }))}
        autoSize={{ minRows: minRows, maxRows: maxRows }}
        style={{ width: '100%', ...styles }}
        {...rest}
      />

      {errorMsg && (
        <div className="form-errormsg">
          <span><MyIcon name='jinggao' /></span>
          <p>{errorMsg}</p>
        </div>
      )}
    </div>
  )
}

export default MyTextArea
