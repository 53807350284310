import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Image } from 'antd'
import BasicFilter from '../../../components/filter/basicFilter'
import MyIcon from '../../../components/general/icon'
import AddSlide from './add'
import EditSlide from './edit'
import DeleteSlide from './delete'

export default function MiniappSlide() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [propsData, setPropsData] = useState({})
  const [slideList, setSlideList] = useState([])
  const [isAddSlideVisible, setIsAddSlideVisible] = useState(false)
  const [isEditSlideVisible, setIsEditSlideVisible] = useState(false)
  const [isDeleteSlideVisible, setIsDeleteSlideVisible] = useState(false)

  const initialConditions = {
    keywords: '',
    sort_filter: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getSlideList = () => {
    axios.post('/p/deco/slide/list/get', { 
      merchant_oid: merchantOid,
      source: 1
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setSlideList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getSlideList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue])

  const editSlide = (oid) => {
    setIsEditSlideVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteSlide = (oid, title) => {
    setIsDeleteSlideVisible(true)
    setPropsData({ oid: oid, title: title})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">首页轮播图</div>
          <BasicFilter
            addName='轮播图'
            filterValue={filterValue} 
            setFilterValue={setFilterValue} 
            isAddVisible={() => { setIsAddSlideVisible(true) }}  
          />
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th style={{width: '300px'}}>
                    <div className='public-main-style2-title'>轮播图</div>
                  </th>
                  <th>打开模式</th>
                  <th>排序</th>
                  <th style={{width: '50px'}}></th>
                </tr>
              </thead>

              <tbody>
                {slideList && (slideList.length > 0 ? (slideList.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editSlide(item.oid) }}>
                    <td style={{width: '300px'}}>
                      <div className='public-main-style2-tuwen'>
                        <div style={{margin: '10px 15px 10px 0'}} onClick={(event) => {event.stopPropagation()}}>
                          <Image src={item.thumbnail} width={100} height={80} />
                        </div>
                        <span>{item.title}</span>
                      </div>
                    </td>
                    <td>{item.mode}</td>
                    <td>{item.sort}</td>
                    <td style={{width: '50px'}}>
                      <div className='public-main-style2-operation-box'>
                        <div className='public-main-style2-operation' 
                          onClick={(event) => {event.stopPropagation(); deleteSlide(item.oid, item.title)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan='4'><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isAddSlideVisible && <AddSlide
        getSlideList={getSlideList} 
        isOpen={isAddSlideVisible} 
        isCancel={() => { setIsAddSlideVisible(false) }} 
      />}

      {isEditSlideVisible && <EditSlide
        data={propsData}
        getSlideList={getSlideList} 
        isOpen={isEditSlideVisible} 
        isCancel={() => { setIsEditSlideVisible(false) }} 
      />}

      {isDeleteSlideVisible && <DeleteSlide
        data={propsData}
        getSlideList={getSlideList} 
        isOpen={isDeleteSlideVisible} 
        isCancel={() => { setIsDeleteSlideVisible(false) }} 
      />}
    </div>
  )
}
