import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, Image } from 'antd'

const FacebodyStudentDetail = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [studentDetail, setStudentDetail] = useState({})

  useEffect(() => {
    axios.post('/p/facebody/student/detail/get', {
      merchant_oid: merchantOid,
      student_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setStudentDetail(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleCancel = () => {
    props.isCancel()
  }

  const getColor = () => {
    if (studentDetail.verification_status.key === 2) return 'green'
    if (studentDetail.verification_status.key === 3) return 'red'
    return 'black'
  }

  return (
    <Modal 
      title="人像核验学生详情"
      destroyOnClose={true}
      width='700px'
      open={props.isOpen}
      onCancel={handleCancel}
      footer={false}>

      <div className='public-datail-container'>
        <div className='public-datail-item'>
          <div className='public-datail-label'>照片</div>
          <div className='public-datail-photo'>
            <Image src={studentDetail.photo} width={80}/>
          </div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>所属分类</div>
          <div className='public-datail-content'>{studentDetail.facebody_category}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>姓名</div>
          <div className='public-datail-content'>{studentDetail.name}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>性别</div>
          <div className='public-datail-content'>{studentDetail.gender}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>身份证号</div>
          <div className='public-datail-content'>{studentDetail.id_number}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>考生号</div>
          <div className='public-datail-content'>{studentDetail.ex_number}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>手机号</div>
          <div className='public-datail-content'>{studentDetail.phone}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>民族</div>
          <div className='public-datail-content'>{studentDetail.ethnicity}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>政治面貌</div>
          <div className='public-datail-content'>{studentDetail.affiliation}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>出生日期</div>
          <div className='public-datail-content'>{studentDetail.date_birth}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>学院</div>
          <div className='public-datail-content'>{studentDetail.college}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>专业</div>
          <div className='public-datail-content'>{studentDetail.major}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>备注</div>
          <div className='public-datail-content'>{studentDetail.remark}</div>
        </div>

        {studentDetail.verification_status && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>核验状态</div>
            <div style={{ color: getColor() }} className='public-datail-content'>{studentDetail.verification_status.value}</div>
          </div>
        }
        {studentDetail.verification_time && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>最后核验时间</div>
            <div className='public-datail-content'>{studentDetail.verification_time}</div>
          </div>
        }
        {studentDetail.photo_sim && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>照片比对相似度</div>
            <div className='public-datail-content'>{studentDetail.photo_sim}（取值范围0.00-100.00）</div>
          </div>
        }
        {(studentDetail.library_sim && studentDetail.library_sim !== '') && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>公安库比对相似度</div>
            <div className='public-datail-content'>{studentDetail.library_sim}（取值范围0.00-100.00）</div>
          </div>
        }
        {studentDetail.result_description && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>比对结果描述</div>
            <div className='public-datail-content'>{studentDetail.result_description}</div>
          </div>
        }
        {studentDetail.best_frame && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>采集照片</div>
            <div className='public-datail-photo'>
              <Image src={studentDetail.best_frame} width={80}/>
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}
export default FacebodyStudentDetail
