import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyDatePicker from '../../../components/forms/datePicker'

const EditEffectiveDate = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({
    effective_date: props.data.info.effective_date,
  })

  const handleOk = () => {
    axios.post('/p/retention_admission/config/edit', { 
      merchant_oid: merchantOid, 
      effective_date: formValue.effective_date
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getFreshmenConfig()
          message.success('修改成功！')
        } else {
          message.error(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    props.isCancel()
  }

  return (
    <Modal 
      title={'生效日期'}
      destroyOnClose={true}
      width='410px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyDatePicker
        id="effective_date"
        label='选择生效日期'
        placeholder='请选择'
        value={formValue.effective_date}
        onChange={setFormValue}
        styles={{width: '360px'}}
        format='YYYY-MM-DD'
      />
    </Modal>
  )
}
export default EditEffectiveDate
