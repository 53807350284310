import React from 'react'
import { Modal } from 'antd'

const ImportPrompt = (props) => {
  const { count, msgs } = props.data

  return (
    <Modal 
      title="数据导入提示"
      open={props.isOpen}
      onCancel={props.isCancel}
      footer={null}>

      <h3>共有{count}条重复数据</h3>
      {msgs && msgs.map(item => (
        <span>{item} | </span>
      ))}
      {Number(count) > 50 && <p>仅展示前50条重复数据信息</p>}
    </Modal>
  )
}

export default ImportPrompt
