import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Image } from 'antd'
import ContentFilter from '../../../components/filter/contentFilter'
import AddPicture from './add'
import EditPicture from './edit'
import DeletePicture from './delete'
import MyIcon from '../../../components/general/icon'

export default function Picture() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [pictureList, setPictureList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [isNextPage, setIsNextPage] = useState(0)
  const [isAddPictureVisible, setIsAddPictureVisible] = useState(false)
  const [isEditPictureVisible, setIsEditPictureVisible] = useState(false)
  const [isDeletePictureVisible, setIsDeletePictureVisible] = useState(false)

  const initialConditions = {
    column_oid: '',
    keywords: '',
    sort_filter: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getPictureList = () => {
    setCurrentPage(1)
    setIsNextPage(0)
    axios.post('/p/content/picture/list/get', { 
      merchant_oid: merchantOid,
      column_oid: filterValue.column_oid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
      page: 1, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setPictureList(response.data.pictures)
          if (response.data.total_page > 1) {
            setIsNextPage(1)
          }
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getPictureList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue])

  const loadNextPage = () => {
    setCurrentPage(page => page + 1)
    axios.post('/p/content/picture/list/get', { 
      merchant_oid: merchantOid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
      page: currentPage + 1, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          const newData = response.data.pictures
          if (response.data.total_page <= currentPage + 1) {
            setIsNextPage(2)
          }
          setPictureList(prevData => {
            const newDataIds = newData.map(item => item.oid)
            const filteredData = prevData.filter(item => !newDataIds.includes(item.oid))
            return [...filteredData, ...newData]
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const editPicture = (oid) => {
    setIsEditPictureVisible(true)
    setPropsData({ oid: oid })
  }

  const deletePicture = (oid, title) => {
    setIsDeletePictureVisible(true)
    setPropsData({ oid: oid, title: title})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">图片列表</div>
          <ContentFilter
            addName='图片'
            category={2}
            filterValue={filterValue} 
            setFilterValue={setFilterValue} 
            isAddVisible={() => { setIsAddPictureVisible(true) }}  
          />
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th style={{width: '300px'}}>
                    <div className='public-main-style2-title'>图片</div>
                  </th>
                  <th>所属栏目</th>
                  <th>排序</th>
                  <th style={{width: '50px'}}></th>
                </tr>
              </thead>

              <tbody>
                {pictureList && (pictureList.length > 0 ? (pictureList.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editPicture(item.oid) }}>
                    <td style={{width: '300px'}}>
                      <div className='public-main-style2-tuwen'>
                        <div style={{margin: '10px 15px 10px 0'}} onClick={(event) => {event.stopPropagation()}}>
                          <Image src={item.filename} width={100} height={80} />
                        </div>
                        <span>{item.title}</span>
                      </div>
                    </td>
                    <td>{item.column_name}</td>
                    <td>{item.sort}</td>
                    <td style={{width: '50px'}}>
                      <div className='public-main-style2-operation-box'>
                        <div className='public-main-style2-operation' 
                          onClick={(event) => {event.stopPropagation(); deletePicture(item.oid, item.title)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan='4'><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>

              <tfoot>
                {isNextPage === 1 ? (
                  <tr className='public-main-style2-tfootNext'>
                    <td colSpan='4'>
                      <div className='public-main-style2-nextPage' onClick={loadNextPage}>下一页</div>
                    </td>
                  </tr>
                ) : ( isNextPage === 2 && 
                  <tr className='public-main-style2-tfootOver'>
                    <td colSpan='4'>已加载全部</td>
                  </tr>
                )}
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      {isAddPictureVisible && <AddPicture
        getPictureList={getPictureList} 
        isOpen={isAddPictureVisible} 
        isCancel={() => { setIsAddPictureVisible(false) }} 
      />}

      {isEditPictureVisible && <EditPicture
        data={propsData}
        getPictureList={getPictureList} 
        isOpen={isEditPictureVisible} 
        isCancel={() => { setIsEditPictureVisible(false) }} 
      />}

      {isDeletePictureVisible && <DeletePicture
        data={propsData}
        getPictureList={getPictureList} 
        isOpen={isDeletePictureVisible} 
        isCancel={() => { setIsDeletePictureVisible(false) }} 
      />}
    </div>
  )
}
