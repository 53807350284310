import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, Spin, Upload, message } from 'antd'
import MyIcon from '../../../components/general/icon'
import { apiBaseURL } from '../../../request'

const ImportNoticeTemplate = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [noticeTemplateAddress, setNoticeTemplateAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    axios.post('/p/admission_notice/template/get', {
      merchant_oid: merchantOid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setNoticeTemplateAddress(response.data.notice_template)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, isLoading])

  const imageUpload = {
    name: 'doc',
    action: `${apiBaseURL}/common/doc/upload`,
    headers: {
      authorization: localStorage.getItem('token'),
    },
    onChange(info) {
      if (info.file.status === 'done') {
        handleImport(info.file.response.data.doc_url)
      }
    },
  }

  const handleImport = (filename) => {
    setIsLoading(true)
    axios.post('/p/admission_notice/template/import', { 
      merchant_oid: merchantOid, 
      notice_template: filename
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('录取通知书模版导入成功！')
          props.checkTemplate()
          setIsLoading(false)
        } else {
          message.error(response.msg)
          setIsLoading(false)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const previewNotice = () => {
    if (!noticeTemplateAddress) {
      message.error('无法预览，因为缺少录取通知书模版！')
      return false
    }
    window.open(noticeTemplateAddress, '_blank')
  }

  return (
    <Modal
      width={500}
      title="录取通知书模版"
      open={props.isOpen}
      onCancel={props.isCancel}
      maskClosable={false}
      footer=''
    >
      <Spin spinning={isLoading}>
        <div className='public-template-container'>
          <div className='public-template-icon'>
            <MyIcon name='luqutongzhishu2' />
          </div>

          <div className='public-template-title'>录取通知书模版</div>
          <div className='public-template-despcription'>支持docx格式，模版中的动态数据使用双花括号包裹，如：{'\u007B\u007B'}姓名{'\u007D\u007D'}</div>

          <Upload {...imageUpload} showUploadList={false}>
            <div className='public-template-button1'>
              <p>导入模版</p>
            </div>
          </Upload>

          <div className='public-template-button2' onClick={ previewNotice }>
            <p>预览模版</p>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default ImportNoticeTemplate
