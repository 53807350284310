import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Pagination, message, Tooltip } from 'antd'
import MyIcon from '../../../components/general/icon'
import NoticeRibbon from './ribbon'
import NoticeBatch from './batch'
import AddAdmissionStudent from './add'
import EditAdmissionStudent from './edit'
import DeleteAdmissionStudent from './delete'
import ImportAdmissionStudent from './import'
import PrintAdmissionNotice from './print'
import ImportNoticeTemplate from './template'
import FilterAdmissionStudent from './filter'

export default function AdmissionNotice() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [currentPage, setCurrentPage] = useState(1)
  const [studentData, setStudentData] = useState({})
  const [checkTemplate, setCheckTemplate] = useState(true)
  const [selectedOid, setSelectedOid] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isNoticeBatchVisible, setIsNoticeBatchVisible] = useState(false)
  const [isAddVisible, setIsAddVisible] = useState(false)
  const [isEditVisible, setIsEditVisible] = useState(false)
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [isImportVisible, setIsImportVisible] = useState(false)
  const [isPrintVisible, setIsPrintVisible] = useState(false)
  const [isTemplateVisible, setIsTemplateVisible] = useState(false)

  const initialConditions = {
    category: '',
    college: '',
    major: '',
    duration: '',
    keywords: '',
    is_print: 2,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getStudentData = () => {
    axios.post('/p/admission_notice/student/list/get', { 
      merchant_oid: merchantOid,
      category: filterValue.category,
      college: filterValue.college,
      major: filterValue.major,
      duration: filterValue.duration,
      keywords: filterValue.keywords,
      is_print: filterValue.is_print,
      page: currentPage, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setStudentData({
            'total_page': response.data.total_page,
            'current_page': response.data.current_page,
            'total': response.data.total,
            'students': response.data.students
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getStudentData()
    axios.post('/p/admission_notice/template/get', { 
      merchant_oid: merchantOid,
    })
      .then(function (response) {
        if (response.errcode === 1) {
          setCheckTemplate(false)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue, currentPage])

  const editStudent = (oid) => {
    setIsEditVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteStudent = (oid, name) => {
    setIsDeleteVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  const printStudent = (oid) => {
    if (!checkTemplate) {
      message.error('无法生成和打印，因为缺少录取通知书模版！')
      return false
    }
    setIsPrintVisible(true)
    setPropsData({ oid: [oid] })
  }

  const printStudents = (selectedOid) => {
    if (!checkTemplate) {
      message.error('无法生成和打印，因为缺少录取通知书模版！')
      return false
    }
    if (selectedOid.length !== 0) {
      setIsPrintVisible(true)
      setPropsData({ oid: selectedOid })
    }
  }

  const clickCheckBox = (oid) => {
    if (selectedOid.includes(oid)) {
      setSelectedOid(selectedOid.filter(id => id !== oid));
    } else {
      setSelectedOid([...selectedOid, oid]);
    }
  }

  const clickAllCheckBox = () => {
    let studentOid = studentData.students.map(student => student.oid)
    if (selectedOid.length === studentOid.length) {
      setSelectedOid([])
    } else {
      setSelectedOid([])
      setSelectedOid(studentOid)
    }
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">录取通知书</div>
          {!isNoticeBatchVisible ?
            <NoticeRibbon 
              filterValue={filterValue} 
              setFilterValue={setFilterValue}
              isBatchOperationVisible={() => { setIsNoticeBatchVisible(true) }}
              isAddVisible={() => { setIsAddVisible(true) }}
              isImportVisible={() => { setIsImportVisible(true) }}
              isTemplateVisible={() => { setIsTemplateVisible(true) }}
            /> : 
            <NoticeBatch 
              isPrintNotice={() => { printStudents(selectedOid) }} 
              isCancelVisible={() => { setIsNoticeBatchVisible(false) }}
              selectedNumber={selectedOid.length}
              clearCheckBox={() => { setSelectedOid([]) }}
            />
          }
        </div>

        <FilterAdmissionStudent 
          filterValue={filterValue} 
          setFilterValue={setFilterValue}
          setCurrentPage={setCurrentPage}
        />

        <div className='public-main-style2'>
          <table className='public-main-style2-item'>
            <thead>
              <tr className='public-main-style2-thead'>
                {isNoticeBatchVisible && 
                  <th style={{width: '40px', cursor: 'pointer'}} onClick={clickAllCheckBox}>
                    {selectedOid.length === studentData.students.map(student => student.oid).length
                      ? <MyIcon name='fuxuankuang-xuanzhong' />
                      : <MyIcon name='fuxuankuang-moren' />
                    }
                  </th>
                }
                <th style={{textAlign: 'left', paddingLeft: '20px'}}>姓名</th>
                <th>考生号</th>
                <th>学院</th>
                <th>专业</th>
                <th>学制</th>
                <th>招生类型</th>
                <th>是否打印</th>
                <th style={{width: '50px'}}></th>
              </tr>
            </thead>

            <tbody>
              {studentData.students && (studentData.students.length > 0 ? (studentData.students.map((item) => (
                <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editStudent(item.oid) }}>
                  {isNoticeBatchVisible && 
                    <th style={{width: '40px', cursor: 'pointer'}} onClick={(event) => {event.stopPropagation(); clickCheckBox(item.oid)}}>
                      {selectedOid.includes(item.oid)
                        ? <MyIcon name='fuxuankuang-xuanzhong' />
                        : <MyIcon name='fuxuankuang-moren' />
                      }
                    </th>
                  }
                  <td style={{textAlign: 'left', paddingLeft: '20px'}}>{item.name}</td>
                  <td>{item.number}</td>
                  <td>{item.college ? item.college : '-'}</td>
                  <td>{item.major ? item.major : '-'}</td>
                  <td>{item.duration ? item.duration : '-'}</td>
                  <td>{item.category ? item.category : '-'}</td>
                  <td>{item.is_print ? item.is_print : '-'}</td>
                  <td style={{width: '100px'}}>
                    <div className='public-main-style2-operation-box'>
                      <Tooltip placement="bottom" color='#1A1B1C' title="打印">
                        <div className='public-main-style2-operation' onClick={(event) => {event.stopPropagation(); printStudent(item.oid)}}>
                          <MyIcon name='dayin' />
                        </div>
                      </Tooltip>
                      <Tooltip placement="bottom" color='#1A1B1C' title="删除">
                        <div className='public-main-style2-operation' onClick={(event) => {event.stopPropagation(); deleteStudent(item.oid, item.name)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))) : (
                <tr className='public-main-style2-tbodyEmpty'>
                  <td colSpan="8"><span>暂无数据</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {!isNoticeBatchVisible ?
          <div className='pagination'>
            <Pagination 
              current={currentPage}
              total={studentData.total}
              pageSize={20}
              onChange={(page) => setCurrentPage(page)}
              showTotal={(total) => `共${total}条`}
              showSizeChanger={false}
              hideOnSinglePage={true}
              showQuickJumper={true}
            />
          </div> : <div style={{height: '30px'}}></div>
        }
      </div>

      {isAddVisible && <AddAdmissionStudent
        getStudentData={getStudentData}
        isOpen={isAddVisible}
        isCancel={() => { setIsAddVisible(false) }}
      />}

      {isEditVisible && <EditAdmissionStudent
        data={propsData}
        getStudentData={getStudentData}
        isOpen={isEditVisible}
        isCancel={() => { setIsEditVisible(false) }}
      />}

      {isDeleteVisible && <DeleteAdmissionStudent
        data={propsData}
        getStudentData={getStudentData}
        isOpen={isDeleteVisible}
        isCancel={() => { setIsDeleteVisible(false) }}
      />}

      {isImportVisible && <ImportAdmissionStudent
        getStudentData={getStudentData}
        filterValue={filterValue}
        isOpen={isImportVisible}
        isCancel={() => { setIsImportVisible(false) }}
      />}

      {isPrintVisible && <PrintAdmissionNotice
        data={propsData}
        getStudentData={getStudentData}
        isOpen={isPrintVisible}
        isCancel={() => { setIsPrintVisible(false) }}
      />}

      {isTemplateVisible && <ImportNoticeTemplate
        checkTemplate={() => { setCheckTemplate(true) }}
        isOpen={isTemplateVisible}
        isCancel={() => { setIsTemplateVisible(false) }}
      />}
    </div>
  )
}
