import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyRichText from '../../../components/forms/richText'
import MyCollegeSelect from '../../../components/select/collegeSelect'

const EditMajor = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/info/major/detail/get', {
      merchant_oid: merchantOid,
      major_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({nameEmpty: '专业名称不能为空'})
      return false
    }
    if (!formValue.college_oid) {
      setErrorMsg({collegeEmpty: '所属院系不能为空'})
      return false
    }

    axios.post('/p/info/major/edit', { 
      merchant_oid: merchantOid,
      major_oid: props.data.oid,
      college_oid: formValue.college_oid,
      name: formValue.name,
      sort: Number(formValue.sort),
      introduction: formValue.introduction
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getMajorList()
          message.success('专业修改成功！')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title='编辑专业'
      destroyOnClose={true}
      width='760px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyInput
        id="name"
        required
        autoFocus
        maxLength={64}
        label='名称'
        placeholder='请输入专业的名称'
        value={formValue.name}
        onChange={setFormValue}
        errorMsg={errorMsg.nameEmpty}
      />

      <MyRichText
        id='introduction'
        label='专业介绍'
        value={formValue.introduction}
        setValue={setFormValue}
      />

      <MyCollegeSelect
        id='college_oid'
        required
        label='选择院系'
        value={formValue.college_oid}
        onChange={setFormValue}
        errorMsg={errorMsg.collegeEmpty}
      />

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />
    </Modal>
  )
}
export default EditMajor
