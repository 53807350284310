import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Pagination, Spin } from 'antd'
import InfoFilter from '../../../components/filter/infoFilter'
import EmptyPlan from './empty'
import ImportPlan from './import'

export default function Plan() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [planData, setPlanData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [isImportPlanVisible, setIsImportPlanVisible] = useState(false)
  const [isEmptyPlanVisible, setIsEmptyPlanVisible] = useState(false)

  const initialConditions = {
    keywords: '',
    sort_filter: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getPlanList = () => {
    axios.post('/p/info/plan/get', { 
      merchant_oid: merchantOid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
      page: currentPage, 
      per_page: 20
    })
      .then(function (response) {
        setIsLoading(false)
        if (response.errcode === 0) {
          setPlanData({
            'total_page': response.data.total_page,
            'current_page': response.data.current_page,
            'total': response.data.total,
            'headers': response.data.thead,
            'plans': response.data.tbody
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getPlanList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue, currentPage])

  return (
    <div className="public-page">
      <Spin spinning={isLoading}>
        <div className="public-container">
          <div className="public-header">
            <div className="public-title">招生计划</div>
            <InfoFilter 
              filterValue={filterValue} 
              setFilterValue={setFilterValue} 
              isEmptyVisible={() => { setIsEmptyPlanVisible(true) }}
              isImportVisible={() => { setIsImportPlanVisible(true) }} 
            />
          </div>

          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  {planData.headers && planData.headers.map((header, index) => (
                    <th key={header} style={index === 0 ? {textAlign: 'left', paddingLeft: '20px', paddingRight: '50px'} : {paddingRight: '50px'}}>
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {planData.plans && (planData.plans.length > 0 ? (planData.plans.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid}>
                    <td style={{textAlign: 'left', paddingLeft: '20px', paddingRight: '50px'}}>{item.year}</td>
                    <td style={{paddingRight: '50px'}}>{item.region}</td>
                    <td style={{paddingRight: '50px'}}>{item.section}</td>
                    <td style={{paddingRight: '50px'}}>{item.batch}</td>
                    <td style={{paddingRight: '50px'}}>{item.campus}</td>
                    <td style={{paddingRight: '50px'}}>{item.major}</td>
                    <td style={{paddingRight: '50px'}}>{item.numbers}</td>
                    {planData.headers.map((header, index) => (
                      index >= 7 ? (
                        <td key={index} style={{ paddingRight: '50px' }}>
                          {item.others.hasOwnProperty(header) && item.others[header] != null ? item.others[header] : '-'}
                        </td>
                      ) : null
                    ))}
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan={planData.headers.length}><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='pagination'>
            <Pagination 
              current={currentPage}
              total={planData.total}
              pageSize={20}
              onChange={(page) => setCurrentPage(page)}
              showTotal={(total) => `共${total}条`}
              showSizeChanger={false}
              hideOnSinglePage={true}
              showQuickJumper={true}
            />
          </div>
        </div>
      </Spin>
      {isEmptyPlanVisible && <EmptyPlan
        getPlanList={getPlanList}
        isOpen={isEmptyPlanVisible}
        isCancel={() => { setIsEmptyPlanVisible(false) }}
      />}

      {isImportPlanVisible && <ImportPlan
        getPlanList={getPlanList}
        isOpen={isImportPlanVisible}
        isCancel={() => { setIsImportPlanVisible(false) }}
      />}
    </div>
  )
}
