import React from 'react';
import axios from 'axios';
import MyIcon from '../general/icon';
import { message } from 'antd';

const MyDocUpload = ({
  id,
  label = '',
  required = false,
  value,
  setValue,
  setIsLoading,
  errorMsg
}) => {

  const handleDoc = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx,'
    input.onchange = handleFileSelect
    input.click()
  }

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 8 * 1024 * 1024) {
        message.warning('文件大小不能超过8MB')
        return
      }
    
      try {
        const docUrl = await uploadDoc(file)
        setValue(prevFormValue => ({ ...prevFormValue, [id]: docUrl }))
      } catch (error) {
        console.error('Error uploading doc:', error)
      }
    }
  }

  const uploadDoc = (file) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      axios.post('/common/oss_signature/generate', { suffix: file.name.split('.').pop() })
        .then(function (response) {
          if (response.errcode === 0) {
            const formData = new FormData();
            formData.append('name',response.data.filename)
            formData.append('policy', response.data.policy)
            formData.append('OSSAccessKeyId', response.data.accessid)
            formData.append('success_action_status', '200')
            formData.append('signature', response.data.signature)
            formData.append('key', response.data.dir + response.data.filename)
            formData.append('file', file)

            fetch(response.data.host, { method: 'POST', body: formData })
              .then(() => {
                resolve(response.data.full_url)
                setIsLoading(false)
              }
            )
          } else {
            reject(new Error('API request failed'))
          }
        })
        .catch(function (error) {
          console.error('API request failed, error:', error)
          reject(error)
        })
    })
  }

  const handleDelete = () => {
    setValue(prevFormValue => ({ ...prevFormValue, [id]: '' }));
  }

  return (
    <div className="form-box">
      <label htmlFor={id}>
        {label}
        {required && <span style={{ color: '#ae2a19' }}> *</span>}
      </label>

      <div className="form-upload-docBox">
        <div className='form-upload-doc' onClick={handleDoc}>
          <MyIcon name="shangchuan" />
          <span>选择本地文件</span>
        </div>

        {value &&
          <div className='form-upload-doc-display'>
            <MyIcon name="lianjie" />
            <span>{value}</span>
            <div className='form-upload-doc-del' onClick={handleDelete}>
              <MyIcon name="huishouzhan" />
            </div>
          </div>
        }
      </div>

      {errorMsg && (
        <div className="form-errormsg">
          <span>
            <MyIcon name="jinggao" />
          </span>
          <p>{errorMsg}</p>
        </div>
      )}
    </div>
  )
}

export default MyDocUpload;
