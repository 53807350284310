import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message, Button } from 'antd'
import MyInput from '../../../components/forms/input'
import MyColumnSelect from '../../../components/select/columnSelect'
import MyImageUpload from '../../../components/forms/imageUpload'

const AddPicture = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  const handleSubmit = () => {
    setErrorMsg({})
    if (!formValue.filename) {
      setErrorMsg({filenameEmpty: '图片不能为空'})
      return false
    }
    if (!formValue.column_oid) {
      setErrorMsg({columnEmpty: '所属栏目不能为空'})
      return false
    }

    axios.post('/p/content/picture/add', {
      merchant_oid: merchantOid,
      title: formValue.title,
      column_oid: formValue.column_oid,
      filename: formValue.filename,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('新图片添加成功！')
          props.getPictureList()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleOk = () => {
    const isOk = handleSubmit()
    if (isOk !== false) {handleCancel()}
  }

  const handleNext = () => {
    handleSubmit()
    setFormValue({column_oid: formValue.column_oid})
  }

  const handleCancel = () => {
    setErrorMsg({})
    setFormValue({})
    props.isCancel()
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="添加图片"
        width='460px'
        open={props.isOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key='cancel' onClick={handleCancel}>取消</Button>,
          <Button key='submit' onClick={handleNext}>提交并新建下一条</Button>,
          <Button key='isOk' type="primary" onClick={handleOk}>创建</Button>
        ]}>

        <MyImageUpload 
          id="filename"
          required
          label='图片'
          value={formValue.filename}
          setValue={setFormValue}
          errorMsg={errorMsg.filenameEmpty}
        />

        <MyColumnSelect
          id='column_oid'
          required
          label='选择栏目'
          category={2}
          value={formValue.column_oid}
          onChange={setFormValue}
          errorMsg={errorMsg.columnEmpty}
        />

        <MyInput
          id="title"
          maxLength={64}
          label='标题'
          placeholder='请输入图片标题'
          value={formValue.title}
          onChange={setFormValue}
        />

        <MyInput
          type='number'
          id="sort"
          label='排序'
          value={formValue.sort}
          onChange={setFormValue}
          styles={{ width: '60px' }}
          min={0}
          max={1000}
        />
      </Modal>
    </>
  )
}

export default AddPicture
