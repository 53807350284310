import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Pagination } from 'antd'
import JobApplicationRibbon from './ribbon'
import ExportJobApplication from './export'

export default function JobApplication() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [currentPage, setCurrentPage] = useState(1)
  const [applicationData, setApplicationData] = useState({})
  const [isExportVisible, setIsExportVisible] = useState(false)

  const initialConditions = {
    unit_oid: '',
    job_oid: ''
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getApplicationData = () => {
    axios.post('/p/jobs/application/list/get', { 
      merchant_oid: merchantOid,
      unit_oid: filterValue.unit_oid,
      job_oid: filterValue.job_oid,
      page: currentPage, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setApplicationData({
            'total_page': response.data.total_page,
            'current_page': response.data.current_page,
            'total': response.data.total,
            'job_applications': response.data.job_applications
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getApplicationData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue, currentPage])

  const PreviewResume = (item) => {
    if (item.resume_type_id === 1) {
      window.open(`/resume/preview/${item.resume_oid}`, '_blank')
    } else {
      window.open(item.annex_resume, '_blank')
    }
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">投递记录</div>
          <JobApplicationRibbon 
            isExportVisible={() => { setIsExportVisible(true) }}
            filterValue={filterValue} 
            setFilterValue={setFilterValue}
          />
        </div>

        <div className='public-main-style2'>
          <table className='public-main-style2-item'>
            <thead>
              <tr className='public-main-style2-thead'>
                <th><div className='public-main-style2-name'>招聘单位</div></th>
                <th><div className='public-main-style2-name2'>投递职位</div></th>
                <th>投递人</th>
                <th>投递简历</th>
                <th>投递时间</th>
              </tr>
            </thead>

            <tbody>
              {applicationData.job_applications && (applicationData.job_applications.length > 0 ? (applicationData.job_applications.map((item) => (
                <tr className='public-main-style2-tbody' key={item.oid} style={{cursor: 'default'}}>
                  <td><div className='public-main-style2-name'>{item.unit}</div></td>
                  <td><div className='public-main-style2-name2'>{item.job}</div></td>
                  <td>{item.user}</td>
                  <td>
                    <div className='public-main-style2-viewBox'>
                      {item.resume_type_name}
                      <div className='public-main-style2-view' onClick={(event) => {event.stopPropagation(); PreviewResume(item)}}>查看</div>
                    </div>
                  </td>
                  <td>{item.create_time}</td>
                </tr>
              ))) : (
                <tr className='public-main-style2-tbodyEmpty'>
                  <td colSpan="5"><span>暂无数据</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='pagination'>
          <Pagination 
            current={currentPage}
            total={applicationData.total}
            pageSize={20}
            onChange={(page) => setCurrentPage(page)}
            showTotal={(total) => `共${total}条`}
            showSizeChanger={false}
            hideOnSinglePage={true}
            showQuickJumper={true}
          />
        </div>
      </div>

      {isExportVisible && <ExportJobApplication
        filterValue={filterValue}
        isOpen={isExportVisible}
        isCancel={() => { setIsExportVisible(false) }}
      />}
    </div>
  )
}
