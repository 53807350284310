import React from 'react'
import MyIcon from '../../../components/general/icon'

const NoticeBatch = ({
  isPrintNotice,
  isCancelVisible,
  selectedNumber,
  clearCheckBox
}) => {

  const handleClear = () => {
    clearCheckBox()
  }

  const handleCancel = () => {
    isCancelVisible()
    clearCheckBox()
  }

  return (
    <div className='batch-container'>
      <div className='batch-box'>
        <div className='batch-func' onClick={() => { isPrintNotice(true) }}>
          <span><MyIcon name='dayin'/></span>
          <p>批量打印</p>
        </div>
        <div className='batch-tip'>
          <p>已选 {selectedNumber}</p>
        </div>
        <div className='batch-button' onClick={ handleClear }>清除</div>
        <div className='batch-button' onClick={ handleCancel }>取消</div>
      </div>
    </div>
  )
}

export default NoticeBatch
