import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message, Button } from 'antd'
import MyInput from '../../../components/forms/input'
import MyImageUpload from '../../../components/forms/imageUpload'
import MyRadio from '../../../components/forms/radio'
import MyTextArea from '../../../components/forms/textArea'

const AddMiniappNavigation = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  const handleSubmit = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({nameEmpty: '标题不能为空'})
      return false
    }
    if (!formValue.icon) {
      setErrorMsg({iconEmpty: '图标不能为空'})
      return false
    }
    let path
    if (formValue.mode === 4) {
      path = formValue.finderUserName
    } else if (formValue.mode === 5) {
      path = formValue.finderUserName + '&' + formValue.feedId
    } else {
      path = formValue.path
    }

    axios.post('/p/deco/navigation/add', {
      merchant_oid: merchantOid,
      source: 1,
      name: formValue.name,
      icon: formValue.icon,
      mode: formValue.mode,
      path: path,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('新金刚位创建成功！')
          props.getNavigationList()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleOk = () => {
    const isOk = handleSubmit()
    if (isOk !== false) {handleCancel()}
  }

  const handleNext = () => {
    handleSubmit()
    setFormValue({})
  }

  const handleCancel = () => {
    setErrorMsg({})
    setFormValue({})
    props.isCancel()
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="创建金刚位"
        width='760px'
        open={props.isOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key='cancel' onClick={handleCancel}>取消</Button>,
          <Button key='submit' onClick={handleNext}>提交并新建下一条</Button>,
          <Button key='isOk' type="primary" onClick={handleOk}>创建</Button>
        ]}>

        <MyImageUpload 
          id="icon"
          required
          label='图标'
          value={formValue.icon}
          setValue={setFormValue}
          errorMsg={errorMsg.iconEmpty}
        />

        <MyInput
          id="name"
          autoFocus
          maxLength={64}
          label='标题'
          placeholder='请输入金刚位的名称'
          value={formValue.name}
          onChange={setFormValue}
          errorMsg={errorMsg.nameEmpty}
        />

        <MyRadio
          id='mode'
          label='跳转页面'
          options={[
            {
              label: '内部二级页面',
              value: 1,
            },
            {
              label: '内部底部导航',
              value: 3,
            },
            {
              label: '网页链接',
              value: 2,
            },
            {
              label: '视频号主页',
              value: 4,
            },
            {
              label: '视频号视频',
              value: 5,
            },
            {
              label: '暂未开放',
              value: 6,
            },
            {
              label: '不跳转',
              value: 0,
            }
          ]}
          defaultValue={0}
          value={formValue.mode}
          onChange={setFormValue}
        />

        {[1, 2, 3].includes(formValue.mode) && <MyTextArea 
          id="path"
          minRows={2}
          placeholder='请输入完整路径'
          value={formValue.path}
          onChange={setFormValue}
        />}

        {[4, 5].includes(formValue.mode) && <MyInput
          id="finderUserName"
          maxLength={128}
          label='视频号id'
          placeholder='以“sph”开头的id，可在视频号助手获取'
          value={formValue.finderUserName}
          onChange={setFormValue}
        />}

        {formValue.mode === 5 && <MyInput
          id="feedId"
          maxLength={128}
          label='视频id'
          placeholder='请输入视频 feedId'
          value={formValue.feedId}
          onChange={setFormValue}
        />}

        <MyInput
          type='number'
          id="sort"
          label='排序'
          value={formValue.sort}
          onChange={setFormValue}
          styles={{ width: '60px' }}
          min={0}
          max={1000}
        />
      </Modal>
    </>
  )
}

export default AddMiniappNavigation
