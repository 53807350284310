import React, { useState } from 'react';
import axios from 'axios';
import { Tooltip } from 'antd';
import MyIcon from '../general/icon';

const MyVideoUpload = ({
  id,
  label = '',
  required = false,
  width = 160,
  height = 90,
  value,
  setValue,
  setIsLoading,
  errorMsg
}) => {
  const [hovered, setHovered] = useState(false)

  const handleVideo = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'video/*'
    input.onchange = handleFileSelect
    input.click()
  }

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const videoUrl = await uploadVideo(file)
        setValue(prevFormValue => ({ ...prevFormValue, [id]: videoUrl }))
      } catch (error) {
        console.error('Error uploading video:', error)
      }
    }
  }

  const uploadVideo = (file) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      axios.post('/common/oss_signature/generate', { suffix: file.type.split('/')[1] })
        .then(function (response) {
          if (response.errcode === 0) {
            const formData = new FormData();
            formData.append('name',response.data.filename)
            formData.append('policy', response.data.policy)
            formData.append('OSSAccessKeyId', response.data.accessid)
            formData.append('success_action_status', '200')
            formData.append('signature', response.data.signature)
            formData.append('key', response.data.dir + response.data.filename)
            formData.append('file', file)

            fetch(response.data.host, { method: 'POST', body: formData })
              .then(() => {
                resolve(response.data.full_url)
                setIsLoading(false)
              }
            )
          } else {
            reject(new Error('API request failed'))
          }
        })
        .catch(function (error) {
          console.error('API request failed, error:', error)
          reject(error)
        })
    })
  }

  const handleDelete = () => {
    setValue(prevFormValue => ({ ...prevFormValue, [id]: '' }));
  }

  return (
    <div className="form-box">
      <label htmlFor={id}>
        {label}
        {required && <span style={{ color: '#ae2a19' }}> *</span>}
      </label>

      <div className="form-upload-box">
        <div
          className="form-upload-img"
          style={{
            width: `${width}px`,
            height: `${height}px`,
            position: 'relative',
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {value ? 
            <video src={value} alt="" style={{ width: `${width}px`, height: `${height}px` }} />
            : 
            <div className="form-upload-video" onClick={handleVideo} style={{ width: `${width}px`, height: `${height}px` }}>
              <MyIcon name="jiahao" />
            </div>
          }
          {hovered && value && (
            <Tooltip title="删除视频">
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleDelete}
              >
                <MyIcon name="huishouzhan" style={{ fontSize: '20px', color: '#fff', cursor: 'pointer' }} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>

      {errorMsg && (
        <div className="form-errormsg">
          <span>
            <MyIcon name="jinggao" />
          </span>
          <p>{errorMsg}</p>
        </div>
      )}
    </div>
  )
}

export default MyVideoUpload;
