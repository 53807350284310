import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyTextArea from '../../../components/forms/textArea'

const EditWelcomeMsg = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({
    welcome_msg: props.data.info.welcome_msg
  })
  const [errorMsg, setErrorMsg] = useState({})

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.welcome_msg) {
      setErrorMsg({welcomeMsgEmpty: '欢迎语不能为空'})
      return false
    }

    axios.post('/p/chatbot/config/edit', { 
      merchant_oid: merchantOid, 
      welcome_msg: formValue.welcome_msg
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getChatbotConfig()
          message.success('欢迎语修改成功！')
        } else {
          message.error(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title={'欢迎语'}
      destroyOnClose={true}
      width='700px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyTextArea
        id="welcome_msg"
        minRows={5}
        placeholder='请输入欢迎语'
        value={formValue.welcome_msg}
        onChange={setFormValue}
        errorMsg={errorMsg.welcomeMsgEmpty}
      />
    </Modal>
  )
}

export default EditWelcomeMsg
