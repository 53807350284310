import React from 'react'
import { Outlet, NavLink } from 'react-router-dom'
import MyIcon from '../../components/general/icon'

export default function MoreNav() {
  const computedClassName = ({ isActive }) => {
    return isActive ?  "secondaryNav-menu secondaryNav-menuActive" : "secondaryNav-menu"
  }

  return (
    <div className="secondaryNav-page">
      <div className="secondaryNav-container">
        <div className="secondaryNav-headline">更多应用</div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">人才招聘</div>
            <NavLink to="job_unit" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='gongsi' /></div>
              <div className="secondaryNav-name">招聘单位</div>
            </NavLink>
            <NavLink to="job_category" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='lanmu' /></div>
              <div className="secondaryNav-name">职位分类</div>
            </NavLink>
            <NavLink to="job_post" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='gangwei' /></div>
              <div className="secondaryNav-name">招聘职位</div>
            </NavLink>
            <NavLink to="job_application" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='toudi' /></div>
              <div className="secondaryNav-name">投递记录</div>
            </NavLink>
            <NavLink to="job_resume" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='jianli' /></div>
              <div className="secondaryNav-name">用户简历</div>
            </NavLink>
          </div>
      </div>
      <Outlet />
    </div>
  )
}
