import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyTextArea from '../../../components/forms/textArea'
import MySwitch from '../../../components/forms/switch'

const EditPausePrompt = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({
    is_pause: props.data.info.is_pause,
    pause_prompt: props.data.info.pause_prompt
  })
  const [errorMsg, setErrorMsg] = useState({})
  console.log(formValue);
  
  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.pause_prompt) {
      setErrorMsg({pausePromptEmpty: '提示信息不能为空'})
      return false
    }

    axios.post('/p/retention_admission/config/pause', { 
      merchant_oid: merchantOid, 
      is_pause: formValue.is_pause,
      pause_prompt: formValue.pause_prompt
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getFreshmenConfig()
          message.success('修改成功！')
        } else {
          message.error(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title={'暂停服务'}
      destroyOnClose={true}
      width='700px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MySwitch
        required
        id='is_pause'
        label='是否暂停'
        checked={formValue.is_pause}
        onChange={setFormValue}
      />

      {formValue.is_pause === 1 &&
        <MyTextArea 
          id="pause_prompt"
          required
          label='请输入暂停原因'
          minRows={5}
          placeholder='请输入'
          value={formValue.pause_prompt}
          onChange={setFormValue}
          errorMsg={errorMsg.pausePromptEmpty}
        />
      }
    </Modal>
  )
}

export default EditPausePrompt
