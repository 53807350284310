import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyColumnSelect from '../../../components/select/columnSelect'
import MyImageUpload from '../../../components/forms/imageUpload'

const EditPicture = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/content/picture/detail/get', {
      merchant_oid: merchantOid,
      picture_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.filename) {
      setErrorMsg({filenameEmpty: '图片不能为空'})
      return false
    }
    if (!formValue.column_oid) {
      setErrorMsg({columnEmpty: '所属栏目不能为空'})
      return false
    }

    axios.post('/p/content/picture/edit', { 
      merchant_oid: merchantOid,
      picture_oid: formValue.oid,
      title: formValue.title,
      column_oid: formValue.column_oid,
      filename: formValue.filename,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getPictureList()
          message.success('图片修改成功！')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title='编辑图片'
      destroyOnClose={true}
      width='460px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyImageUpload 
        id="filename"
        required
        label='图片'
        value={formValue.filename}
        setValue={setFormValue}
        errorMsg={errorMsg.filenameEmpty}
      />

      <MyColumnSelect
        id='column_oid'
        required
        label='选择栏目'
        category={2}
        value={formValue.column_oid}
        onChange={setFormValue}
        errorMsg={errorMsg.columnEmpty}
      />

      <MyInput
        id="title"
        maxLength={64}
        label='标题'
        placeholder='请输入图片标题'
        value={formValue.title}
        onChange={setFormValue}
      />

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />
    </Modal>
  )
}

export default EditPicture
