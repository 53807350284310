import React, { useState, useEffect, useRef } from 'react'
import { Space, DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN'
import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'

const MyDatePicker = ({
  id,
  required = false,
  label = '',
  placeholder,
  value,
  onChange,
  errorMsg,
  styles,
  format = 'YYYY-MM-DD HH:mm:ss',
  ...rest
}) => {
  const inputRef = useRef(null)
  const [status, setStatus] = useState('')

  useEffect(() => {
    if(errorMsg) {
      inputRef.current.focus()
      setStatus('error')
    } else {
      setStatus('')
    }
  }, [errorMsg])

  return (
    <div className="form-box">
      <label htmlFor={id}>
        {label} 
        {required && <span style={{ color: '#ae2a19' }}> *</span>}
      </label>

      <Space direction="vertical">
        <DatePicker
          locale={locale}
          ref={inputRef}
          id={id}
          status={status}
          allowClear={true}
          placeholder={placeholder}
          value={value ? dayjs(value, format) : null}
          onChange={(date, dateString) => onChange(prevValues => ({ ...prevValues, [id]: dateString }))}
          style={{ height: '40px', width: '215px', ...styles  }}
          {...rest}
        />
      </Space>

      {errorMsg && (
        <div className="form-errormsg">
          <span className="iconfont icon-jinggao"></span> 
          <p>{errorMsg}</p>
        </div>
      )}
    </div>
  )
}

export default MyDatePicker
