import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'

const EditChatbotFaq = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/chatbot/faq/detail/get', {
      merchant_oid: merchantOid,
      faq_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.question) {
      setErrorMsg({questionEmpty: '问题不能为空'})
      return false
    }

    axios.post('/p/chatbot/faq/edit', { 
      merchant_oid: merchantOid,
      faq_oid: formValue.oid,
      question: formValue.question,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getFaqList()
          message.success('常见问题修改成功！')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title='编辑常见问题'
      destroyOnClose={true}
      width='800px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyInput
        id="question"
        required
        autoFocus
        maxLength={64}
        label='问题'
        placeholder='请输入文章的标题'
        value={formValue.question}
        onChange={setFormValue}
        errorMsg={errorMsg.questionEmpty}
      />

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />
    </Modal>
  )
}

export default EditChatbotFaq
