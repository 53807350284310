import React from 'react'
import ReactDOM from 'react-dom/client'
import Router from './router'
import { MyContextProvider } from './context'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import './request'
import './styles/index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <MyContextProvider>
      <ConfigProvider locale={zhCN}>
        <Router />
      </ConfigProvider>
    </MyContextProvider>
  </React.StrictMode>
)
