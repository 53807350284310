import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, Spin, message } from 'antd'
import MyIcon from '../../../components/general/icon'

const PrintAdmissionNotice = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [noticeAddress, setNoticeAddress] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getPrintNotice(props.data.oid)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, props.data])

  const getPrintNotice = (oid) => {
    axios.post('/p/admission_notice/generate', { 
      merchant_oid: merchantOid,
      student_oids: oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setNoticeAddress(response.data.download_address)
          setIsLoading(false)
        } else {
          message.error(response.msg)
          setIsLoading(false)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const printNotices = () => {
    window.open(noticeAddress, '_blank')
    axios.post('/p/admission_notice/mark_printed', { 
      merchant_oid: merchantOid,
      student_oids: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          props.getStudentData()
          props.isCancel()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <Modal
      width={400}
      title="录取通知书打印"
      open={props.isOpen}
      onCancel={props.isCancel}
      footer=''
    >

      <Spin spinning={isLoading} tip='录取通知书生成中，请稍等'>
        <div className='public-print-container'>
          <div className='public-print-icon'>
            <MyIcon name='luqutongzhishu2' />
          </div>

          {!isLoading && (<>
            <div className='public-print-title'>录取通知书已生成</div>
            <div className='public-print-button1' onClick={ printNotices }>
              <p>预览打印</p>
            </div>
            <div className='public-print-button2' onClick={() => { props.isCancel() }}>
              <p>取消</p>
            </div>
          </>)}
        </div>
      </Spin>
    </Modal>
  )
}

export default PrintAdmissionNotice
