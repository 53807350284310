import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MyIcon from '../../../components/general/icon'
import AddCampus from './add'
import EditCampus from './edit'
import DeleteCampus from './delete'

export default function Campus() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [campusList, setCampusList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isAddCampusVisible, setIsAddCampusVisible] = useState(false)
  const [isEditCampusVisible, setIsEditCampusVisible] = useState(false)
  const [isDeleteCampusVisible, setIsDeleteCampusVisible] = useState(false)

  const getCampusList = () => {
    axios.post('/p/info/campus/list/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setCampusList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getCampusList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const editCampus = (oid) => {
    setIsEditCampusVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteCampus = (oid, name) => {
    setIsDeleteCampusVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">校区位置</div>
        </div>

        <div className='public-main-style'>
          <div className='public-main-style1'>
            <div className='public-main-style1-add' onClick={() => { setIsAddCampusVisible(true) }}>
              <div className='public-main-style1-add-icon'>
                <MyIcon name='jiahao' />
              </div>
              <div className='public-main-style1-add-title'>创建新的校区</div>
            </div>

            {campusList.map(item => (
              <div className='public-main-style1-content' key={item.oid} onClick={() => { editCampus(item.oid) }}>
                <div>
                  <div className='public-main-style1-content-title'>
                    <div className='public-main-style1-content-name'>{item.name}</div>
                    <div className='public-main-style1-content-delete' 
                      onClick={(event) => {event.stopPropagation(); deleteCampus(item.oid, item.name)}}>
                      <MyIcon name='huishouzhan' />
                    </div>
                  </div>
                  <div className='public-main-style1-content-description'>{item.address}</div>
                </div>
                <div className='public-main-style1-content-other'></div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isAddCampusVisible && <AddCampus
        getCampusList={getCampusList} 
        isOpen={isAddCampusVisible} 
        isCancel={() => { setIsAddCampusVisible(false) }} 
      />}

      {isEditCampusVisible && <EditCampus
        data={propsData}
        getCampusList={getCampusList}
        isOpen={isEditCampusVisible}
        isCancel={() => { setIsEditCampusVisible(false) }}
      />}

      {isDeleteCampusVisible && <DeleteCampus
        data={propsData}
        getCampusList={getCampusList}
        isOpen={isDeleteCampusVisible}
        isCancel={() => { setIsDeleteCampusVisible(false) }}
      />}
    </div>
  )
}
