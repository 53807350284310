import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyImageUpload from '../../../components/forms/imageUpload'

const EditColumn = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/content/column/detail/get', {
      merchant_oid: merchantOid,
      column_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({nameEmpty: '栏目名称不能为空'})
      return false
    }

    axios.post('/p/content/column/edit', {
      merchant_oid: merchantOid,
      column_oid: formValue.oid,
      name: formValue.name,
      sort: Number(formValue.sort),
      icon: formValue.icon
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('栏目编辑成功！')
          handleCancel()
          props.getColumnList()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title='编辑栏目'
        width='460px'
        open={props.isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="取消"
        okText="保存">

        <MyImageUpload 
          id="icon"
          label='图标'
          value={formValue.icon}
          setValue={setFormValue}
        />

        <MyInput
          id="name"
          required
          autoFocus
          maxLength={64}
          label='名称'
          placeholder='请输入栏目的名称'
          value={formValue.name}
          onChange={setFormValue}
          errorMsg={errorMsg.nameEmpty}
        />

        <MyInput
          type='number'
          id="sort"
          label='排序'
          value={formValue.sort}
          onChange={setFormValue}
          styles={{ width: '60px' }}
          min={0}
          max={1000}
        />
      </Modal>
    </>
  )
}

export default EditColumn
