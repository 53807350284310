import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../components/forms/input'

const ChangePwd = (props) => {
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.currentPassword) {
      setErrorMsg({currentPasswordEmpty: '当前密码不能为空'})
      return
    }
    if (!formValue.newPassword) {
      setErrorMsg({newPasswordEmpty: '新密码不能为空'})
      return
    }
    if (!formValue.confirmPassword) {
      setErrorMsg({confirmPasswordEmpty: '确认密码不能为空'})
      return
    }
    if (formValue.newPassword.trim() !== formValue.confirmPassword.trim()) {
      setErrorMsg({passwordMismatch: '新密码和确认密码不一致'})
      return
    }

    axios.post('/p/people/pwd/change', {
      current_password: formValue.currentPassword,
      new_password: formValue.newPassword,
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          message.success('密码修改成功！')
        } else if (response.errcode === 1) {
          setErrorMsg({newPasswordLengthError: response.msg})
        } else if (response.errcode === 2) {
          setErrorMsg({currentPasswordError: response.msg})
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setFormValue({})
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <>
      <Modal 
        title="修改密码"
        width='500px'
        open={props.isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="取消"
        okText="保存">
        
        <div className='modalBox'>
          <MyInput
            id="currentPassword"
            type="password"
            autoFocus
            label="当前密码"
            placeholder='请输入当前密码'
            value={formValue.currentPassword}
            onChange={setFormValue}
            errorMsg={errorMsg.currentPasswordEmpty || errorMsg.currentPasswordError}
          />

          <MyInput
            id="newPassword"
            type="password"
            label="新密码"
            placeholder='请输入新密码'
            value={formValue.newPassword}
            onChange={setFormValue}
            errorMsg={errorMsg.newPasswordEmpty || errorMsg.newPasswordLengthError}
          />

          <MyInput
            id="confirmPassword"
            type="password"
            label="确认密码"
            placeholder='确认新密码'
            value={formValue.confirmPassword}
            onChange={setFormValue}
            errorMsg={errorMsg.confirmPasswordEmpty || errorMsg.passwordMismatch}
          />
        </div>
      </Modal>
    </>
  )
}

export default ChangePwd
