import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { message, Upload, Spin } from 'antd'
import EditMailInfo from './editMailInfo'
import { apiBaseURL } from '../../../request'
import MyQrcode from '../../../components/general/qrcode'
import EditEffectiveDate from './editEffectiveDate'
import EditPausePrompt from './editPausePrompt'

export default function FreshmenConfig() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [freshmenConfig, setFreshmenConfig] = useState({})
  const [propsData, setPropsData] = useState({})
  const [isEditEffectiveDateVisible, setIsEditEffectiveDateVisible] = useState(false)
  const [isEditPausePromptVisible, setIsEditPausePromptVisible] = useState(false)
  const [isEditMailInfoVisible, setIsEditMailInfoVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isAccessAddressVisible, setIsAccessAddressVisible] = useState(false)

  const getFreshmenConfig = () => {
    axios.post('/p/retention_admission/config/get', {
      merchant_oid: merchantOid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFreshmenConfig(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getFreshmenConfig()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, isLoading])

  const handleMailInfo = () => {
    setIsEditMailInfoVisible(true)
    setPropsData({ info: freshmenConfig })
  }

  const handlePausePrompt = () => {
    setIsEditPausePromptVisible(true)
    setPropsData({ info: freshmenConfig })
  }

  const handleEffectiveDate = () => {
    setIsEditEffectiveDateVisible(true)
    setPropsData({ info: freshmenConfig })
  }

  const previewTemplate = (templateAddress) => {
    if (!templateAddress) {
      message.error('无法预览，请先导入相应模版文件！')
      return false
    }
    window.open(templateAddress, '_blank')
  }

  const fileUpload = (option) => ({
    name: 'doc',
    action: `${apiBaseURL}/common/doc/upload`,
    headers: {
      authorization: localStorage.getItem('token'),
    },
    onChange(info) {
      if (info.file.status === 'done') {
        handleImport(info.file.response.data.doc_url, option)
      }
    }
  })

  const handleImport = (filename, option) => {
    setIsLoading(true)
    axios.post('/p/retention_admission/config/import', { 
      merchant_oid: merchantOid,
      option: option,
      filename: filename
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('导入成功！')
          setIsLoading(false)
        } else {
          message.error(response.msg)
          setIsLoading(false)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const previewAccessAddress = (oid) => {
    setIsAccessAddressVisible(true)
    setPropsData({ accessAddress: 'https://student.yunzhaosoft.com/'+merchantOid})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">配置信息</div>
        </div>

        <div className='public-main-style'>
          <Spin spinning={isLoading} tip='导入中，请稍等'>
            <div className='public-main-style4'>
              <div className='public-main-style4-items'>
                <div className='public-main-style4-item'>
                  <div className='public-main-style4-item-left'>
                    <div className='public-main-style4-item-title'>暂停服务</div>
                    <div className='public-main-style4-item-description'>
                      {freshmenConfig.is_pause ? '已暂停：' + freshmenConfig.pause_prompt : '未暂停'}
                    </div>
                  </div>
                  <div className='public-main-style4-item-right'>
                    <div className='public-main-style4-item-buttonPrimary' onClick={handlePausePrompt}>编辑</div>
                  </div>
                </div>
              </div>

              <div className='public-main-style4-items'>
                <div className='public-main-style4-item'>
                  <div className='public-main-style4-item-left'>
                    <div className='public-main-style4-item-title'>学生访问地址</div>
                    <div className='public-main-style4-item-description'>点击查看可复制访问链接和下载二维码，可在微信小程序或公众号跳转访问</div>
                  </div>
                  <div className='public-main-style4-item-right'>
                    <div className='public-main-style4-item-buttonPrimary' onClick={previewAccessAddress}>查看</div>
                  </div>
                </div>
              </div>

              <div className='public-main-style4-items'>
                <div className='public-main-style4-item'>
                  <div className='public-main-style4-item-left'>
                    <div className='public-main-style4-item-title'>新生保留入学资格证明</div>
                    <div className='public-main-style4-item-description'>
                      导入docx格式的模版文件，在申请通过审核后会自动为学生生成pdf格式的证明文件，模版中的动态数据使用双花括号包裹，
                      如：{'\u007B\u007B'}姓名{'\u007D\u007D'}、{'\u007B\u007B'}学院{'\u007D\u007D'}、{'\u007B\u007B'}专业{'\u007D\u007D'}、{'\u007B\u007B'}原因{'\u007D\u007D'}...
                    </div>
                  </div>
                  <div className='public-main-style4-item-right'>
                    <Upload {...fileUpload(1)} showUploadList={false}>
                      <div className='public-main-style4-item-buttonPrimary'>导入</div>
                    </Upload>
                    <div className='public-main-style4-item-buttonSecondary' onClick={() => {previewTemplate(freshmenConfig.retention_proof)}}>预览</div>
                  </div>
                </div>

                <div className='public-main-style4-item'>
                  <div className='public-main-style4-item-left'>
                    <div className='public-main-style4-item-title'>新生保留入学资格申请表</div>
                    <div className='public-main-style4-item-description'>导入pdf格式的空白申请表，学生填写申请表时，提供给学生下载</div>
                  </div>
                  <div className='public-main-style4-item-right'>
                    <Upload {...fileUpload(2)} showUploadList={false}>
                      <div className='public-main-style4-item-buttonPrimary'>导入</div>
                    </Upload>
                    <div className='public-main-style4-item-buttonSecondary' onClick={() => {previewTemplate(freshmenConfig.deferral_form)}}>预览</div>
                  </div>
                </div>

                <div className='public-main-style4-item'>
                  <div className='public-main-style4-item-left'>
                    <div className='public-main-style4-item-title'>保留入学资格学生复学审批表</div>
                    <div className='public-main-style4-item-description'>导入pdf格式的空白申请表，学生填写申请表时，提供给学生下载</div>
                  </div>
                  <div className='public-main-style4-item-right'>
                    <Upload {...fileUpload(3)} showUploadList={false}>
                      <div className='public-main-style4-item-buttonPrimary'>导入</div>
                    </Upload>
                    <div className='public-main-style4-item-buttonSecondary' onClick={() => {previewTemplate(freshmenConfig.readmission_form)}}>预览</div>
                  </div>
                </div>

                <div className='public-main-style4-item'>
                  <div className='public-main-style4-item-left'>
                    <div className='public-main-style4-item-title'>新生报道手册</div>
                    <div className='public-main-style4-item-description'>导入pdf格式的新生报道手册，学生在复学审批通过后可查看或下载</div>
                  </div>
                  <div className='public-main-style4-item-right'>
                    <Upload {...fileUpload(4)} showUploadList={false}>
                      <div className='public-main-style4-item-buttonPrimary'>导入</div>
                    </Upload>
                    <div className='public-main-style4-item-buttonSecondary' onClick={() => {previewTemplate(freshmenConfig.reporting_handbook)}}>预览</div>
                  </div>
                </div>
              </div>

              <div className='public-main-style4-items'>
                <div className='public-main-style4-item'>
                  <div className='public-main-style4-item-left'>
                    <div className='public-main-style4-item-title'>保留资格生效日期</div>
                    <div className='public-main-style4-item-description'>
                      {freshmenConfig.effective_date ? freshmenConfig.effective_date : '未完善'}
                    </div>
                    <div className='public-main-style4-item-buttonPrimary' onClick={handleEffectiveDate}>编辑</div>
                  </div>
                </div>
              </div>

              <div className='public-main-style4-items'>
                <div className='public-main-style4-item'>
                  <div className='public-main-style4-item-left'>
                    <div className='public-main-style4-item-title'>邮寄信息</div>
                    <div className='public-main-style4-item-description'>
                      {freshmenConfig.contact ?
                        <>{freshmenConfig.contact} ｜ {freshmenConfig.phone} ｜ {freshmenConfig.address}</>
                      : '未完善'}
                    </div>
                    {freshmenConfig.deferral_mail_info &&
                      <div className='public-main-style4-item-description'>
                        <pre>{freshmenConfig.deferral_mail_info}</pre>
                      </div>
                    }
                    {freshmenConfig.readmission_mail_info &&
                      <div className='public-main-style4-item-description'>
                        <pre>{freshmenConfig.readmission_mail_info}</pre>
                      </div>
                    }
                    <div className='public-main-style4-item-buttonPrimary' onClick={handleMailInfo}>编辑</div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>

      {isEditMailInfoVisible && <EditMailInfo
        data={propsData}
        getFreshmenConfig={getFreshmenConfig}
        isOpen={isEditMailInfoVisible}
        isCancel={() => { setIsEditMailInfoVisible(false) }}
      />}

      {isEditEffectiveDateVisible && <EditEffectiveDate
        data={propsData}
        getFreshmenConfig={getFreshmenConfig}
        isOpen={isEditEffectiveDateVisible}
        isCancel={() => { setIsEditEffectiveDateVisible(false) }}
      />}

      {isEditPausePromptVisible && <EditPausePrompt
        data={propsData}
        getFreshmenConfig={getFreshmenConfig}
        isOpen={isEditPausePromptVisible}
        isCancel={() => { setIsEditPausePromptVisible(false) }}
      />}

      {isAccessAddressVisible && <MyQrcode
        data={propsData}
        isOpen={isAccessAddressVisible}
        isCancel={() => { setIsAccessAddressVisible(false) }}
      />}
    </div>
  )
}
