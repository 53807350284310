import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyTextArea from '../../../components/forms/textArea'
import MyDatePicker from '../../../components/forms/datePicker'
import MyRadio from '../../../components/forms/radio'

const EditFacebodyCategory = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/facebody/category/detail/get', {
      merchant_oid: merchantOid,
      facebody_category_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({nameEmpty: '人脸核身分类名称不能为空'})
      return false
    }

    axios.post('/p/facebody/category/edit', { 
      merchant_oid: merchantOid, 
      facebody_category_oid: formValue.oid, 
      name: formValue.name,
      descrption: formValue.descrption,
      liveness_type: formValue.liveness_type,
      comparison_method: formValue.comparison_method,
      start_time: formValue.start_time,
      end_time: formValue.end_time,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getFacebodyCategoryList()
          message.success('人脸核身修改成功！')
        } else {
          message.error(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title={`编辑人脸核身分类<${formValue.oid}>`}
      destroyOnClose={true}
      width='700px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyInput
        id="name"
        required
        autoFocus
        maxLength={64}
        label='名称'
        placeholder='请输入人脸核身分类的名称'
        value={formValue.name}
        onChange={setFormValue}
        errorMsg={errorMsg.nameEmpty}
      />

      <MyTextArea 
        id="descrption"
        label='描述'
        minRows={3}
        placeholder='请输入人脸核身分类的描述'
        value={formValue.descrption}
        onChange={setFormValue}
      />

      <MyRadio
        id='comparison_method'
        label='人像比对方式'
        defaultValue={1}
        options={[
          {
            label: '活体与系统照片',
            value: 1,
          },
          {
            label: '活体与公安库',
            value: 2,
          },
          {
            label: '活体与系统照片+公安库',
            value: 3,
          }
        ]}
        value={formValue.comparison_method}
        onChange={setFormValue}
      />

      <MyRadio
        id='liveness_type'
        label='活体检测类型'
        options={[
          {
            label: '静默模式',
            value: 1,
          },
          {
            label: '数字模式',
            value: 2,
          },
          {
            label: '动作模式',
            value: 3,
          }
        ]}
        value={formValue.liveness_type}
        onChange={setFormValue}
      />

      <MyDatePicker
        id="start_time"
        label='开始时间'
        placeholder='选择时间'
        value={formValue.start_time}
        onChange={setFormValue}
        styles={{width: '360px'}}
        showTime
      />

      <MyDatePicker
        id="end_time"
        label='结束时间'
        placeholder='选择时间'
        value={formValue.end_time}
        onChange={setFormValue}
        styles={{width: '360px'}}
        showTime
      />

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />
    </Modal>
  )
}
export default EditFacebodyCategory
