import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Input, Select } from 'antd'
import MyIcon from '../../../components/general/icon'

const JobPostRibbon = ({
  isAddVisible,
  filterValue,
  setFilterValue
}) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [unitList, setUnitList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [keywordsValue, setKeywordsValue] = useState('')
  const [searchBoxVisible, setSearchBoxVisible] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFilterValue({ ...filterValue, keywords: keywordsValue })
    }, 300)
    return () => clearTimeout(timeoutId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordsValue])

  useEffect(() => {
    axios.post('/p/jobs/unit/list/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setUnitList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })

    axios.post('/p/jobs/category/list/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setCategoryList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid])

  let unitItems = [
    {  
      value: '', 
      label: '全部单位'
    },
    ...unitList.map((item) => (
      {
        value: item.oid,
        label: item.name
      }
    ))
  ]

  let categoryItems = [
    {  
      value: '', 
      label: '全部分类'
    },
    ...categoryList.map((item) => (
      {
        value: item.oid,
        label: item.name
      }
    ))
  ]

  const clickSortFilter = () => {
    if (filterValue.sort_filter === 1) {
      setFilterValue({ ...filterValue, sort_filter: 0 })
    } else {
      setFilterValue({ ...filterValue, sort_filter: 1 })
    }
  }

  const clickUnitFilter = (value) => {
    setFilterValue({ ...filterValue, unit_oid: value })
  }

  const clickCategoryFilter = (value) => {
    setFilterValue({ ...filterValue, category_oid: value })
  }

  const handleBlur = () => {
    if (!keywordsValue) {
      setSearchBoxVisible(false)
      setFilterValue({ ...filterValue, keywords: '' })
    }
  }

  return (
    <div className='filter-container'>
      <div className='filter-filterContainer'>
        <div className='filter-addButton' onClick={() => { isAddVisible() }}>添加职位</div>

        <Select
          placeholder="所属单位"
          options={unitItems}
          style={{height: '32px', width: '200px'}}
          onChange={(value) => clickUnitFilter(value)}
        />

        <Select
          placeholder="所属分类"
          options={categoryItems}
          style={{height: '32px', width: '200px'}}
          onChange={(value) => clickCategoryFilter(value)}
        />

        {!searchBoxVisible 
        ? <div className='filter-condition' onClick={() => {setSearchBoxVisible(true)}}>
            <span><MyIcon name='sousuo'/></span>
            <p>搜索</p>
          </div>
        : <Input
            style={{width: '260px'}}
            prefix={<MyIcon name='sousuo'/>}
            maxLength={64}
            autoFocus
            placeholder='输入关键字'
            value={keywordsValue}
            onChange={(e) => setKeywordsValue(e.target.value)}
            onBlur={handleBlur}
            allowClear
          />
        }

        <div className='filter-condition' onClick={() => {clickSortFilter()}}>
          <span>{filterValue.sort_filter === 1 ? <MyIcon name='zhengxu'/> : <MyIcon name='daoxu'/>}</span>
          <p>排序</p>
        </div>
      </div>
    </div>
  )
}

export default JobPostRibbon
