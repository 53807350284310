import React, { useState, useEffect } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import { Modal, message, Button, Tag, Input } from 'antd'
import MyInput from '../../../components/forms/input'
import MyRichText from '../../../components/forms/richText'
import MySwitch from '../../../components/forms/switch'
import MyUnitSelect from '../../../components/select/unitSelect'
import MyCategorySelect from '../../../components/select/categorySelect'
import MyEducationSelect from '../../../components/select/educationSelect'
import MyDegreeSelect from '../../../components/select/degreeSelect'
import MyExaminationTypeSelect from '../../../components/select/examinationTypeSelect'
import MySourceTypeSelect from '../../../components/select/sourceTypeSelect'
import MyDatePicker from '../../../components/forms/datePicker'

const AddJobPost = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})
  const [newTag, setNewTag] = useState('')
  const [newReceiveMailbox, setNewReceiveMailbox] = useState('')

  useEffect(() => {
    const today = dayjs()
    setFormValue({
      release_time: dayjs(today).format('YYYY-MM-DD HH:mm:ss')
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const handleSubmit = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({ nameEmpty: '职位名称不能为空' })
      return false
    }
    if (!formValue.unit_oid) {
      setErrorMsg({ unitEmpty: '所属招聘单位不能为空' })
      return false
    }
    if (!formValue.category_oid) {
      setErrorMsg({ categoryEmpty: '所属职位分类不能为空' })
      return false
    }

    axios.post('/p/jobs/post/add', {
      merchant_oid: merchantOid,
      unit_oid: formValue.unit_oid,
      category_oid: formValue.category_oid,
      name: formValue.name,
      code: formValue.code,
      shortlisted_proportion: formValue.shortlisted_proportion,
      is_top: formValue.is_top,
      release_time: formValue.release_time,
      number: Number(formValue.number),
      tags: formValue.tags || [],
      receive_mailbox: formValue.receive_mailbox || [],
      introduction: formValue.introduction,
      condition: formValue.condition,
      advantage: formValue.advantage,
      educational_requirement: Number(formValue.educational_requirement),
      degree_requirement: Number(formValue.degree_requirement),
      examination_type: Number(formValue.examination_type),
      source_type: Number(formValue.source_type),
      address: formValue.address,
      phone: formValue.phone,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('创建成功！')
          props.getJobPostList()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleOk = () => {
    const isOk = handleSubmit()
    if (isOk !== false) { handleCancel() }
  }

  const handleNext = () => {
    handleSubmit()
    const today = dayjs()
    setFormValue({
      release_time: dayjs(today).format('YYYY-MM-DD HH:mm:ss'),
    })
  }

  const handleCancel = () => {
    setErrorMsg({})
    setFormValue({})
    props.isCancel()
  }

  const addTag = () => {
    if (newTag && !formValue.tags?.includes(newTag)) {
      setFormValue(prev => ({
        ...prev,
        tags: [...(prev.tags || []), newTag]
      }))
      setNewTag('')
    }
  }

  const removeTag = (tagToRemove) => {
    setFormValue(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }))
  }

  const addReceiveMailbox = () => {
    if (newReceiveMailbox && !formValue.receive_mailbox?.includes(newReceiveMailbox)) {
      setFormValue(prev => ({
        ...prev,
        receive_mailbox: [...(prev.receive_mailbox || []), newReceiveMailbox]
      }))
      setNewReceiveMailbox('')
    }
  }

  const removeReceiveMailbox = (tagToRemove) => {
    setFormValue(prev => ({
      ...prev,
      receive_mailbox: prev.receive_mailbox.filter(tag => tag !== tagToRemove)
    }))
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="创建招聘职位"
        width='800px'
        open={props.isOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key='cancel' onClick={handleCancel}>取消</Button>,
          <Button key='submit' onClick={handleNext}>提交并新建下一条</Button>,
          <Button key='isOk' type="primary" onClick={handleOk}>创建</Button>
        ]}>

        <MyInput
          id="name"
          required
          autoFocus
          maxLength={64}
          label='职位名称'
          placeholder='请输入职位的名称'
          value={formValue.name}
          onChange={setFormValue}
          errorMsg={errorMsg.nameEmpty}
        />

        <MyInput
          id="code"
          maxLength={64}
          label='岗位代码'
          placeholder='请输入岗位代码'
          value={formValue.code}
          onChange={setFormValue}
        />

        <MyUnitSelect
          id='unit_oid'
          required
          label='选择所属单位'
          category={1}
          value={formValue.unit_oid}
          onChange={setFormValue}
          errorMsg={errorMsg.unitEmpty}
        />

        <MyCategorySelect
          id='category_oid'
          required
          label='选择职位分类'
          category={1}
          value={formValue.category_oid}
          onChange={setFormValue}
          errorMsg={errorMsg.categoryEmpty}
        />

        <MyDatePicker
          id="release_time"
          label='发布时间'
          value={formValue.release_time}
          onChange={setFormValue}
          errorMsg={errorMsg.releaseTimeEmpty}
          showTime
        />

        <MyInput
          type='number'
          id="number"
          label='招考数量'
          value={formValue.number}
          onChange={setFormValue}
          styles={{ width: '60px' }}
          min={0}
          max={1000}
        />

        <MyInput
          id="shortlisted_proportion"
          maxLength={64}
          label='入围比例'
          placeholder='请输入入围比例'
          value={formValue.shortlisted_proportion}
          onChange={setFormValue}
        />

        <div className="form-box">
          <label>岗位标签（岗位类别）</label>
          <div style={{ marginBottom: '2px' }}>
            {(formValue.tags || []).map(tag => (
              <Tag key={tag}
                closable
                onClose={() => removeTag(tag)}
              >
                {tag}
              </Tag>
            ))}
          </div>
          <Input
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onPressEnter={addTag}
            placeholder='输入标签并按回车添加'
          />
        </div>

        <div className="form-box">
          <label>接收邮箱</label>
          <div style={{ marginBottom: '2px' }}>
            {(formValue.receive_mailbox || []).map(tag => (
              <Tag key={tag}
                closable
                onClose={() => removeReceiveMailbox(tag)}
              >
                {tag}
              </Tag>
            ))}
          </div>
          <Input
            value={newReceiveMailbox}
            onChange={(e) => setNewReceiveMailbox(e.target.value)}
            onPressEnter={addReceiveMailbox}
            placeholder='输入接收邮箱并按回车添加'
          />
        </div>

        <MyEducationSelect
          id='educational_requirement'
          label='选择学历要求'
          category={1}
          value={formValue.educational_requirement}
          onChange={setFormValue}
        />

        <MyDegreeSelect
          id='degree_requirement'
          label='选择学位要求'
          category={1}
          value={formValue.degree_requirement}
          onChange={setFormValue}
        />

        <MyExaminationTypeSelect
          id='examination_type'
          label='选择考试类型'
          category={1}
          value={formValue.examination_type}
          onChange={setFormValue}
        />

        <MySourceTypeSelect
          id='source_type'
          label='选择来源类型'
          category={1}
          value={formValue.source_type}
          onChange={setFormValue}
        />

        <MyRichText
          id='introduction'
          label='岗位介绍'
          value={formValue.introduction}
          setValue={setFormValue}
        />

        <MyRichText
          id='condition'
          label='应聘条件'
          value={formValue.condition}
          setValue={setFormValue}
        />

        <MyRichText
          id='advantage'
          label='岗位优势'
          value={formValue.advantage}
          setValue={setFormValue}
        />

        <MyInput
          id="address"
          maxLength={64}
          label='工作地址'
          placeholder='请输入工作地址'
          value={formValue.address}
          onChange={setFormValue}
        />

        <MyInput
          id="phone"
          maxLength={64}
          label='咨询电话'
          placeholder='请输入咨询电话'
          value={formValue.phone}
          onChange={setFormValue}
        />

        <MySwitch
          id='is_top'
          label='是否置顶'
          onChange={setFormValue}
        />

        <MyInput
          type='number'
          id="sort"
          label='排序'
          value={formValue.sort}
          onChange={setFormValue}
          styles={{ width: '60px' }}
          min={0}
          max={1000}
        />
      </Modal>
    </>
  )
}

export default AddJobPost
