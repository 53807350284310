import React, { useEffect, useState } from 'react'
import axios from 'axios'
import EditWelcomeMsg from './editWelcomeMsg'
import EditGreetingResponse from './editGreetingResponse'
import EditNoAnswerResponse from './editNoAnswerResponse'

export default function ChatbotConfig() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [chatbotConfig, setChatbotConfig] = useState({})
  const [propsData, setPropsData] = useState({})
  const [isEditWelcomeMsgVisible, setIsEditWelcomeMsgVisible] = useState(false)
  const [isEditGreetingResponseVisible, setIsEditGreetingResponseVisible] = useState(false)
  const [isEditNoAnswerResponseVisible, setIsEditNoAnswerResponseVisible] = useState(false)

  const getChatbotConfig = () => {
    axios.post('/p/chatbot/config/get', {
      merchant_oid: merchantOid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setChatbotConfig(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getChatbotConfig()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const handleWelcomeMsg = () => {
    setIsEditWelcomeMsgVisible(true)
    setPropsData({ info: chatbotConfig })
  }

  const handleGreetingResponse = () => {
    setIsEditGreetingResponseVisible(true)
    setPropsData({ info: chatbotConfig })
  }

  const handleNoAnswerResponse = () => {
    setIsEditNoAnswerResponseVisible(true)
    setPropsData({ info: chatbotConfig })
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">配置信息</div>
        </div>

        <div className='public-main-style'>
          <div className='public-main-style4'>
            <div className='public-main-style4-items'>
              <div className='public-main-style4-item'>
                <div className='public-main-style4-item-left'>
                  <div className='public-main-style4-item-title'>欢迎语</div>
                  <div className='public-main-style4-item-description'>
                    {chatbotConfig.welcome_msg}
                  </div>
                </div>
                <div className='public-main-style4-item-right'>
                  <div className='public-main-style4-item-buttonPrimary' onClick={handleWelcomeMsg}>编辑</div>
                </div>
              </div>
            </div>

            <div className='public-main-style4-items'>
              <div className='public-main-style4-item'>
                <div className='public-main-style4-item-left'>
                  <div className='public-main-style4-item-title'>问候语回应</div>
                  <div className='public-main-style4-item-description'>
                    {chatbotConfig.greeting_response}
                  </div>
                </div>
                <div className='public-main-style4-item-right'>
                  <div className='public-main-style4-item-buttonPrimary' onClick={handleGreetingResponse}>编辑</div>
                </div>
              </div>
            </div>

            <div className='public-main-style4-items'>
              <div className='public-main-style4-item'>
                <div className='public-main-style4-item-left'>
                  <div className='public-main-style4-item-title'>无答案默认回应</div>
                  <div className='public-main-style4-item-description'>
                    {chatbotConfig.no_answer_response}
                  </div>
                </div>
                <div className='public-main-style4-item-right'>
                  <div className='public-main-style4-item-buttonPrimary' onClick={handleNoAnswerResponse}>编辑</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isEditWelcomeMsgVisible && <EditWelcomeMsg
        data={propsData}
        getChatbotConfig={getChatbotConfig}
        isOpen={isEditWelcomeMsgVisible}
        isCancel={() => { setIsEditWelcomeMsgVisible(false) }}
      />}

      {isEditGreetingResponseVisible && <EditGreetingResponse
        data={propsData}
        getChatbotConfig={getChatbotConfig}
        isOpen={isEditGreetingResponseVisible}
        isCancel={() => { setIsEditGreetingResponseVisible(false) }}
      />}

      {isEditNoAnswerResponseVisible && <EditNoAnswerResponse
        data={propsData}
        getChatbotConfig={getChatbotConfig}
        isOpen={isEditNoAnswerResponseVisible}
        isCancel={() => { setIsEditNoAnswerResponseVisible(false) }}
      />}
    </div>
  )
}
