import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message, Spin } from 'antd'
import MyIcon from '../../../components/general/icon'

const ExportJobApplication = (props) => {
  const domain = window.location.hostname;
  const merchantOid = localStorage.getItem('merchant_oid')
  const [isLoading, setIsLoading] = useState(false)

  const handleExport = () => {
    setIsLoading(true)
    axios.post('/p/jobs/application/export', { 
      merchant_oid: merchantOid,
      unit_oid: props.filterValue.unit_oid,
      job_oid: props.filterValue.job_oid,
      domain_name: domain + '/resume/preview/'
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('导出成功！')
          setIsLoading(false)
          const link = document.createElement('a')
          link.href = response.data.download_address
          link.setAttribute('download', '')
          link.click()
          document.body.removeChild(link)
        } else {
          message.error(response.msg)
          setIsLoading(false)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <Modal
      width={460}
      title="投递记录导出"
      open={props.isOpen}
      onCancel={props.isCancel}
      footer=''>
      
      <Spin spinning={isLoading}>
        <div className='public-import-container' style={{height: '370px'}}>
          <div className='public-import-box'>
            <div className='public-import-icon'><MyIcon  name='daochu' /></div>
            <div className='public-import-title'>导出为Excel</div>
            <div className='public-import-despcription'>默认导出全部数据，可通过条件筛选，导出对应条件的数据</div>
            <div className='public-import-button1' onClick={() => { handleExport() }}>
              <p>导出数据</p>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default ExportJobApplication
