import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { message } from 'antd'
import MyRichText from '../../../components/forms/richText'
import MyButton from '../../../components/forms/button'

export default function Intro() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})

  useEffect(() => {
    axios.post('/p/merchant/info/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid])

  const handleSubmit = () => {
    axios.post('/p/merchant/info/edit', {
      merchant_oid: merchantOid,
      introduction: formValue.introduction
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('学校简介修改成功')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">学校简介</div>
        </div>

        <div style={{ maxHeight: 'calc(100vh - 128px)', overflowY: 'auto' }}>
          <form>
            <MyRichText
              id='introduction'
              value={formValue.introduction}
              setValue={setFormValue}
              styles={{marginTop: '30px', height: '500px'}}
            />

            <MyButton 
              onClick={handleSubmit} 
              buttonText='保存'
              style={{ width: '160px', marginTop: '50px' }}
            />
          </form>
        </div>
      </div>
    </div>
  )
}
