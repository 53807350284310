import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal } from 'antd'

const ChatBotRecordDetail = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [chatBotRecordDetail, setChatBotRecordDetail] = useState({})

  useEffect(() => {
    axios.post('/p/chatbot/record/detail/get', {
      merchant_oid: merchantOid,
      record_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setChatBotRecordDetail(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleCancel = () => {
    props.isCancel()
  }

  const getColor = (isHit) => {
    if (isHit === '未命中') {
      return 'red'
    } else if (isHit === '反问') {
      return 'orange'
    } else {
      return 'green'
    }
  }

  return (
    <Modal 
      title="会话详情"
      destroyOnClose={true}
      width='700px'
      open={props.isOpen}
      onCancel={handleCancel}
      footer={false}>

      <div className='public-datail-container'>
        <div className='public-datail-item'>
          <div className='public-datail-label'>会话时间</div>
          <div className='public-datail-content'>{chatBotRecordDetail.create_time}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>用户提问</div>
          <div className='public-datail-content'>{chatBotRecordDetail.user_question}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>用户提问关键字</div>
          <div className='public-datail-content'>{chatBotRecordDetail.user_question_keywords}</div>
        </div>
        <div className='public-datail-item'>
          <div className='public-datail-label'>是否命中</div>
          <div className='public-datail-content' style={{ color: getColor(chatBotRecordDetail.is_hit) }}>{chatBotRecordDetail.is_hit}</div>
        </div>

        {chatBotRecordDetail.is_hit === '反问' && 
          <div className='public-datail-item2'>
            <div className='public-datail-label'>反问问题</div>
            {chatBotRecordDetail.reverse_questions.length > 0 ? (chatBotRecordDetail.reverse_questions.map((item) => (
              <div className='public-datail-content' key={item}>{item}</div>
            ))) : (
              <div className='public-datail-content'>空</div>
            )}
          </div>
        }

        {chatBotRecordDetail.is_hit === '命中' && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>匹配度</div>
            <div className='public-datail-content'>{chatBotRecordDetail.similarity}</div>
          </div>
        }
        {chatBotRecordDetail.is_hit === '命中' && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>匹配问题</div>
            <div className='public-datail-content'>{chatBotRecordDetail.knowledge_question}</div>
          </div>
        }
        {chatBotRecordDetail.is_hit === '命中' && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>匹配问题关键字</div>
            <div className='public-datail-content'>{chatBotRecordDetail.knowledge_question_keywords}</div>
          </div>
        }
        {chatBotRecordDetail.is_hit === '命中' && 
          <div className='public-datail-item'>
            <div className='public-datail-label'>匹配答案</div>
            <div className='public-datail-content'>{chatBotRecordDetail.answer}</div>
          </div>
        }
      </div>
    </Modal>
  )
}

export default ChatBotRecordDetail
