import React from 'react'

export default function CollectData() {

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">收集数据</div>
        </div>
      </div>
    </div>
  )
}
