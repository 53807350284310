import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MyIcon from '../../../components/general/icon'
import AddContact from './add'
import EditContact from './edit'
import DeleteContact from './delete'

export default function Contact() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [contactList, setContactList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isAddContactVisible, setIsAddContactVisible] = useState(false)
  const [isEditContactVisible, setIsEditContactVisible] = useState(false)
  const [isDeleteContactVisible, setIsDeleteContactVisible] = useState(false)

  const getContactList = () => {
    axios.post('/p/info/contact/list/get', { 
      merchant_oid: merchantOid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setContactList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getContactList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const editContact = (oid) => {
    setIsEditContactVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteContact = (oid, title) => {
    setIsDeleteContactVisible(true)
    setPropsData({ oid: oid, title: title})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">联系信息</div>
          <div className='filter-container'>
            <div className='filter-filterContainer'>
              <div className='filter-addButton' onClick={() => { setIsAddContactVisible(true) }}>添加信息</div>
            </div>
          </div>
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th style={{textAlign: 'left', paddingLeft: '20px'}}>标题</th>
                  <th>类型</th>
                  <th>描述</th>
                  <th>排序</th>
                  <th style={{width: '50px'}}></th>
                </tr>
              </thead>

              <tbody>
                {contactList && (contactList.length > 0 ? (contactList.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editContact(item.oid) }}>
                    <td style={{textAlign: 'left', paddingLeft: '20px'}}>{item.title}</td>
                    <td>{item.category}</td>
                    <td>{item.description}</td>
                    <td>{item.sort}</td>
                    <td style={{width: '50px'}}>
                      <div className='public-main-style2-operation-box'>
                        <div className='public-main-style2-operation' 
                          onClick={(event) => {event.stopPropagation(); deleteContact(item.oid, item.title)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan="5"><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isAddContactVisible && <AddContact
        getContactList={getContactList} 
        isOpen={isAddContactVisible} 
        isCancel={() => { setIsAddContactVisible(false) }} 
      />}

      {isEditContactVisible && <EditContact
        data={propsData}
        getContactList={getContactList} 
        isOpen={isEditContactVisible} 
        isCancel={() => { setIsEditContactVisible(false) }} 
      />}

      {isDeleteContactVisible && <DeleteContact
        data={propsData}
        getContactList={getContactList} 
        isOpen={isDeleteContactVisible} 
        isCancel={() => { setIsDeleteContactVisible(false) }} 
      />}
    </div>
  )
}
