import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MyIcon from '../../../components/general/icon'
import AddJobPost from './add'
import EditJobPost from './edit'
import DeleteJobPost from './delete'
import JobPostRibbon from './ribbon'

export default function JobPost() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [jobPostList, setJobPostList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [isNextPage, setIsNextPage] = useState(0)
  const [isAddJobPostVisible, setIsAddJobPostVisible] = useState(false)
  const [isEditJobPostVisible, setIsEditJobPostVisible] = useState(false)
  const [isDeleteJobPostVisible, setIsDeleteJobPostVisible] = useState(false)

  const initialConditions = {
    unit_oid: '',
    category_oid: '',
    keywords: '',
    sort_filter: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getJobPostList = () => {
    setCurrentPage(1)
    setIsNextPage(0)
    axios.post('/p/jobs/post/list/get', { 
      merchant_oid: merchantOid,
      unit_oid: filterValue.unit_oid,
      category_oid: filterValue.category_oid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
      page: 1, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setJobPostList(response.data.job_posts)
          if (response.data.total_page > 1) {
            setIsNextPage(1)
          }
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getJobPostList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue])

  const loadNextPage = () => {
    setCurrentPage(page => page + 1)
    axios.post('/p/jobs/post/list/get', { 
      merchant_oid: merchantOid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
      page: currentPage + 1, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          const newData = response.data.job_posts
          if (response.data.total_page <= currentPage + 1) {
            setIsNextPage(2)
          }
          setJobPostList(prevData => {
            const newDataIds = newData.map(item => item.oid)
            const filteredData = prevData.filter(item => !newDataIds.includes(item.oid))
            return [...filteredData, ...newData]
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const editJobPost = (oid) => {
    setIsEditJobPostVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteJobPost = (oid, name) => {
    setIsDeleteJobPostVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">职位管理</div>
          <JobPostRibbon 
            filterValue={filterValue} 
            setFilterValue={setFilterValue} 
            isAddVisible={() => { setIsAddJobPostVisible(true) }}  
          />
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th><div className='public-main-style2-name'>招聘单位</div></th>
                  <th><div className='public-main-style2-name2'>职位名称</div></th>
                  <th><div className='public-main-style2-name2'>职位分类</div></th>
                  <th>招聘人数</th>
                  <th>招聘状态</th>
                  <th>是否置顶</th>
                  <th>发布时间</th>
                  <th>排序</th>
                  <th style={{width: '50px'}}></th>
                </tr>
              </thead>

              <tbody>
                {jobPostList && (jobPostList.length > 0 ? (jobPostList.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editJobPost(item.oid) }}>
                    <td><div className='public-main-style2-name'>{item.unit}</div></td>
                    <td><div className='public-main-style2-name2'>{item.name}</div></td>
                    <td><div className='public-main-style2-name2'>{item.category}</div></td>
                    <td>{item.number}</td>
                    <td>{item.status}</td>
                    <td>{item.is_top}</td>
                    <td>{item.release_time}</td>
                    <td>{item.sort}</td>
                    <td style={{width: '50px'}}>
                      <div className='public-main-style2-operation-box'>
                        <div className='public-main-style2-operation' 
                          onClick={(event) => {event.stopPropagation(); deleteJobPost(item.oid, item.name)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan='9'><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>

              <tfoot>
                {isNextPage === 1 ? (
                  <tr className='public-main-style2-tfootNext'>
                    <td colSpan='9'>
                      <div className='public-main-style2-nextPage' onClick={loadNextPage}>下一页</div>
                    </td>
                  </tr>
                ) : ( isNextPage === 2 && 
                  <tr className='public-main-style2-tfootOver'>
                    <td colSpan='9'>已加载全部</td>
                  </tr>
                )}
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      {isAddJobPostVisible && <AddJobPost
        getJobPostList={getJobPostList} 
        isOpen={isAddJobPostVisible} 
        isCancel={() => { setIsAddJobPostVisible(false) }} 
      />}

      {isEditJobPostVisible && <EditJobPost
        data={propsData}
        getJobPostList={getJobPostList} 
        isOpen={isEditJobPostVisible} 
        isCancel={() => { setIsEditJobPostVisible(false) }} 
      />}

      {isDeleteJobPostVisible && <DeleteJobPost
        data={propsData}
        getJobPostList={getJobPostList} 
        isOpen={isDeleteJobPostVisible} 
        isCancel={() => { setIsDeleteJobPostVisible(false) }} 
      />}
    </div>
  )
}
