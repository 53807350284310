import React, { useState, useEffect } from 'react'
import axios from 'axios'
import AddChatbotFaq from './add'
import EditChatbotFaq from './edit'
import DeleteChatbotFaq from './delete'
import BasicFilter from '../../../components/filter/basicFilter'
import MyIcon from '../../../components/general/icon'

export default function ChatbotFaq() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [faqList, setFaqList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [isAddFaqVisible, setIsAddFaqVisible] = useState(false)
  const [isEditFaqVisible, setIsEditFaqVisible] = useState(false)
  const [isDeleteFaqVisible, setIsDeleteFaqVisible] = useState(false)

  const initialConditions = {
    keywords: '',
    sort_filter: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getFaqList = () => {
    axios.post('/p/chatbot/faq/list/get', { merchant_oid: merchantOid })
      .then(function (response) {
        if (response.errcode === 0) {
          setFaqList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getFaqList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const editFaq = (oid) => {
    setIsEditFaqVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteFaq = (oid, question) => {
    setIsDeleteFaqVisible(true)
    setPropsData({ oid: oid, question: question})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">常见问题</div>
          <BasicFilter
            addName='问题'
            filterValue={filterValue} 
            setFilterValue={setFilterValue} 
            isAddVisible={() => { setIsAddFaqVisible(true) }}  
          />
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th style={{width: '300px'}}>
                    <div className='public-main-style2-title'>问题</div>
                  </th>
                  <th>排序</th>
                  <th style={{width: '50px'}}></th>
                </tr>
              </thead>

              <tbody>
                {faqList && (faqList.length > 0 ? (faqList.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editFaq(item.oid) }}>
                    <td style={{width: '300px'}}>
                      <div className='public-main-style2-title'>{item.question}</div>
                    </td>
                    <td>{item.sort}</td>
                    <td style={{width: '50px'}}>
                      <div className='public-main-style2-operation-box'>
                        <div className='public-main-style2-operation' 
                          onClick={(event) => {event.stopPropagation(); deleteFaq(item.oid, item.question)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan='4'><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isAddFaqVisible && <AddChatbotFaq
        getFaqList={getFaqList} 
        isOpen={isAddFaqVisible} 
        isCancel={() => { setIsAddFaqVisible(false) }} 
      />}

      {isEditFaqVisible && <EditChatbotFaq
        data={propsData}
        getFaqList={getFaqList} 
        isOpen={isEditFaqVisible} 
        isCancel={() => { setIsEditFaqVisible(false) }} 
      />}

      {isDeleteFaqVisible && <DeleteChatbotFaq
        data={propsData}
        getFaqList={getFaqList} 
        isOpen={isDeleteFaqVisible} 
        isCancel={() => { setIsDeleteFaqVisible(false) }} 
      />}
    </div>
  )
}
