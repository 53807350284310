import React, { useState, useEffect } from 'react'
import axios from 'axios'
import MyIcon from '../../../components/general/icon'
import ContentFilter from '../../../components/filter/contentFilter'
import AddArticle from './add'
import EditArticle from './edit'
import DeleteArticle from './delete'

export default function Article() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [articleList, setArticleList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [isNextPage, setIsNextPage] = useState(0)
  const [isAddArticleVisible, setIsAddArticleVisible] = useState(false)
  const [isEditArticleVisible, setIsEditArticleVisible] = useState(false)
  const [isDeleteArticleVisible, setIsDeleteArticleVisible] = useState(false)

  const initialConditions = {
    column_oid: '',
    keywords: '',
    sort_filter: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getArticleList = () => {
    setCurrentPage(1)
    setIsNextPage(0)
    axios.post('/p/content/article/list/get', { 
      merchant_oid: merchantOid,
      column_oid: filterValue.column_oid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
      page: 1, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setArticleList(response.data.articles)
          if (response.data.total_page > 1) {
            setIsNextPage(1)
          }
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getArticleList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue])

  const loadNextPage = () => {
    setCurrentPage(page => page + 1)
    axios.post('/p/content/article/list/get', { 
      merchant_oid: merchantOid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
      page: currentPage + 1, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          const newData = response.data.articles
          if (response.data.total_page <= currentPage + 1) {
            setIsNextPage(2)
          }
          setArticleList(prevData => {
            const newDataIds = newData.map(item => item.oid)
            const filteredData = prevData.filter(item => !newDataIds.includes(item.oid))
            return [...filteredData, ...newData]
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const editArticle = (oid) => {
    setIsEditArticleVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteArticle = (oid, name) => {
    setIsDeleteArticleVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">文章列表</div>
          <ContentFilter
            addName='文章'
            category={1}
            filterValue={filterValue} 
            setFilterValue={setFilterValue} 
            isAddVisible={() => { setIsAddArticleVisible(true) }}  
          />
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th style={{width: '300px'}}>
                    <div className='public-main-style2-title'>文章标题</div>
                  </th>
                  <th>所属栏目</th>
                  <th>打开模式</th>
                  <th>是否置顶</th>
                  <th>是否隐藏</th>
                  <th>发布时间</th>
                  <th style={{width: '50px'}}></th>
                </tr>
              </thead>

              <tbody>
                {articleList && (articleList.length > 0 ? (articleList.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editArticle(item.oid) }}>
                    <td style={{width: '300px'}}>
                      <div className='public-main-style2-title'>{item.title}</div>
                    </td>
                    <td>{item.column_name}</td>
                    <td>{item.mode}</td>
                    <td>{item.is_top}</td>
                    <td>{item.is_hide}</td>
                    <td>{item.release_time}</td>
                    <td style={{width: '50px'}}>
                      <div className='public-main-style2-operation-box'>
                        <div className='public-main-style2-operation' 
                          onClick={(event) => {event.stopPropagation(); deleteArticle(item.oid, item.title)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan='6'><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>

              <tfoot>
                {isNextPage === 1 ? (
                  <tr className='public-main-style2-tfootNext'>
                    <td colSpan='6'>
                      <div className='public-main-style2-nextPage' onClick={loadNextPage}>下一页</div>
                    </td>
                  </tr>
                ) : ( isNextPage === 2 && 
                  <tr className='public-main-style2-tfootOver'>
                    <td colSpan='6'>已加载全部</td>
                  </tr>
                )}
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      {isAddArticleVisible && <AddArticle
        getArticleList={getArticleList} 
        isOpen={isAddArticleVisible} 
        isCancel={() => { setIsAddArticleVisible(false) }} 
      />}

      {isEditArticleVisible && <EditArticle
        data={propsData}
        getArticleList={getArticleList} 
        isOpen={isEditArticleVisible} 
        isCancel={() => { setIsEditArticleVisible(false) }} 
      />}

      {isDeleteArticleVisible && <DeleteArticle
        data={propsData}
        getArticleList={getArticleList} 
        isOpen={isDeleteArticleVisible} 
        isCancel={() => { setIsDeleteArticleVisible(false) }} 
      />}
    </div>
  )
}
