import React from 'react'
import { Switch } from 'antd'

const MySwitch = ({
  id,
  required = false,
  label = '',
  defaultChecked = false,
  checked,
  onChange
}) => {

  return (
    <div className="form-box">
      <label htmlFor={id}>
        {label} 
        {required && <span style={{ color: '#ae2a19' }}> *</span>}
      </label>

      <Switch 
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={(checked) => onChange(prevValues => ({ ...prevValues, [id]: Number(checked) }))}
      />
    </div>
  )
}

export default MySwitch
