import React from 'react'
import { Outlet, NavLink } from 'react-router-dom'
import MyIcon from '../../components/general/icon'

export default function SettingNav() {
  const computedClassName = ({ isActive }) => {
    return isActive ?  "secondaryNav-menu secondaryNav-menuActive" : "secondaryNav-menu"
  }

  return (
    <div className="secondaryNav-page">
      <div className="secondaryNav-container">
        <div className="secondaryNav-headline">系统设置</div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">操作日志</div>
            <NavLink to="logs" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='caozuorizhi' /></div>
              <div className="secondaryNav-name">日志列表</div>
            </NavLink>
          </div>
      </div>
      <Outlet />
    </div>
  )
}
