import React, { useState } from 'react';
import { Upload, Button, Tooltip } from 'antd';
import { apiBaseURL } from '../../request';
import MyIcon from '../general/icon';

const MyImageUpload = ({
  id,
  label = '',
  required = false,
  size = 60,
  value,
  setValue,
  errorMsg
}) => {
  const [hovered, setHovered] = useState(false)

  const imageUpload = {
    name: 'image',
    action: `${apiBaseURL}/common/image/upload`,
    headers: {
      authorization: localStorage.getItem('token'),
    },
    onChange(info) {
      if (info.file.status === 'done') {
        setValue(prevFormValue => ({ ...prevFormValue, [id]: info.file.response.data.image_url }))
      }
    }
  }

  const handleDelete = () => {
    setValue(prevFormValue => ({ ...prevFormValue, [id]: '' }));
  }

  return (
    <div className="form-box">
      <label htmlFor={id}>
        {label}
        {required && <span style={{ color: '#ae2a19' }}> *</span>}
      </label>

      <div className="form-upload-box">
        <div
          className="form-upload-img"
          style={{
            width: `${size}px`,
            height: `${size}px`,
            position: 'relative',
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {value && <img src={value} alt="" style={{ width: `${size}px`, height: `${size}px` }} />}
          {hovered && value && (
            <Tooltip title="删除图片">
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleDelete}
              >
                <MyIcon name="huishouzhan" style={{ fontSize: '20px', color: '#fff', cursor: 'pointer' }} />
              </div>
            </Tooltip>
          )}
        </div>
        <div className="form-upload">
          <Upload {...imageUpload} showUploadList={false}>
            <Button>本地上传</Button>
          </Upload>
        </div>
      </div>

      {errorMsg && (
        <div className="form-errormsg">
          <span>
            <MyIcon name="jinggao" />
          </span>
          <p>{errorMsg}</p>
        </div>
      )}
    </div>
  );
};

export default MyImageUpload;
