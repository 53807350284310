import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Pagination } from 'antd'

export default function OperationLog() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [currentPage, setCurrentPage] = useState(1)
  const [logData, setLogData] = useState({})

  useEffect(() => {
    axios.post('/p/merchant/operation_log/get', { 
      merchant_oid: merchantOid,
      page: currentPage, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setLogData({
            'total_page': response.data.total_page,
            'current_page': response.data.current_page,
            'total': response.data.total,
            'logs': response.data.logs
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, currentPage])

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">操作日志</div>
        </div>

        <div className='public-main-style2'>
          <table className='public-main-style2-item'>
            <thead>
              <tr className='public-main-style2-thead'>
                <th style={{textAlign: 'left', paddingLeft: '20px', width: '150px'}}>操作时间</th>
                <th>操作账号</th>
                <th>操作模块</th>
                <th>操作类型</th>
                <th>操作人IP</th>
                <th style={{width: '260px'}}>
                  <div className='public-main-style2-operation_detail'>操作数据</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {logData.logs && (logData.logs.length > 0 ? (logData.logs.map((item) => (
                <tr className='public-main-style2-tbody' key={item.oid}>
                  <td style={{textAlign: 'left', paddingLeft: '20px', width: '150px'}}>{item.operation_time}</td>
                  <td>{item.producer}</td>
                  <td>{item.operation_module}</td>
                  <td>{item.action_type}</td>
                  <td>
                    {item.ip_address ? item.ip_address : '-'}
                    {item.location ? ` (${item.location})` : ''}
                  </td>
                  <td style={{width: '260px'}}>
                    <div className='public-main-style2-operation_detail'>{item.operation_detail ? item.operation_detail : '-'}</div>
                  </td>
                </tr>
              ))) : (
                <tr className='public-main-style2-tbodyEmpty'>
                  <td colSpan="8"><span>暂无数据</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='pagination'>
          <Pagination 
            current={currentPage}
            total={logData.total}
            pageSize={20}
            onChange={(page) => setCurrentPage(page)}
            showTotal={(total) => `共${total}条`}
            showSizeChanger={false}
            hideOnSinglePage={true}
            showQuickJumper={true}
          />
        </div>
      </div>
    </div>
  )
}
