import React from 'react'
import { useNavigate } from 'react-router-dom'
import MyButton from '../../components/forms/button'

export default function NotFound() {
  const navigate = useNavigate()

  return (
    <div className="error-page">
      <div className="error-container">
        <img className="error-figure" src='/images/404.png' alt="404" />
        <p className="error-title">访问的页面不存在</p>
        <MyButton 
          onClick={() => { navigate('/index') }} 
          buttonText='返回首页'
          style={{ width: '120px', marginTop: '30px' }}
        />
      </div>
    </div>
  )
}
