import React, { useState, useEffect } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import { Modal, message, Button, Spin } from 'antd'
import MyInput from '../../../components/forms/input'
import MyRichText from '../../../components/forms/richText'
import MyImageUpload from '../../../components/forms/imageUpload'
import MySwitch from '../../../components/forms/switch'
import MyRadio from '../../../components/forms/radio'
import MyTextArea from '../../../components/forms/textArea'
import MyColumnSelect from '../../../components/select/columnSelect'
import MyDatePicker from '../../../components/forms/datePicker'
import MyDocUpload from '../../../components/forms/docUpload'

const AddArticle = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const today = dayjs()
    setFormValue({
      release_time: dayjs(today).format('YYYY-MM-DD HH:mm:ss')
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const handleSubmit = () => {
    setErrorMsg({})
    if (!formValue.title) {
      setErrorMsg({titleEmpty: '文章标题不能为空'})
      return false
    }
    if (!formValue.column_oid) {
      setErrorMsg({columnEmpty: '所属栏目不能为空'})
      return false
    }

    axios.post('/p/content/article/add', {
      merchant_oid: merchantOid,
      title: formValue.title,
      column_oid: formValue.column_oid,
      is_top: formValue.is_top,
      is_hide: formValue.is_hide,
      thumbnail: formValue.thumbnail,
      mode: formValue.mode,
      rich_text: formValue.rich_text,
      page_link: formValue.page_link,
      doc_name: formValue.doc_name,
      release_time: formValue.release_time
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('新文章创建成功！')
          props.getArticleList()
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleOk = () => {
    const isOk = handleSubmit()
    if (isOk !== false) {handleCancel()}
  }

  const handleNext = () => {
    handleSubmit()
    const today = dayjs()
    setFormValue({
      release_time: dayjs(today).format('YYYY-MM-DD HH:mm:ss'),
      column_oid: formValue.column_oid
    })
  }

  const handleCancel = () => {
    setErrorMsg({})
    setFormValue({})
    props.isCancel()
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title="创建文章"
        width='800px'
        open={props.isOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key='cancel' onClick={handleCancel}>取消</Button>,
          <Button key='submit' onClick={handleNext}>提交并新建下一条</Button>,
          <Button key='isOk' type="primary" onClick={handleOk}>创建</Button>
        ]}>

        <Spin spinning={isLoading}>
          <MyInput
            id="title"
            required
            autoFocus
            maxLength={64}
            label='文章标题'
            placeholder='请输入文章的标题'
            value={formValue.title}
            onChange={setFormValue}
            errorMsg={errorMsg.titleEmpty}
          />

          <MyColumnSelect
            id='column_oid'
            required
            label='选择栏目'
            category={1}
            value={formValue.column_oid}
            onChange={setFormValue}
            errorMsg={errorMsg.columnEmpty}
          />

          <MyDatePicker
            id="release_time"
            label='发布时间'
            value={formValue.release_time}
            onChange={setFormValue}
            errorMsg={errorMsg.releaseTimeEmpty}
            showTime
          />

          <MyRadio
            id='mode'
            label='文章详情'
            options={[
              {
                label: '富文本',
                value: 1,
              },
              {
                label: '网页链接',
                value: 2,
              },
              {
                label: '文档',
                value: 3,
              }
            ]}
            defaultValue={1}
            value={formValue.mode}
            onChange={setFormValue}
          />

          {(!formValue.mode || formValue.mode === 1) && <MyRichText
            id='rich_text'
            value={formValue.rich_text}
            setValue={setFormValue}
          />}

          {formValue.mode === 2 && <MyTextArea 
            id="page_link"
            minRows={3}
            placeholder='请输入完整的网页链接地址'
            value={formValue.page_link}
            onChange={setFormValue}
          />}

          {formValue.mode === 3 && <MyDocUpload 
            id="doc_name"
            value={formValue.doc_name}
            setValue={setFormValue}
            setIsLoading={setIsLoading}
          />}

          <MyImageUpload 
            id="thumbnail"
            label='缩略图'
            value={formValue.thumbnail}
            setValue={setFormValue}
          />

          <MySwitch 
            id='is_top'
            label='是否置顶'
            onChange={setFormValue}
          />

          <MySwitch 
            id='is_hide'
            label='是否隐藏'
            onChange={setFormValue}
          />
        </Spin>
      </Modal>
    </>
  )
}

export default AddArticle
