import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ContentFilter from '../../../components/filter/contentFilter'
import AddFaq from './add'
import EditFaq from './edit'
import DeleteFaq from './delete'
import MyIcon from '../../../components/general/icon'

export default function Faq() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [faqList, setFaqList] = useState([])
  const [propsData, setPropsData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [isNextPage, setIsNextPage] = useState(0)
  const [isAddFaqVisible, setIsAddFaqVisible] = useState(false)
  const [isEditFaqVisible, setIsEditFaqVisible] = useState(false)
  const [isDeleteFaqVisible, setIsDeleteFaqVisible] = useState(false)

  const initialConditions = {
    column_oid: '',
    keywords: '',
    sort_filter: 0,
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getFaqList = () => {
    setCurrentPage(1)
    setIsNextPage(0)
    axios.post('/p/content/faq/list/get', { 
      merchant_oid: merchantOid,
      column_oid: filterValue.column_oid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
      page: 1, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFaqList(response.data.faqs)
          if (response.data.total_page > 1) {
            setIsNextPage(1)
          }
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getFaqList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue])

  const loadNextPage = () => {
    setCurrentPage(page => page + 1)
    axios.post('/p/content/faq/list/get', { 
      merchant_oid: merchantOid,
      keywords: filterValue.keywords,
      sort_filter: filterValue.sort_filter,
      page: currentPage + 1, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          const newData = response.data.faqs
          if (response.data.total_page <= currentPage + 1) {
            setIsNextPage(2)
          }
          setFaqList(prevData => {
            const newDataIds = newData.map(item => item.oid)
            const filteredData = prevData.filter(item => !newDataIds.includes(item.oid))
            return [...filteredData, ...newData]
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const editFaq = (oid) => {
    setIsEditFaqVisible(true)
    setPropsData({ oid: oid })
  }

  const deleteFaq = (oid, question) => {
    setIsDeleteFaqVisible(true)
    setPropsData({ oid: oid, question: question})
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">常见问题列表</div>
          <ContentFilter
            addName='问题'
            category={5}
            filterValue={filterValue} 
            setFilterValue={setFilterValue} 
            isAddVisible={() => { setIsAddFaqVisible(true) }}  
          />
        </div>

        <div className='public-main-style'>
          <div className='public-main-style2'>
            <table className='public-main-style2-item'>
              <thead>
                <tr className='public-main-style2-thead'>
                  <th style={{width: '300px'}}>
                    <div className='public-main-style2-title'>问题</div>
                  </th>
                  <th>所属栏目</th>
                  <th>排序</th>
                  <th style={{width: '50px'}}></th>
                </tr>
              </thead>

              <tbody>
                {faqList && (faqList.length > 0 ? (faqList.map((item) => (
                  <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { editFaq(item.oid) }}>
                    <td style={{width: '300px'}}>
                      <div className='public-main-style2-title'>{item.question}</div>
                    </td>
                    <td>{item.column_name}</td>
                    <td>{item.sort}</td>
                    <td style={{width: '50px'}}>
                      <div className='public-main-style2-operation-box'>
                        <div className='public-main-style2-operation' 
                          onClick={(event) => {event.stopPropagation(); deleteFaq(item.oid, item.question)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <tr className='public-main-style2-tbodyEmpty'>
                    <td colSpan='4'><span>暂无数据</span></td>
                  </tr>
                ))}
              </tbody>

              <tfoot>
                {isNextPage === 1 ? (
                  <tr className='public-main-style2-tfootNext'>
                    <td colSpan='4'>
                      <div className='public-main-style2-nextPage' onClick={loadNextPage}>下一页</div>
                    </td>
                  </tr>
                ) : ( isNextPage === 2 && 
                  <tr className='public-main-style2-tfootOver'>
                    <td colSpan='4'>已加载全部</td>
                  </tr>
                )}
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      {isAddFaqVisible && <AddFaq
        getFaqList={getFaqList} 
        isOpen={isAddFaqVisible} 
        isCancel={() => { setIsAddFaqVisible(false) }} 
      />}

      {isEditFaqVisible && <EditFaq
        data={propsData}
        getFaqList={getFaqList} 
        isOpen={isEditFaqVisible} 
        isCancel={() => { setIsEditFaqVisible(false) }} 
      />}

      {isDeleteFaqVisible && <DeleteFaq
        data={propsData}
        getFaqList={getFaqList} 
        isOpen={isDeleteFaqVisible} 
        isCancel={() => { setIsDeleteFaqVisible(false) }} 
      />}
    </div>
  )
}
