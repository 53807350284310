import React from 'react'
import { Outlet, NavLink } from 'react-router-dom'
import MyIcon from '../../components/general/icon'

export default function MaterialNav() {
  const computedClassName = ({ isActive }) => {
    return isActive ?  "secondaryNav-menu secondaryNav-menuActive" : "secondaryNav-menu"
  }

  return (
    <div className="secondaryNav-page">
      <div className="secondaryNav-container">
        <div className="secondaryNav-headline">素材管理</div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">小程序管理</div>
            <NavLink to="slide/miniapp" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='lunbotu' /></div>
              <div className="secondaryNav-name">首页轮播图</div>
            </NavLink>
            <NavLink to="navigation/miniapp" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='jingangwei' /></div>
              <div className="secondaryNav-name">首页金刚位</div>
            </NavLink>
            <NavLink to="banner/miniapp" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='buju' /></div>
              <div className="secondaryNav-name">临时广告位</div>
            </NavLink>
          </div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">信息网管理</div>
            <NavLink to="slide/website" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='lunbotu' /></div>
              <div className="secondaryNav-name">首页轮播图</div>
            </NavLink>
            <NavLink to="navigation/website" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='jingangwei' /></div>
              <div className="secondaryNav-name">首页快捷访问</div>
            </NavLink>
            <NavLink to="banner/website" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='buju' /></div>
              <div className="secondaryNav-name">临时广告位</div>
            </NavLink>
          </div>
      </div>
      <Outlet />
    </div>
  )
}
