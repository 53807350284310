import React from 'react'
import { Outlet, NavLink } from 'react-router-dom'
import MyIcon from '../../components/general/icon'

export default function ContentNav() {

  const computedClassName = ({ isActive }) => {
    return isActive ?  "secondaryNav-menu secondaryNav-menuActive" : "secondaryNav-menu"
  }

  return (
    <div className="secondaryNav-page">
      <div className="secondaryNav-container">
        <div className="secondaryNav-headline">图文视频</div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">文章管理</div>
            <NavLink to="article_column" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='lanmu' /></div>
              <div className="secondaryNav-name">文章栏目</div>
            </NavLink>
            <NavLink to="article_list" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='wenzhang' /></div>
              <div className="secondaryNav-name">文章列表</div>
            </NavLink>
          </div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">图片管理</div>
            <NavLink to="picture_column" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='lanmu' /></div>
              <div className="secondaryNav-name">图片栏目</div>
            </NavLink>
            <NavLink to="picture_list" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='tupian' /></div>
              <div className="secondaryNav-name">图片列表</div>
            </NavLink>
          </div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">视频管理</div>
            <NavLink to="video_column" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='lanmu' /></div>
              <div className="secondaryNav-name">视频栏目</div>
            </NavLink>
            <NavLink to="video_list" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='shipin' /></div>
              <div className="secondaryNav-name">视频列表</div>
            </NavLink>
          </div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">常见问题管理</div>
            <NavLink to="faq_column" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='lanmu' /></div>
              <div className="secondaryNav-name">常见问题栏目</div>
            </NavLink>
            <NavLink to="faq_list" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='changjianwenti' /></div>
              <div className="secondaryNav-name">常见问题列表</div>
            </NavLink>
          </div>

          <div className="secondaryNav-box">
            <div className="secondaryNav-title">人物管理</div>
            <NavLink to="character_column" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='lanmu' /></div>
              <div className="secondaryNav-name">人物栏目</div>
            </NavLink>
            <NavLink to="character_list" className={computedClassName}>
              <div className="secondaryNav-icon"><MyIcon name='renwu' /></div>
              <div className="secondaryNav-name">人物列表</div>
            </NavLink>
          </div>
      </div>
      <Outlet />
    </div>
  )
}
