import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyTextArea from '../../../components/forms/textArea'
import MyImageUpload from '../../../components/forms/imageUpload'

const EditCampus = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/info/campus/detail/get', {
      merchant_oid: merchantOid,
      campus_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({nameEmpty: '校区名称不能为空'})
      return false
    }
    if (!formValue.address) {
      setErrorMsg({addressEmpty: '校区地址不能为空'})
      return false
    }

    axios.post('/p/info/campus/edit', { 
      merchant_oid: merchantOid, 
      campus_oid: formValue.oid, 
      name: formValue.name,
      sort: Number(formValue.sort),
      address: formValue.address,
      introduction: formValue.introduction,
      thumbnail: formValue.thumbnail
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getCampusList()
          message.success('校区修改成功！')
        } else {
          message.error('腾讯地图地址解析失败，请稍后再试！')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title={`编辑校区<${formValue.oid}>`}
      destroyOnClose={true}
      width='700px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyInput
        id="name"
        required
        autoFocus
        maxLength={64}
        label='名称'
        placeholder='请输入校区的名称'
        value={formValue.name}
        onChange={setFormValue}
        errorMsg={errorMsg.nameEmpty}
      />

      <MyTextArea 
        id="address"
        required
        label='地址'
        minRows={3}
        placeholder='请输入完整的校区地址'
        value={formValue.address}
        onChange={setFormValue}
        errorMsg={errorMsg.addressEmpty}
      />

      <MyTextArea 
        id="introduction"
        label='介绍'
        minRows={3}
        placeholder='请输入校区介绍，如乘车路线'
        value={formValue.introduction}
        onChange={setFormValue}
      />

      <MyImageUpload 
        id="thumbnail"
        label='缩略图'
        value={formValue.thumbnail}
        setValue={setFormValue}
      />

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />
    </Modal>
  )
}
export default EditCampus
