import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { Dropdown, Space, message } from 'antd'
import MyAvatar from '../../components/general/avatar'
import ChangePwd from './changepwd'

const AcccountSelect = () => {
  const navigate = useNavigate()
  const [accountDetail, setAccountDetail] = useState({})
  const [isChangePwdVisible, setIsChangePwdVisible] = useState(false)

  const fetchAcountDetail = () => {
    axios.post('/p/people/personal/get')
      .then(function (response) {
        if (response.errcode === 0) {
          setAccountDetail(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    fetchAcountDetail()
  }, [])

  const handleChangePwd = () => {
    setIsChangePwdVisible(true)
  }

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('merchant_oid')
    navigate('/login')
    message.success('退出登录成功')
  }

  const items = [
    {
      label: (
        <div className="people-personal">
          <MyAvatar userObject={accountDetail} avatarSize={46}/>
          <div className="people-box">
            <div className="people-nickname">{accountDetail.nickname}</div>
            <div className="people-username">{accountDetail.username}</div>
          </div>
        </div>
      ),
      disabled: true,
    },
    {
      type: 'divider',
    },
    {
      label: <div onClick={handleChangePwd}>修改密码</div>,
    },
    {
      label: <div onClick={handleLogout}>退出登录</div>,
    },
  ]

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']} overlayStyle={{ width: '280px'}}>
        <Link onClick={(e) => e.preventDefault()}>
          <Space>
            <MyAvatar userObject={accountDetail} avatarSize={30} fontSize='12px'/>
          </Space>
        </Link>
      </Dropdown>

      {isChangePwdVisible && <ChangePwd
        isOpen={isChangePwdVisible}
        isCancel={() => { setIsChangePwdVisible(false) }}
      />}
    </>
  )
}

export default AcccountSelect
