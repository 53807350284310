import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyRichText from '../../../components/forms/richText'
import MyColumnSelect from '../../../components/select/columnSelect'
import MyImageUpload from '../../../components/forms/imageUpload'

const EditCharacter = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({})
  const [errorMsg, setErrorMsg] = useState({})

  useEffect(() => {
    axios.post('/p/content/character/detail/get', {
      merchant_oid: merchantOid,
      character_oid: props.data.oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setFormValue(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, props.data])

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.name) {
      setErrorMsg({nameEmpty: '人物名称不能为空'})
      return false
    }
    if (!formValue.column_oid) {
      setErrorMsg({columnEmpty: '所属栏目不能为空'})
      return false
    }

    axios.post('/p/content/character/edit', { 
      merchant_oid: merchantOid,
      character_oid: formValue.oid,
      name: formValue.name,
      honor: formValue.honor,
      column_oid: formValue.column_oid,
      thumbnail: formValue.thumbnail,
      introduction: formValue.introduction,
      sort: Number(formValue.sort)
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getCharacterList()
          message.success('人物修改成功！')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title='编辑人物'
      destroyOnClose={true}
      width='800px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyImageUpload 
        id="thumbnail"
        label='人物照片'
        value={formValue.thumbnail}
        setValue={setFormValue}
      />

      <MyColumnSelect
        id='column_oid'
        required
        label='选择栏目'
        category={4}
        value={formValue.column_oid}
        onChange={setFormValue}
        errorMsg={errorMsg.columnEmpty}
      />

      <MyInput
        id="name"
        required
        autoFocus
        maxLength={64}
        label='姓名'
        placeholder='请输入人物姓名'
        value={formValue.name}
        onChange={setFormValue}
        errorMsg={errorMsg.nameEmpty}
      />

      <MyInput
        id="honor"
        maxLength={64}
        label='荣誉'
        placeholder='请输入人物荣誉'
        value={formValue.honor}
        onChange={setFormValue}
      />

      <MyRichText
        id='introduction'
        required
        label='人物介绍'
        value={formValue.introduction}
        setValue={setFormValue}
      />

      <MyInput
        type='number'
        id="sort"
        label='排序'
        value={formValue.sort}
        onChange={setFormValue}
        styles={{ width: '60px' }}
        min={0}
        max={1000}
      />
    </Modal>
  )
}

export default EditCharacter
