import React from 'react'

export default function Maintenance() {
  return (
    <div className="error-page">
      <div className="error-container">
        <img className="error-figure" src='/images/maintenance.png' alt="maintenance" />
        <p className="error-title">系统正在升级，请稍后再试，带来不便深感抱歉！</p>
      </div>
    </div>
  )
}
