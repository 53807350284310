import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'
import MyInput from '../../../components/forms/input'
import MyTextArea from '../../../components/forms/textArea'

const EditMailInfo = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [formValue, setFormValue] = useState({
    contact: props.data.info.contact,
    phone: props.data.info.phone,
    address: props.data.info.address,
    deferral_mail_info: props.data.info.deferral_mail_info,
    readmission_mail_info: props.data.info.readmission_mail_info
  })
  const [errorMsg, setErrorMsg] = useState({})

  const handleOk = () => {
    setErrorMsg({})
    if (!formValue.contact) {
      setErrorMsg({contactEmpty: '联系人不能为空'})
      return false
    }
    if (!formValue.phone) {
      setErrorMsg({phoneEmpty: '联系电话不能为空'})
      return false
    }
    if (!formValue.address) {
      setErrorMsg({addressEmpty: '邮寄地址不能为空'})
      return false
    }
    if (!formValue.deferral_mail_info) {
      setErrorMsg({deferralMailInfoEmpty: '保留申请邮寄材料不能为空'})
      return false
    }
    if (!formValue.readmission_mail_info) {
      setErrorMsg({readmissionMailInfoEmpty: '复学申请邮寄材料不能为空'})
      return false
    }

    axios.post('/p/retention_admission/config/edit', { 
      merchant_oid: merchantOid, 
      contact: formValue.contact, 
      phone: formValue.phone,
      address: formValue.address,
      deferral_mail_info: formValue.deferral_mail_info,
      readmission_mail_info: formValue.readmission_mail_info
    })
      .then(function (response) {
        if (response.errcode === 0) {
          handleCancel()
          props.getFreshmenConfig()
          message.success('邮寄信息修改成功！')
        } else {
          message.error(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleCancel = () => {
    setErrorMsg({})
    props.isCancel()
  }

  return (
    <Modal 
      title={'邮寄信息'}
      destroyOnClose={true}
      width='700px'
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="取消"
      okText="保存">

      <MyInput
        id="contact"
        required
        autoFocus
        maxLength={64}
        label='联系人'
        placeholder='请输入联系人'
        value={formValue.contact}
        onChange={setFormValue}
        errorMsg={errorMsg.contactEmpty}
      />

      <MyInput
        id="phone"
        required
        maxLength={64}
        label='联系电话'
        placeholder='请输入联系电话'
        value={formValue.phone}
        onChange={setFormValue}
        errorMsg={errorMsg.phoneEmpty}
      />

      <MyInput
        id="address"
        required
        maxLength={64}
        label='邮寄地址'
        placeholder='请输入邮寄地址'
        value={formValue.address}
        onChange={setFormValue}
        errorMsg={errorMsg.addressEmpty}
      />

      <MyTextArea 
        id="deferral_mail_info"
        required
        label='保留申请材料'
        minRows={5}
        placeholder='请输入保留申请邮寄材料'
        value={formValue.deferral_mail_info}
        onChange={setFormValue}
        errorMsg={errorMsg.deferralMailInfoEmpty}
      />

      <MyTextArea 
        id="readmission_mail_info"
        required
        label='复学申请材料'
        minRows={5}
        placeholder='请输入复学申请邮寄材料'
        value={formValue.readmission_mail_info}
        onChange={setFormValue}
        errorMsg={errorMsg.readmissionMailInfoEmpty}
      />
    </Modal>
  )
}
export default EditMailInfo
