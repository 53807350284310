import React from 'react'
import MyIcon from '../../components/general/icon'
import { NavLink } from 'react-router-dom'
import { Tooltip } from 'antd'

export default function PrimaryNav() {

  const computedClassName = ({ isActive }) => {
    return isActive ?  "main-primaryNav-icon main-primaryNav-iconActive" : "main-primaryNav-icon"
  }

  return (
    <div className='main-primaryNav-page'>
      <Tooltip placement="right" color='#1A1B1C' title="首页">
        <NavLink to="index" className={computedClassName}>
          <MyIcon name='shouye' />
        </NavLink>
      </Tooltip>

      <div className='main-primaryNav-divider'></div>

      <Tooltip placement="right" color='#1A1B1C' title="招生信息">
        <NavLink to="/info" className={computedClassName}>
          <MyIcon name='zhaoshengxinxi' />
        </NavLink>
      </Tooltip>

      <Tooltip placement="right" color='#1A1B1C' title="图文视频">
        <NavLink to="content" className={computedClassName}>
          <MyIcon name='tuwenshipin' />
        </NavLink>
      </Tooltip>

      <Tooltip placement="right" color='black' title="招录辅助">
        <NavLink to="auxiliary" className={computedClassName}>
          <MyIcon name='zhaolufuzhu' />
        </NavLink>
      </Tooltip>

      <Tooltip placement="right" color='black' title="更多应用">
        <NavLink to="more" className={computedClassName}>
          <MyIcon name='yingyong' />
        </NavLink>
      </Tooltip>

      <div className='main-primaryNav-divider'></div>

      <Tooltip placement="right" color='black' title="素材管理">
        <NavLink to="material" className={computedClassName}>
          <MyIcon name='sucaiguanli' />
        </NavLink>
      </Tooltip>

      <div className='main-primaryNav-divider'></div>

      <Tooltip placement="right" color='black' title="系统设置">
        <NavLink to="setting" className={computedClassName}>
          <MyIcon name='shezhi' style={{fontSize: '15px'}} />
        </NavLink>
      </Tooltip>
    </div>
  )
}
