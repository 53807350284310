import React, { useState, useEffect, useRef } from 'react'
import { Input } from 'antd'
import MyIcon from '../general/icon'

const MyInput = ({
  id,
  required = false,
  autoFocus=false,
  label = '',
  type = 'text',
  placeholder = '',
  value,
  maxLength = 64,
  onChange,
  errorMsg,
  styles,
  name,
  autoComplete='off',
  ...rest
}) => {
  const inputRef = useRef(null)
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus()
    }
    if(errorMsg) {
      inputRef.current.focus()
      setStatus('error')
    } else {
      setStatus('')
    }
  }, [errorMsg, autoFocus])

  return (
    <div className="form-box">
      <label htmlFor={id}>
        {label} 
        {required && <span style={{ color: '#ae2a19' }}> *</span>}
      </label>

      {type === 'password' ? (
        <Input.Password
          ref={inputRef}
          autoComplete={autoComplete}
          id={id}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          status={status}
          onChange={(e) => onChange(prevValues => ({ ...prevValues, [id]: e.target.value }))}
          style={{ height: '40px', width: '100%', ...styles }}
          {...rest}
        />
      ) : (
        <Input
          ref={inputRef}
          autoComplete={autoComplete}
          id={id}
          type={type}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          status={status}
          onChange={(e) => onChange(prevValues => ({ ...prevValues, [id]: e.target.value }))}
          style={{ height: '40px', width: '100%', ...styles }}
          {...rest}
        />
      )}

      {errorMsg && (
        <div className="form-errormsg">
          <span><MyIcon name='jinggao' /></span> 
          <p>{errorMsg}</p>
        </div>
      )}
    </div>
  )
}

export default MyInput
