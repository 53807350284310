import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Input, Select } from 'antd'
import MyIcon from '../general/icon'

const ContentFilter = ({
  addName,
  category,
  isAddVisible,
  filterValue,
  setFilterValue
}) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [columnList, setColumnList] = useState([])
  const [keywordsValue, setKeywordsValue] = useState('')
  const [searchBoxVisible, setSearchBoxVisible] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFilterValue({ ...filterValue, keywords: keywordsValue })
    }, 300)
    return () => clearTimeout(timeoutId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordsValue])

  useEffect(() => {
    axios.post('/p/content/column/list/get', { 
      merchant_oid: merchantOid,
      category: category
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setColumnList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [merchantOid, category])

  let items = [
    {  
      value: '', 
      label: '全部栏目'
    },
    ...columnList.flatMap(item => {
      let parentItem = {
        value: item.oid,
        label: item.name
      };
      let childrenItems = item.children.map(child => ({
        value: child.oid,
        label: child.name
      }));
      return [parentItem, ...childrenItems];
    })
  ]
  
  const clickSortFilter = () => {
    if (filterValue.sort_filter === 1) {
      setFilterValue({ ...filterValue, sort_filter: 0 })
    } else {
      setFilterValue({ ...filterValue, sort_filter: 1 })
    }
  }

  const clickColumnFilter = (value) => {
    setFilterValue({ ...filterValue, column_oid: value })
  }

  const handleBlur = () => {
    if (!keywordsValue) {
      setSearchBoxVisible(false)
      setFilterValue({ ...filterValue, keywords: '' })
    }
  }

  return (
    <div className='filter-container'>
      <div className='filter-filterContainer'>
        <div className='filter-addButton' onClick={() => { isAddVisible() }}>添加{addName}</div>
        <Select
          placeholder="所属栏目"
          options={items}
          style={{height: '32px', width: '200px'}}
          onChange={(value) => clickColumnFilter(value)}
        />

        {!searchBoxVisible 
        ? <div className='filter-condition' onClick={() => {setSearchBoxVisible(true)}}>
            <span><MyIcon name='sousuo'/></span>
            <p>搜索</p>
          </div>
        : <Input
            style={{width: '260px'}}
            prefix={<MyIcon name='sousuo'/>}
            maxLength={64}
            autoFocus
            placeholder='输入关键字'
            value={keywordsValue}
            onChange={(e) => setKeywordsValue(e.target.value)}
            onBlur={handleBlur}
            allowClear
          />
        }

        <div className='filter-condition' onClick={() => {clickSortFilter()}}>
          <span>{filterValue.sort_filter === 1 ? <MyIcon name='zhengxu'/> : <MyIcon name='daoxu'/>}</span>
          <p>排序</p>
        </div>
      </div>
    </div>
  )
}

export default ContentFilter
