import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'

export default function ResumePreview() {
  const { oid } = useParams()
  const [resumeDetail, setResumeDetail] = useState({})

  const getResumeDetail = () => {
    axios.post('/p/jobs/resume/detail/get', { 
      resume_oid: oid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setResumeDetail(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getResumeDetail()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oid])

  useEffect(() => {
    document.title = resumeDetail.name ? `${resumeDetail.name}的简历` : '简历预览';
  }, [resumeDetail.name]);

  const handlePrint = () => {
    window.print()
  }

  return (
    <div className="resume-page">
      <div className='resume-header'>
        <div className='resume-print-box'>
          <div className='resume-print' onClick={handlePrint}>保存/打印</div>
        </div>
        
        <div className='resume-header-box'>
          <div className='resume-header-left'>
            <img src={resumeDetail.avatar} alt="" />
          </div>
          <div className='resume-header-right'>
            <div className='resume-header-name'>{resumeDetail.name}</div>
            <div className='resume-header-info'>{resumeDetail.status} / {resumeDetail.age}岁 / {resumeDetail.gender} / {resumeDetail.phone}</div>
            <div className='resume-header-info'>{resumeDetail.political_outlook} / {resumeDetail.graduating_institution} / {resumeDetail.study_major}</div>
            <div className='resume-header-info'>现居住地：{resumeDetail.current_residence}</div>
            <div className='resume-header-info'>户籍所在地：{resumeDetail.registered_residence}</div>
          </div>
        </div>
      </div>

      <div className='resume-body'>
        <div className='resume-body-box'>
          <div className='resume-body-title'>
            <div className='resume-body-title-decoration'></div>个人优势
          </div>
          <div className='resume-body-content'>{resumeDetail.personal_advantage || '未填写'}</div>
        </div>
        <div className='resume-body-box'>
          <div className='resume-body-title'>
            <div className='resume-body-title-decoration'></div>职业资质
          </div>
          <div className='resume-body-content'>{resumeDetail.professional_qualification || '未填写'}</div>
        </div>
        <div className='resume-body-box'>
          <div className='resume-body-title'>
            <div className='resume-body-title-decoration'></div>其他说明
          </div>
          <div className='resume-body-content'>{resumeDetail.other_instruction || '未填写'}</div>
        </div>
        <div className='resume-body-box'>
          <div className='resume-body-title'>
            <div className='resume-body-title-decoration'></div>教育经历
          </div>
          <div className='resume-body-items'>
            {resumeDetail.educations && resumeDetail.educations.length > 0 ? (resumeDetail.educations.map((item) => (
              <div className='resume-body-item'>
                <div className='resume-body-item-up'>
                  <div className='resume-body-item-left'>
                    <div className='resume-body-item-name'>{item.school_name}</div>
                    <div className='resume-body-item-info'>{item.degree} {item.major}</div>
                  </div>
                  <div className='resume-body-item-right'>{item.start_date} 至 {item.end_date}</div>
                </div>
              </div>
            ))) : ('未填写')}
          </div>
        </div>
        <div className='resume-body-box'>
          <div className='resume-body-title'>
            <div className='resume-body-title-decoration'></div>工作经历
          </div>
          <div className='resume-body-items'>
            {resumeDetail.works && resumeDetail.works.length > 0 ? (resumeDetail.works.map((item) => (
              <div className='resume-body-item'>
                <div className='resume-body-item-up'>
                  <div className='resume-body-item-left'>
                    <div className='resume-body-item-name'>{item.unit_name}</div>
                    <div className='resume-body-item-info'>{item.position_name}</div>
                  </div>
                  <div className='resume-body-item-right'>{item.start_date} 至 {item.end_date}</div>
                </div>
                <div className='resume-body-item-down'>{item.work_content}</div>
              </div>
            ))) : ('未填写')}
          </div>
        </div>
      </div>
    </div>
  )
}
