import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, message } from 'antd'

const MyQrcode = (props) => {
  const { accessAddress } = props.data
  const [qrcodeUrl, setQrcodeUrl] = useState('')

  useEffect(() => {
    axios.post('/common/qrcode/generate', {
      url: accessAddress
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setQrcodeUrl(response.data.qrcode_address)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [accessAddress])

  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(accessAddress).then(() => {
        message.success('学生访问地址已复制！')
      }).catch(err => {
        console.error('复制失败: ', err)
      })
    } else {
      const textarea = document.createElement('textarea')
      textarea.value = accessAddress;
      document.body.appendChild(textarea)
      textarea.select();
      try {
        document.execCommand('copy');
        message.success('学生访问地址已复制！')
      } catch (err) {
        console.error('复制失败: ', err)
      }
      document.body.removeChild(textarea)
    }
  }

  const handleDownload = async () => {
    const response = await fetch(qrcodeUrl)
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'qrcode.png')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  return (
    <Modal 
      width={400}
      title="查看学生访问地址"
      open={props.isOpen}
      onCancel={props.isCancel}
      footer={false}>

      <div className='facebody-category-address-container'>
        <div className='facebody-category-address-qrcode'>
          <img src={qrcodeUrl} alt="" />
        </div>

        <div className='facebody-category-address-buttonBox'>
          <div className='facebody-category-address-button1' onClick={ handleCopy }>
            <p>复制链接</p>
          </div>
          <div className='facebody-category-address-button2' onClick={ handleDownload }>
            <p>保存图片</p>
          </div>
        </div>
      </div>

    </Modal>
  )
}

export default MyQrcode
