import React, { useState } from 'react'
import axios from 'axios'
import { Modal, message, Upload, Spin } from 'antd'
import MyIcon from '../../../components/general/icon'
import { apiBaseURL } from '../../../request'
import EmptyAdmissionStudent from './empty'

const ImportAdmissionStudent = (props) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [isLoading, setIsLoading] = useState(false)
  const [isEmptyVisible, setIsEmptyVisible] = useState(false)

  const imageUpload = {
    name: 'doc',
    action: `${apiBaseURL}/common/doc/upload`,
    headers: {
      authorization: localStorage.getItem('token'),
    },
    onChange(info) {
      if (info.file.status === 'done') {
        handleImport(info.file.response.data.doc_url)
      }
    },
  }

  const handleImport = (filename) => {
    setIsLoading(true)
    axios.post('/p/admission_notice/student/import', { 
      merchant_oid: merchantOid, 
      filename: filename
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('录取学生数据导入成功！')
          setIsLoading(false)
          props.isCancel()
          props.getStudentData()
        } else {
          message.error(response.msg)
          setIsLoading(false)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleDownload = () => {
    setIsLoading(true)
    axios.post('/common/excel_template/download', { 
      name: 'lqxs'
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('录取通知书学生信息导入模版下载成功！')
          setIsLoading(false)
          const link = document.createElement('a')
          link.href = response.data.download_address
          link.setAttribute('download', '')
          link.click()
          document.body.removeChild(link)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const handleExport = () => {
    setIsLoading(true)
    axios.post('/p/admission_notice/student/export', { 
      merchant_oid: merchantOid,
      category: props.filterValue.category,
      college: props.filterValue.college,
      major: props.filterValue.major,
      duration: props.filterValue.duration,
      keywords: props.filterValue.keywords,
      is_print: props.filterValue.is_print,
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('录取通知书学生信息导出成功！')
          setIsLoading(false)
          const link = document.createElement('a')
          link.href = response.data.download_address
          link.setAttribute('download', '')
          link.click()
          document.body.removeChild(link)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <>
    <Modal
      width={700}
      title="录取通知书学生信息的导入和导出"
      open={props.isOpen}
      onCancel={props.isCancel}
      maskClosable={false}
      footer=''>

      <Spin spinning={isLoading}>
        <div className='public-import-container'>
          <div className='public-import-box'>
            <div className='public-import-icon'>
              <MyIcon name='daoru' />
            </div>
            <div className='public-import-title'>从本地导入</div>
            <div className='public-import-despcription'>导入数据前请先下载导入模版，根据模版格式导入，支持自定义字段</div>
            
            <Upload {...imageUpload} showUploadList={false}>
              <div className='public-import-button1'>
                <p>导入数据</p>
              </div>
            </Upload>
            
            <div className='public-import-button2' onClick={() => { handleDownload() }}>
              <p>下载模版</p>
            </div>
          </div>

          <div className='public-import-box'>
            <div className='public-import-icon'><MyIcon  name='daochu' /></div>
            <div className='public-import-title'>导出为Excel</div>
            <div className='public-import-despcription'>默认导出全部数据，可通过条件筛选，导出对应条件的数据</div>
            <div className='public-import-button1' onClick={() => { handleExport() }}>
              <p>导出数据</p>
            </div>
            <div className='public-import-button2' onClick={() => { setIsEmptyVisible(true) }}>
              <p>清空数据</p>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>

    {isEmptyVisible && <EmptyAdmissionStudent
      getStudentData={props.getStudentData()}
      isOpen={isEmptyVisible}
      isCancel={() => { setIsEmptyVisible(false) }}
    />}
    </>
  )
}

export default ImportAdmissionStudent
